import { Option } from "@faro-lotv/flat-ui";
import { PropOptional, validatePrimitive } from "@faro-lotv/foundation";
import {
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { MarkupStatus } from "@faro-lotv/service-wires";

export type MarkupModelMetaData = {
  /** Color string of the Markup */
  color?: string;

  /** Type of the animation of the Markup */
  nodeAnimationType?: string;

  /**
   * Name of the type of the 3d material of the Markup.
   * This is currently ignored and instead MeshBasicMaterial is used
   */
  nodeMaterialName?: string;

  /**
   * Number of side of the model to render.
   * If equal to 2 it will be rendered with DoubleSide.
   * Otherwise the FrontSide will be rendered
   */
  textureRenderingSides?: number;
};

/**
 * @param metaData object to check
 * @returns true if the metaData passed is a MarkupModelMetaData
 */
export function isMarkupModelMetaData(
  metaData: Record<string, unknown> | null,
): metaData is MarkupModelMetaData {
  return (
    !!metaData &&
    validatePrimitive(metaData, "color", "string", PropOptional) &&
    validatePrimitive(metaData, "nodeAnimationType", "string", PropOptional) &&
    validatePrimitive(metaData, "nodeMaterialName", "string", PropOptional) &&
    validatePrimitive(metaData, "textureRenderingSides", "number", PropOptional)
  );
}

export const MARKUP_STATUS_DISPLAY_NAME: Record<string, string> = {
  [MarkupStatus.Open]: "To do",
  [MarkupStatus.InProgress]: "In progress",
  [MarkupStatus.ToReview]: "To review",
  [MarkupStatus.Resolved]: "Resolved",
};

/** List of available options to use for the DropDownMarkupField's value property */
export const STATUS_OPTIONS: Option[] = [
  {
    key: MarkupStatus.Open,
    value: MarkupStatus.Open,
    label: MARKUP_STATUS_DISPLAY_NAME[MarkupStatus.Open],
  },
  {
    key: MarkupStatus.InProgress,
    value: MarkupStatus.InProgress,
    label: MARKUP_STATUS_DISPLAY_NAME[MarkupStatus.InProgress],
  },
  {
    key: MarkupStatus.ToReview,
    value: MarkupStatus.ToReview,
    label: MARKUP_STATUS_DISPLAY_NAME[MarkupStatus.ToReview],
  },
  {
    key: MarkupStatus.Resolved,
    value: MarkupStatus.Resolved,
    label: MARKUP_STATUS_DISPLAY_NAME[MarkupStatus.Resolved],
  },
];

/**
 * @returns a display name for a markup status or undefined if the markup has no status. Allows any string to be passed,
 * since IElements may contain arbitrary data.
 * @param status The status value out of an IElement
 */
export function getMarkupStatusDisplayName(
  status: string | null | undefined,
): string | undefined {
  if (status) {
    if (status in MARKUP_STATUS_DISPLAY_NAME) {
      return MARKUP_STATUS_DISPLAY_NAME[status];
    }
    return status;
  }
}

/**
 * @returns returns the provider name for an external markup type
 * @param externalMarkup the type of the external markup iElement
 */
export function getExternalMarkupProviderName(
  externalMarkup?: ExternalMarkupIElement,
): string | undefined {
  if (!externalMarkup) return;

  switch (externalMarkup.type) {
    case IElementType.markupBim360:
      return "Autodesk BIM 360";
    case IElementType.markupProcoreRfi:
      return "Procore RFI";
    case IElementType.markupProcoreObservation:
      return "Procore Observation";
  }

  return "External Provider";
}
