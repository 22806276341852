import { parseUrlLikeString } from "@faro-lotv/foundation";
import {
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { PropsWithChildren, useMemo, useState } from "react";
import { convertToDateString } from "../../../utils";
import { FaroButton } from "../../button/faro-button";
import { FaroChipList, FaroChipTag } from "../../chip";
import { cyan, neutral } from "../../colors";
import { FontWeights } from "../../faro-theme";
import { FileDetailsList } from "../../horizontal-scroll-list/file-details-list";
import { FaroRichTextViewer } from "../../rich-text";
import { FaroText } from "../../text/faro-text/faro-text";
import { UserAvatar } from "../../user-avatar/user-avatar";
import { AnnotationViewerHeader } from "./annotation-viewer-header";
import type { AnnotationViewerProps, Tag } from "./annotation-viewer-types";

const DEFAULT_BACKGROUND = `${neutral[999]}CC`;

/**
 * @returns Information of an annotation
 *  This component has three possible state/variants:
 *   - collapsed -> the annotation ui is hidden
 *   - title -> only the title will be shown
 *   - small -> title, description and some control buttons will be shown
 *   - full -> On top of what small shows, also assignee, due date, status and the sync service will be shown
 */
export function AnnotationViewer({
  children,
  title,
  description,
  hasAttachment,
  isMeasurement,
  createdBy,
  assignee,
  canEdit,
  dueDate,
  status,
  syncService,
  variant,
  files,
  links,
  tags,
  onCloseButtonClick,
  onDelete,
  onEdit,
  onExpandButtonClick,
  onShareButtonClick,
  onCopyToClipboard,
  onContextMenuOpen,
  onAttachmentOpened,
  onDescriptionError,
}: PropsWithChildren<AnnotationViewerProps>): JSX.Element {
  const sx: SxProps<Theme> = useMemo(() => {
    switch (variant) {
      case "collapsed":
        return {
          width: 0,
          height: 0,
          overflow: "hidden",
        };
      case "title":
        return {
          backgroundColor: DEFAULT_BACKGROUND,
          maxWidth: "250px",
          "& .MuiCardHeader-avatar": {
            alignSelf: "flex-start",
            marginRight: 1,
          },
          "& .MuiCardHeader-title": {
            py: 0.3,
            display: "flex",
          },
          backdropFilter: "blur(4px)",
        };
      default:
        return {
          backgroundColor: DEFAULT_BACKGROUND,
          px: 1,
          py: 0.3,
          minWidth: "500px",
          maxWidth: "740px",
          "& .MuiCardHeader-avatar": {
            alignSelf: "flex-start",
            marginTop: 0.5,
          },
          backdropFilter: "blur(4px)",
        };
    }
  }, [variant]);

  const shouldShowTags =
    !!tags?.length && (variant === "small" || variant === "full");

  return (
    <Card
      variant="outlined"
      sx={sx}
      onPointerDown={(e) => e.stopPropagation()}
      onWheel={(e) => e.stopPropagation()}
    >
      <AnnotationViewerHeader
        canEdit={canEdit}
        hasAttachment={hasAttachment}
        links={links}
        isMeasurement={isMeasurement}
        title={title}
        variant={variant}
        onCloseButtonClick={onCloseButtonClick}
        onDelete={onDelete}
        onEdit={onEdit}
        onExpandButtonClick={onExpandButtonClick}
        onShareButtonClick={onShareButtonClick}
        onCopyToClipboard={onCopyToClipboard}
        onContextMenuOpen={onContextMenuOpen}
      />
      <CardContent
        sx={{
          pt: 0,
          " > *:not(:last-child)": {
            pb: 2,
          },
          ...(variant === "title" && {
            visibility: "collapse",
            ":last-child": {
              p: 0,
            },
          }),
        }}
      >
        {shouldShowTags && <AnnotationTagChips tags={tags} />}

        {variant === "small" && (
          <>
            {children}

            {description && (
              // Add support for eliding in https://faro01.atlassian.net/browse/SWEB-4856
              <ShortDescriptionField
                hasMoreFields={
                  !!assignee || !!dueDate || !!status || !!syncService
                }
                onExpandButtonClick={onExpandButtonClick}
                onDescriptionError={onDescriptionError}
              >
                {description}
              </ShortDescriptionField>
            )}
          </>
        )}

        {variant === "full" && (
          <FullAnnotationViewerBody
            description={description}
            links={links}
            files={files}
            onAttachmentOpened={onAttachmentOpened}
            dueDate={dueDate}
            assignee={assignee}
            status={status}
            syncService={syncService}
            createdBy={createdBy}
            onDescriptionError={onDescriptionError}
          >
            {children}
          </FullAnnotationViewerBody>
        )}
      </CardContent>
    </Card>
  );
}

type FullAnnotationViewerBodyProps = Pick<
  AnnotationViewerProps,
  | "description"
  | "links"
  | "files"
  | "onAttachmentOpened"
  | "dueDate"
  | "assignee"
  | "status"
  | "syncService"
  | "createdBy"
  | "onDescriptionError"
>;

function FullAnnotationViewerBody({
  children,
  description,
  links,
  files,
  onAttachmentOpened,
  dueDate,
  assignee,
  status,
  syncService,
  createdBy,
  onDescriptionError,
}: PropsWithChildren<FullAnnotationViewerBodyProps>): JSX.Element {
  const fields = [
    ...(assignee
      ? [
          {
            name: "Assignee",
            value: (
              <UserAvatar
                userDisplayInfo={assignee}
                size="xs"
                shouldShowWhiteRim={false}
                displayOption="nameOnly"
                dark
              />
            ),
          },
        ]
      : []),
    ...(dueDate
      ? [
          {
            name: "Due date",
            value: convertToDateString(dueDate.toString()),
          },
        ]
      : []),
    ...(status ? [{ name: "Status", value: status }] : []),
    ...(syncService ? [{ name: "Sync with", value: syncService }] : []),
    ...(createdBy
      ? [
          {
            name: "Creator",
            value: (
              <UserAvatar
                userDisplayInfo={createdBy}
                size="xs"
                shouldShowWhiteRim={false}
                displayOption="nameOnly"
                dark
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {children}

      {description && (
        <FaroRichTextViewer
          initialText={description ?? ""}
          dark
          label="Description"
          onError={onDescriptionError}
        />
      )}

      {links && <LinksList links={links} />}

      <Grid container rowSpacing={2} sx={{ pt: 2 }}>
        {fields.map((field) => (
          <Grid key={field.name} item xs={6}>
            <AnnotationField fieldName={field.name}>
              {field.value}
            </AnnotationField>
          </Grid>
        ))}
      </Grid>

      {files && files.length > 0 && (
        <FileDetailsList
          sx={{ px: 0 }}
          files={files}
          onAttachmentOpened={onAttachmentOpened}
        />
      )}
    </>
  );
}

type ShortDescriptionFieldProps = Pick<
  AnnotationViewerProps,
  "onDescriptionError"
> & {
  /** The description to show while hovering */
  children: AnnotationViewerProps["description"];

  /** True if the annotations has more fields to show */
  hasMoreFields: boolean;

  /** The user clicked on the Show more... button */
  onExpandButtonClick: AnnotationViewerProps["onExpandButtonClick"];
};

function ShortDescriptionField({
  children,
  hasMoreFields,
  onExpandButtonClick,
  onDescriptionError,
}: ShortDescriptionFieldProps): JSX.Element {
  const [isTextShortened, setIsTextShortened] = useState(false);

  return (
    <Stack>
      <FaroRichTextViewer
        initialText={children ?? ""}
        maxLength={250}
        onTextShortened={() => setIsTextShortened(true)}
        dark
        label="Description"
        onError={onDescriptionError}
      />

      {(isTextShortened || hasMoreFields) && (
        <FaroButton variant="ghost" dark onClick={onExpandButtonClick}>
          Show more
        </FaroButton>
      )}
    </Stack>
  );
}

interface AnnotationTagChipsProps {
  /** The tags of the annotation */
  tags?: Tag[];
}

function AnnotationTagChips({
  tags = [],
}: AnnotationTagChipsProps): JSX.Element {
  return (
    <FaroChipList
      chips={tags.map((t) => (
        <FaroChipTag key={t.id} label={t.label} color={t.color} dark />
      ))}
      dark
    />
  );
}

type AnnotationFieldProps = {
  /** The label to render for the field */
  fieldName: string;

  /** Custom styles to apply */
  sx?: SxProps<Theme>;

  /** adds a max-height to the content and enables the scrolling */
  enableScroll?: boolean;
};

/**
 * @returns A value together with a label
 */
function AnnotationField({
  sx,
  fieldName,
  enableScroll,
  children,
}: PropsWithChildren<AnnotationFieldProps>): JSX.Element {
  return (
    <Stack sx={sx}>
      <FaroText
        variant="labelL"
        sx={{ color: neutral[300], pb: 1, fontWeight: FontWeights.Bold }}
      >
        {fieldName}
      </FaroText>
      <FaroText
        variant="bodyM"
        onWheel={(e) => e.stopPropagation()}
        sx={{
          color: neutral[0],
          ...(enableScroll && {
            maxHeight: "20vh",
            overflowY: "auto",
            whiteSpace: "pre-wrap",
            scrollbarWidth: "thin",
            ["&::-webkit-scrollbar-thumb"]: {
              background: `${neutral[999]}CC`,
              ["&:hover"]: {
                background: `${neutral[500]}CC`,
              },
            },
            scrollbarColor: `${neutral[400]}CC ${neutral[999]}CC`,
          }),
        }}
      >
        {children ?? "-"}
      </FaroText>
    </Stack>
  );
}

type LinksListProps = Pick<AnnotationViewerProps, "links">;

function LinksList({ links }: LinksListProps): JSX.Element | null {
  if (!links) return null;
  return (
    <Stack sx={{ width: "100%" }}>
      <FaroText
        variant="labelL"
        sx={{ color: neutral[300], py: 1, fontWeight: FontWeights.Bold }}
      >
        Links
      </FaroText>
      {links.map((link) => (
        <Link
          key={link.href}
          target="_blank"
          sx={{ color: cyan[400] }}
          href={parseUrlLikeString(link.href)}
          title={`Open Link: ${link.href}`}
        >
          {link.label}
        </Link>
      ))}
    </Stack>
  );
}
