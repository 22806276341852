import { GUID, assert } from "@faro-lotv/foundation";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { TokenProvider, useCoreApiTokenProvider } from "./authentication";
import { CoreApiClient } from "./core-api";
import { PointCloudApiClient } from "./point-cloud-api";
import { PreAlignmentApiClient } from "./pre-alignment-api";
import { ProgressApiClient } from "./progress-api";
import { ProjectApi } from "./project-api";
import { RegistrationApiClient } from "./registration-api";

/** A collection of API clients in Sphere XG. */
export type ApiClients = {
  /** A function to fetch new authentication tokens. */
  tokenProvider: TokenProvider;

  /** Client to talk to the core API. */
  coreApiClient: CoreApiClient;

  /** Client to talk to the point cloud API. */
  pointCloudApiClient: PointCloudApiClient;

  /** Client to talk to the progress API. */
  progressApiClient: ProgressApiClient;

  /** Client to talk to the project API. */
  projectApiClient: ProjectApi;

  /** Client to talk to the registration API. */
  registrationApiClient?: RegistrationApiClient;

  /** Client to talk to the pre-alignment API. */
  preAlignmentApiClient?: PreAlignmentApiClient;
};

export const ApiClientContext = createContext<ApiClients | undefined>(
  undefined,
);

/**
 * @returns A context to access the initialized API clients.
 */
export function useApiClientContext(): ApiClients {
  const context = useContext(ApiClientContext);
  assert(context, "ApiClientContext is not initialized.");
  return context;
}

/** The base URL to use for each backend. */
export type ApiBaseUrls = {
  /** The base URL for the core API. */
  coreApiUrl: string;

  /** The base URL for the point cloud API. */
  pointCloudApiUrl: string;

  /** The base URL for the progress API. */
  progressApiUrl: string;

  /** The base URL for the project API. */
  projectApiUrl: string;

  /** The base URL for the registration API. */
  registrationApiUrl?: string;

  /** The base URL for the pre-alignment API. */
  preAlignmentApiUrl?: string;
};

type ApiClientContextProviderProps = {
  /** The ID of the currently opened project. */
  projectId: GUID;

  /** The ID of the logged-in user, if available. */
  userId?: string;

  /** An identifier for the client that is sending the requests. */
  clientId?: string;

  /** The base URLs to use for each API. */
  apiBaseUrls: ApiBaseUrls;
};

/**
 * @returns Initialize all API clients.
 */
export function ApiClientContextProvider({
  projectId,
  userId,
  clientId,
  apiBaseUrls,
  children,
}: PropsWithChildren<ApiClientContextProviderProps>): JSX.Element {
  const {
    coreApiUrl,
    pointCloudApiUrl,
    progressApiUrl,
    projectApiUrl,
    registrationApiUrl,
    preAlignmentApiUrl,
  } = apiBaseUrls;

  const tokenProvider = useCoreApiTokenProvider({
    projectId,
    clientId,
    coreApiBaseUrl: coreApiUrl,
  });

  const coreApiClient = useMemo(
    () => new CoreApiClient(coreApiUrl, clientId),
    [coreApiUrl, clientId],
  );

  const pointCloudApiClient = useMemo(
    () => new PointCloudApiClient(pointCloudApiUrl, tokenProvider),
    [pointCloudApiUrl, tokenProvider],
  );

  const progressApiClient = useMemo(
    () =>
      new ProgressApiClient(projectId, tokenProvider, progressApiUrl, clientId),
    [progressApiUrl, projectId, tokenProvider, clientId],
  );

  const projectApiClient = useMemo(
    () =>
      new ProjectApi({
        projectApi: new URL(projectApiUrl),
        projectId,
        tokenProvider,
        clientId,
      }),
    [projectApiUrl, projectId, tokenProvider, clientId],
  );

  const registrationApiClient = useMemo(() => {
    if (registrationApiUrl && userId) {
      return new RegistrationApiClient({
        projectId,
        userId,
        tokenProvider,
        baseUrl: registrationApiUrl,
        clientId,
      });
    }
  }, [registrationApiUrl, projectId, userId, tokenProvider, clientId]);

  const preAlignmentApiClient = useMemo(() => {
    if (preAlignmentApiUrl) {
      return new PreAlignmentApiClient({
        projectId,
        tokenProvider,
        baseUrl: preAlignmentApiUrl,
        clientId,
      });
    }
  }, [projectId, tokenProvider, clientId, preAlignmentApiUrl]);

  return (
    <ApiClientContext.Provider
      value={{
        tokenProvider,
        coreApiClient,
        pointCloudApiClient,
        progressApiClient,
        projectApiClient,
        registrationApiClient,
        preAlignmentApiClient,
      }}
    >
      {children}
    </ApiClientContext.Provider>
  );
}
