import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  SxProps,
  Theme,
} from "@mui/material";
import { forwardRef } from "react";

interface IIconButtonProps extends MuiIconButtonProps {
  /** Based on the variant, the styling of the IconButton changes */
  variant?: "MenuBarIcon";

  /** Additional styles can be provided */
  sx?: SxProps<Theme>;
}

/**
 * @returns IconButton according to Faro designs
 */
export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  function IconButton({ children, variant, sx, ...props }, ref) {
    return (
      <MuiIconButton
        title="Home"
        ref={ref}
        sx={[
          {
            ...(variant === "MenuBarIcon" && {
              height: "48px",
              width: "48px",
            }),
            ["> *"]: {
              height: "24px",
              width: "24px",
            },
          },
          // Appending any additional style used, where the component is initialised
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        // Appending any additional props of IconButton used, where the component is initialised
        {...props}
      >
        {children}
      </MuiIconButton>
    );
  },
);
