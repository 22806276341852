import { ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";

/** @returns a wrapper for a related group of ToolButton with the proper FARO style */
export function ToolGroup({
  children,
  orientation = "vertical",
  sx = [],
  ...rest
}: ToggleButtonGroupProps): JSX.Element {
  return (
    <ToggleButtonGroup
      orientation={orientation}
      exclusive={true}
      sx={[
        {
          "&:first-of-type": {
            "> *:first-of-type, > *:first-of-type:hover": {
              "&.MuiButtonBase-root, .MuiButtonBase-root": {
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              },
            },
          },
          "&:last-of-type": {
            "> *:last-of-type, > *:last-of-type:hover": {
              "&.MuiButtonBase-root, .MuiButtonBase-root": {
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              },
            },
          },
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </ToggleButtonGroup>
  );
}
