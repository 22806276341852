import { ToggleButtonProps } from "@mui/material";
import { forwardRef } from "react";
import { blue, neutral } from "../colors";
import { ToggleButton } from "../toggle-button/toggle-button";

export type ToolButtonProps = Omit<ToggleButtonProps, "value"> & {
  /** The value for this ToolButton if used in an exclusive ToolGroup */
  value?: ToggleButtonProps["value"];
};

/** @returns a single ToolButton of a ToolGroup with the proper FARO style */
export const ToolButton = forwardRef<HTMLButtonElement, ToolButtonProps>(
  function ToolButton(
    { children, selected, sx, value = "default", ...rest },
    ref,
  ): JSX.Element | null {
    return (
      <ToggleButton
        ref={ref}
        value={value}
        selected={selected}
        sx={[
          {
            borderRadius: 0,
            border: 0,
            backdropFilter: "none",
            ".MuiBadge-badge": {
              borderColor: neutral[999],
            },
            "&:hover .MuiBadge-badge": {
              borderColor: `color-mix(in srgb, ${neutral[900]}, ${neutral[500]} 25%)`,
            },
            "&.Mui-selected .MuiBadge-badge": {
              borderColor: blue[500],
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        {children}
      </ToggleButton>
    );
  },
);
