import { FaroText } from "../text/faro-text/faro-text";
import { ContentEditable } from "./content-editable";
import { FaroRichTextCore, FaroRichTextCoreProps } from "./faro-rich-text-core";
import { MaxLengthPlugin, MaxLengthPluginProps } from "./max-length-plugin";

export type FaroRichTextViewerProps = Pick<
  FaroRichTextCoreProps,
  "dark" | "sx" | "onError" | "label" | "initialText"
> &
  MaxLengthPluginProps;

/**
 * @returns Version of the FaroRichText component that is specialized for rendering read only content
 */
export function FaroRichTextViewer({
  onError,
  initialText,
  dark,
  label,
  maxLength,
  onTextShortened,
}: FaroRichTextViewerProps): JSX.Element {
  return (
    <FaroRichTextCore
      onError={onError}
      initialText={initialText}
      readOnly
      lexicalPlugins={
        <MaxLengthPlugin
          maxLength={maxLength}
          onTextShortened={onTextShortened}
        />
      }
    >
      <>
        <FaroText variant="heading14" dark={dark} sx={{ pb: 1 }}>
          {label}
        </FaroText>

        {/* Wrapping the RichContent to adjust the styling */}
        <FaroText variant="bodyM" dark={dark}>
          <ContentEditable readOnly dark={dark} />
        </FaroText>
      </>
    </FaroRichTextCore>
  );
}
