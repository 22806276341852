import { assert } from "@faro-lotv/foundation";
import {
	BufferGeometry,
	Float32BufferAttribute,
	Int32BufferAttribute,
	Intersection,
	Matrix4,
	Object3D,
	Points,
	Ray,
	Raycaster,
	Sphere,
	Texture,
	Vector3,
} from "three";
import { MapPlaceholdersMaterial } from "../Materials/MapPlaceholdersMaterial";
import { pixels2m } from "../Utils/CameraUtils";

/**
 * BitFlag used to signal the state of a placeholders to the shader
 *
 * Using a BitFlag because a placeholder can be both hovered and selected at the same time
 */
/* eslint-disable @typescript-eslint/no-magic-numbers */
enum PlaceholderStateFlags {
	/** The placeholder is in the normal state */
	NORMAL = 0,

	/** The placeholder is hovered */
	HOVERED = 1,

	/** The placeholder is selected */
	SELECTED = 2,

	/** The placeholder is hidden */
	HIDDEN = 4,

	/** The placeholder is in a custom state */
	CUSTOM = 8,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

/** Attributes needed by the MapPlaceholdersGeometry class */
type MapPlaceholdersGeometryAttributes = {
	/** The positions of all the placeholders */
	position: Float32BufferAttribute;

	/** A flag for each placeholders with it's current state */
	state: Int32BufferAttribute;

	/** The custom RGB color for each placeholder, normalized */
	color: Float32BufferAttribute;
};

/**
 * Geometry class to manage the position and state of all the placeholders
 */
class MapPlaceholdersGeometry extends BufferGeometry {
	#selected: number | undefined = undefined;
	#hovered: number | undefined = undefined;
	#hidden: number[] = [];
	#custom: number[] = [];

	override attributes: MapPlaceholdersGeometryAttributes;

	/**
	 * Construct a MapPlaceholdersGeometry on a set of positions
	 *
	 * @param positions all the placeholders positions
	 */
	constructor(positions: Vector3[]) {
		super();
		this.attributes = {
			position: new Float32BufferAttribute(positions.map((p) => p.toArray()).flat(), 3, false),
			state: new Int32BufferAttribute(new Array(positions.length).fill(0), 1, false),
			color: new Float32BufferAttribute(positions.map(() => [0, 0, 0, 0]).flat(), 3, false),
		};
		this.setAttribute("position", this.attributes.position);
		this.setAttribute("state", this.attributes.state);
		this.setAttribute("color", this.attributes.color);
	}

	/**
	 * @param index of the placeholder
	 * @returns the state of the placeholders
	 */
	activeStateAt(index: number): PlaceholderStateFlags {
		switch (index) {
			case this.#selected:
				return PlaceholderStateFlags.SELECTED;
			case this.#hovered:
				return PlaceholderStateFlags.HOVERED;
		}
		if (this.#hidden.includes(index)) {
			return PlaceholderStateFlags.HIDDEN;
		}
		return PlaceholderStateFlags.NORMAL;
	}

	/** @returns the index of the selected placeholder */
	get selected(): number | undefined {
		return this.#selected;
	}

	/** Set the index of the selected placeholder */
	set selected(index: number | undefined) {
		if (this.#selected !== undefined) {
			const state = this.attributes.state.getX(this.#selected);
			this.attributes.state.set([state & ~PlaceholderStateFlags.SELECTED], this.#selected);
		}
		if (index !== undefined) {
			const state = this.attributes.state.getX(index);
			this.attributes.state.set([state | PlaceholderStateFlags.SELECTED], index);
		}
		this.#selected = index;
		this.attributes.state.needsUpdate = true;
	}

	/** @returns the index of the hovered placeholder */
	get hovered(): number | undefined {
		return this.#hovered;
	}

	/** Set the index of the hovered placeholder */
	set hovered(index: number | undefined) {
		if (this.#hovered !== undefined) {
			const state = this.attributes.state.getX(this.#hovered);
			this.attributes.state.set([state & ~PlaceholderStateFlags.HOVERED], this.#hovered);
		}
		if (index !== undefined) {
			const state = this.attributes.state.getX(index);
			this.attributes.state.set([state | PlaceholderStateFlags.HOVERED], index);
		}
		this.#hovered = index;
		this.attributes.state.needsUpdate = true;
	}

	get hidden(): number[] {
		return this.#hidden;
	}

	set hidden(indexes: number[]) {
		// Shallow comparison to not update the attribute array
		// if the hidden array is the same as before
		if (this.#hidden === indexes) return;
		for (let i = 0; i < this.attributes.state.count; ++i) {
			let state = this.attributes.state.array[i];
			if (indexes.includes(i)) {
				state |= PlaceholderStateFlags.HIDDEN;
			} else {
				state &= ~PlaceholderStateFlags.HIDDEN;
			}
			this.attributes.state.set([state], i);
		}

		this.#hidden = indexes;
		this.attributes.state.needsUpdate = true;
	}

	/** @returns The list of placeholders in a custom state */
	get custom(): number[] {
		return this.#custom;
	}

	/** Set the placeholders that are in a custom state */
	set custom(indexes: number[]) {
		for (let i = 0; i < this.attributes.state.count; ++i) {
			let state = this.attributes.state.array[i];
			if (indexes.includes(i)) {
				state |= PlaceholderStateFlags.CUSTOM;
			} else {
				state &= ~PlaceholderStateFlags.CUSTOM;
			}
			this.attributes.state.set([state], i);
		}

		this.attributes.state.needsUpdate = true;
	}

	/** Set the color of each placeholder */
	set colors(array: Float32Array) {
		assert(array.length === this.attributes.position.array.length);
		for (let i = 0; i < array.length / 3; ++i) {
			this.attributes.color.setXYZ(i, array[3 * i], array[3 * i + 1], array[3 * i + 2]);
		}
		this.attributes.color.needsUpdate = true;
	}
}

/**
 * A class to optimize the rendering and interaction of a high number of 2d placeholders (> 500) on a map
 *
 * The placeholders are rendered as 2d points of fixed size with three available.
 * All the points are by default rendered in a normal state with a specific texture
 * Then there's an hover state that will be rendered with a different texture and a size factor
 * Then there's a selected state that will be rendered with another texture and size factor
 */
export class MapPlaceholders extends Points<MapPlaceholdersGeometry, MapPlaceholdersMaterial> {
	/** Private cache objects needed for the raycasting */
	#raycastPrivates = {
		sphere: new Sphere(),
		invMatrix: new Matrix4(),
		localRay: new Ray(),
		position: new Vector3(),
	};

	/** Current viewport height needed for precise raycasting */
	viewportHeight?: number;

	/**
	 * Construct a MapPlaceholders object on a set of points
	 *
	 * @param positions position of all the placeholders
	 */
	constructor(positions: Vector3[]) {
		super(new MapPlaceholdersGeometry(positions), new MapPlaceholdersMaterial());
	}

	/** @returns the number of placeholders */
	get count(): number {
		return this.geometry.attributes.position.count;
	}

	/** @returns the texture used to render the placeholder default state */
	get defaultMap(): Texture | undefined {
		return this.material.uniforms.map.value ?? undefined;
	}

	/** Change the texture used to render the placeholder default state */
	set defaultMap(s: Texture | undefined) {
		this.material.uniforms.map.value = s ?? null;
	}

	/** @returns the texture used to render the placeholder hovered state */
	get hoveredMap(): Texture | undefined {
		return this.material.uniforms.hoveredMap.value ?? undefined;
	}

	/** Change the texture used to render the placeholder hovered state */
	set hoveredMap(s: Texture | undefined) {
		this.material.uniforms.hoveredMap.value = s ?? null;
	}

	/** @returns the texture used to render the placeholder selected state */
	get selectedMap(): Texture | undefined {
		return this.material.uniforms.selectedMap.value ?? undefined;
	}

	/** Change the texture used to render the placeholder selected state */
	set selectedMap(s: Texture | undefined) {
		this.material.uniforms.selectedMap.value = s ?? null;
	}

	/** @returns the texture used to render the placeholder in a custom state */
	get customMap(): Texture | undefined {
		return this.material.uniforms.customMap.value ?? undefined;
	}

	/** Change the texture used to render the placeholder in custom state*/
	set customMap(s: Texture | undefined) {
		this.material.uniforms.customMap.value = s ?? null;
	}

	/** @returns the base size of the placeholders in pixels */
	get size(): number {
		return this.material.uniforms.size.value;
	}

	/** Change the base size of the placeholders in pixels */
	set size(value: number) {
		assert(value > 0, "size should be a positive integer");
		this.material.uniforms.size.value = value;
	}

	/** @returns the size factor applied to hovered placeholders */
	get hoveredSizeFactor(): number {
		return this.material.uniforms.hoveredSizeFactor.value;
	}

	/** Change the size factor applied to hovered placeholders */
	set hoveredSizeFactor(value: number) {
		assert(value > 0, "hoveredSizeFactor should be a positive integer");
		this.material.uniforms.hoveredSizeFactor.value = value;
	}

	/** @returns the size factor applied to selected placeholders */
	get selectedSizeFactor(): number {
		return this.material.uniforms.selectedSizeFactor.value;
	}

	/** Change the size factor applied to selected placeholders */
	set selectedSizeFactor(value: number) {
		assert(value > 0, "selectedSizeFactor should be a positive integer");
		this.material.uniforms.selectedSizeFactor.value = value;
	}

	/** @returns the index of the current hovered placeholder */
	get hovered(): number | undefined {
		return this.geometry.hovered;
	}

	/** Change the index of the current hovered placeholder */
	set hovered(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.geometry.hovered = index;
	}

	/** @returns the index of the current selected placeholder */
	get selected(): number | undefined {
		return this.geometry.selected;
	}

	/** Change the index of the current selected placeholder */
	set selected(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.geometry.selected = index;
	}

	/** @returns the list of placeholders in a custom state */
	get custom(): number[] {
		return this.geometry.custom;
	}

	/** Set the list of placeholders that are in a custom state */
	set custom(indexes: number[]) {
		this.geometry.custom = indexes;
	}

	/** @returns The list of placeholders currently hidden */
	get hidden(): number[] {
		return this.geometry.hidden;
	}

	/** Set the list of placeholders that should be hidden */
	set hidden(indexes: number[]) {
		this.geometry.hidden = indexes;
	}

	/**
	 * Compute the size of a specific placeholder based on its current state
	 *
	 * @param index of the placeholder
	 * @returns the actual size
	 */
	#sizeOf(index: number): number {
		const pointState = this.geometry.activeStateAt(index);
		switch (pointState) {
			case PlaceholderStateFlags.SELECTED:
				return this.size * this.selectedSizeFactor;
			case PlaceholderStateFlags.HOVERED:
				return this.size * this.hoveredSizeFactor;
		}
		return this.size;
	}

	/** Set a custom color for each placeholder */
	set colors(array: Float32Array | undefined) {
		if (array) {
			this.geometry.colors = array;
			this.material.defines = {
				...this.material.defines,
				USE_COLORS: "",
			};
		} else {
			delete this.material.defines.USE_COLORS;
		}
		this.material.needsUpdate = true;
	}

	/**
	 * Update the position of the placeholders, assuming the input array has
	 * the same number of elements as before
	 *
	 * @param positions The new positions
	 */
	updatePositions(positions: Vector3[]): void {
		const attribute = this.geometry.getAttribute("position");
		if (positions.length !== attribute.count) {
			throw new Error("Cannot update using a different number of positions");
		}
		for (const [index, p] of positions.entries()) {
			attribute.setXYZ(index, p.x, p.y, p.z);
		}
		attribute.needsUpdate = true;
	}

	/**
	 * Custom raycaster implementation for precise raycasting
	 *
	 * ThreeJS default to a fixed threshold to decide if a ray intersect a point but this threshold
	 * do not take into account the point size so the picking is imprecise.
	 *
	 * @param raycaster doing the raycast
	 * @param intersects list of intersections
	 */
	raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		const { geometry, matrixWorld } = this;
		const { sphere, invMatrix, localRay, position } = this.#raycastPrivates;
		const { camera } = raycaster;

		// Without a camera and a viewport or with an indexed geometry
		// we need to fallback the default ThreeJS algorithm
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!camera || !this.viewportHeight || geometry.index) {
			super.raycast(raycaster, intersects);
			return;
		}

		// Checking boundingSphere distance to ray
		if (geometry.boundingSphere === null) geometry.computeBoundingSphere();
		if (geometry.boundingSphere) sphere.copy(geometry.boundingSphere);
		sphere.applyMatrix4(matrixWorld);
		sphere.radius += this.size * Math.max(this.hoveredSizeFactor, this.selectedSizeFactor);
		if (raycaster.ray.intersectsSphere(sphere) === false) return;

		// Compute local ray to reduce matrix computations for each point
		invMatrix.copy(matrixWorld).invert();
		localRay.copy(raycaster.ray);

		for (let index = 0; index < geometry.attributes.position.count; index++) {
			if (geometry.attributes.state.array[index] & PlaceholderStateFlags.HIDDEN) {
				continue;
			}

			position.fromBufferAttribute(geometry.attributes.position, index).applyMatrix4(matrixWorld);

			const distance = position.distanceTo(localRay.origin);
			// Compute the threshold to raycast on each point based to the distance from the ray and the
			// current camera parameters
			const threshold = pixels2m(this.#sizeOf(index), camera, this.viewportHeight, distance) / 2;
			const localThresholdSq = threshold * threshold;

			const rayPointDistanceSq = localRay.distanceSqToPoint(position);

			if (rayPointDistanceSq < localThresholdSq) {
				const intersectPoint = new Vector3();

				localRay.closestPointToPoint(position, intersectPoint);

				const distance = raycaster.ray.origin.distanceTo(intersectPoint);

				if (distance < raycaster.near || distance > raycaster.far) return;

				intersects.push({
					distance,
					distanceToRay: Math.sqrt(rayPointDistanceSq),
					point: intersectPoint,
					index,
					face: null,
					object: this,
				});
			}
		}
	}
}
