import { TypedEvent } from "@faro-lotv/foundation";
import {
	BoxGeometry,
	BoxHelper,
	Camera,
	ColorRepresentation,
	Euler,
	Group,
	MathUtils,
	Matrix4,
	Mesh,
	Object3D,
	Plane,
	Quaternion,
	Raycaster,
	Vector2,
	Vector3,
} from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { memberWithPrivateData, pixels2m } from "../../Utils";
import { TouchEvents } from "../TouchEvents";
import { BoxHandle, BoxHandleState } from "./BoxHandle";
import { BoxSide, BoxSideState } from "./BoxSide";

// Default values for snapping
const ROTATION_SNAP_DEG = 15;
const SCALE_SNAP = 0.25;

/** Minimum size of the clipping box. Must be greater than 0 to avoid numerical errors. */
const MIN_BOX_SIZE = 0.01;

/** Name to assign to the parent object of the controls scene graph */
export const BOX_CONTROLS_NAME = "TheBoxControls";

/** The colors for the BoxControls */
export interface BoxControlsColors {
	/** The base color of the box */
	box: ColorRepresentation;

	/** The main color of the draggable handles */
	handleMain: ColorRepresentation;
	/** The hovered color of the draggable handles */
	handleHovered: ColorRepresentation;
	/** The focused color of the draggable handles */
	handleFocused: ColorRepresentation;
}

/** Defaults to faro blue theme colors */
const DEFAULT_COLORS: BoxControlsColors = {
	box: 0x6699ff,
	handleMain: 0x1f65f0,
	handleHovered: 0x0e4ecc,
	handleFocused: 0x113c9d,
};

export type TransformControlsMode = "translate" | "rotate" | "scale";

export type TransformSpace = "local" | "world";

type BoxControlsProps = {
	/* The camera to use for clicking */
	camera: Camera;
	/* The element to listen for events */
	element: HTMLElement;
	/* Initial box size */
	size?: Vector3;
	/* Initial box position */
	position?: Vector3;
	/* Initial box rotation */
	rotation?: Quaternion;
	/* Colors to use for the box controls. Defaults to a faro blue. */
	colors?: Partial<BoxControlsColors>;
	/* The size of the box handles in pixels*/
	handleSize?: number;
};

/**
 * A class to place and edit on the fly a box in a scene.
 * Useful to select a specific area
 *
 * @beta this component could still change it's api
 */
export class BoxControls extends Object3D {
	// The camera to use for clicking
	#camera: Camera;
	//  The element to listen for events
	#element?: HTMLElement;
	// Handling touch events
	#touchEvents = new TouchEvents();

	// Group used to hold all the child objects
	#group: Group;

	// The mesh used to render this box
	#boxSides: BoxSide[] = [];
	#boxOutline: BoxHelper;
	#transformControl: TransformControls;

	#pickPoints = new Array<BoxHandle>();
	#clippingPlanes = new Array<Plane>();

	#mouseDown = false;
	#dragging = false;
	#currentHandle: BoxHandle | undefined;
	#hoveredHandle: BoxHandle | undefined;
	#hoveredSide: BoxSide | undefined;
	#raycaster = new Raycaster();
	#mouseClip = new Vector2();

	/** Event for when this control has started being interacted with */
	interactStarted = new TypedEvent<void>();
	/** Event for when this control has stopped being interacted with */
	interactionStopped = new TypedEvent<void>();
	/** Event for when if clipping has been changed between inside and outside */
	clipInsideChanged = new TypedEvent<boolean>();
	/** Event for when the clipping planes change */
	clippingPlanesChanged = new TypedEvent<Plane[]>();
	/** event for when the transform mode has changed */
	transformModeChanged = new TypedEvent<TransformControlsMode>();

	/** Visibility setting for each of the 6 face handles */
	handles: {
		xPlus: { visible: boolean };
		xMinus: { visible: boolean };
		yPlus: { visible: boolean };
		yMinus: { visible: boolean };
		zPlus: { visible: boolean };
		zMinus: { visible: boolean };
	};
	keys = {
		toggleLocalWorldSpace: "q",
		snapping: "Shift",
		toTranslateMode: "w",
		toRotateMode: "r",
		toScaleMode: "e",
		toggleClipInside: "t",
		increaseGizmoSize: "=",
		decreaseGizmoSize: "-",
	};

	/** Maps to link BoxSides to their corresponding BoxHandles and vice versa */
	#handleToSide = new Map<BoxHandle, BoxSide>();
	#sideToHandle = new Map<BoxSide, BoxHandle>();

	#enableTranslateX = true;
	/** @returns true if you can translate on X axis */
	get enableTranslateX(): boolean {
		return this.#enableTranslateX;
	}
	/** true if you can translate on X axis */
	set enableTranslateX(val: boolean) {
		this.#enableTranslateX = val;
		this.showAxes();
	}

	#enableTranslateY = true;
	/** @returns true if you can translate on Y axis */
	get enableTranslateY(): boolean {
		return this.#enableTranslateY;
	}
	/** true if you can translate on Y axis */
	set enableTranslateY(val: boolean) {
		this.#enableTranslateY = val;
		this.showAxes();
	}

	#enableTranslateZ = true;
	/** @returns true if you can translate on Z axis */
	get enableTranslateZ(): boolean {
		return this.#enableTranslateZ;
	}
	/** true if you can translate on Z axis */
	set enableTranslateZ(val: boolean) {
		this.#enableTranslateZ = val;
		this.showAxes();
	}

	#enableRotateX = true;
	/** @returns true if you can rotate on X axis */
	get enableRotateX(): boolean {
		return this.#enableRotateX;
	}
	/** true if you can rotate on X axis */
	set enableRotateX(val: boolean) {
		this.#enableRotateX = val;
		this.showAxes();
	}

	#enableRotateY = true;
	/** @returns true if you can rotate on Y axis */
	get enableRotateY(): boolean {
		return this.#enableRotateY;
	}
	/** true if you can rotate on Y axis */
	set enableRotateY(val: boolean) {
		this.#enableRotateY = val;
		this.showAxes();
	}

	#enableRotateZ = true;
	/** @returns true if you can rotate on Z axis */
	get enableRotateZ(): boolean {
		return this.#enableRotateZ;
	}
	/** true if you can rotate on Z axis */
	set enableRotateZ(val: boolean) {
		this.#enableRotateZ = val;
		this.showAxes();
	}

	#enableScaleX = true;
	/** @returns true if you can scale on X axis */
	get enableScaleX(): boolean {
		return this.#enableScaleX;
	}
	/** true if you can scale on X axis */
	set enableScaleX(val: boolean) {
		this.#enableScaleX = val;
		this.showAxes();
	}

	#enableScaleY = true;
	/** @returns true if you can scale on Y axis */
	get enableScaleY(): boolean {
		return this.#enableScaleY;
	}
	/** true if you can scale on Y axis */
	set enableScaleY(val: boolean) {
		this.#enableScaleY = val;
		this.showAxes();
	}

	#enableScaleZ = true;
	/** @returns true if you can scale on Z axis */
	get enableScaleZ(): boolean {
		return this.#enableScaleZ;
	}
	/** true if you can scale on Z axis */
	set enableScaleZ(val: boolean) {
		this.#enableScaleZ = val;
		this.showAxes();
	}

	#clipInside = true;
	/** @returns true to clip the inside of the box */
	get clipInside(): boolean {
		return this.#clipInside;
	}
	/** true to clip the inside of the box */
	set clipInside(value: boolean) {
		if (value !== this.#clipInside) {
			this.#clipInside = value;
			this.recomputeBox();
			this.clipInsideChanged.emit(value);
		}
	}

	/**
	 *	Constructs the box controls.
	 */
	constructor({
		camera,
		element,
		size = new Vector3(1, 1, 1),
		position,
		rotation,
		colors,
		handleSize = 10,
	}: BoxControlsProps) {
		super();
		this.name = BOX_CONTROLS_NAME;
		this.#camera = camera;
		this.#element = element;
		this.#handleSize = handleSize;

		this.#group = new Group();
		this.add(this.#group);

		const clr = { ...DEFAULT_COLORS, ...colors };
		this.#boxOutline = new BoxHelper(new Mesh(new BoxGeometry(1, 1, 1)), clr.box);
		this.#group.add(this.#boxOutline);

		const sideTransforms: Array<[Vector3, Euler]> = [
			[new Vector3(0.5, 0, 0), new Euler(0, Math.PI / 2, 0)],
			[new Vector3(-0.5, 0, 0), new Euler(0, -Math.PI / 2, 0)],
			[new Vector3(0, 0.5, 0), new Euler(-Math.PI / 2, 0, 0)],
			[new Vector3(0, -0.5, 0), new Euler(Math.PI / 2, 0, 0)],
			[new Vector3(0, 0, 0.5), new Euler(0, 0, 0)],
			[new Vector3(0, 0, -0.5), new Euler(Math.PI, 0, 0)],
		];

		const sideRotToPPRot = new Quaternion().setFromEuler(new Euler(Math.PI / 2, 0, 0));

		const boxColors = {
			main: clr.handleMain,
			hovered: clr.handleHovered,
			focused: clr.handleFocused,
		};
		for (const [sidePos, sideRot] of sideTransforms) {
			const side = new BoxSide(sidePos, sideRot, clr.box);
			this.#boxSides.push(side);

			const ppRot = new Euler().setFromQuaternion(
				new Quaternion().setFromEuler(sideRot).multiply(sideRotToPPRot),
			);

			const handle = new BoxHandle(sidePos, ppRot, boxColors);
			this.#pickPoints.push(handle);

			this.#handleToSide.set(handle, side);
			this.#sideToHandle.set(side, handle);
		}

		this.#group.add(...this.#boxSides, ...this.#pickPoints);

		this.handles = {
			xPlus: this.#pickPoints[0],
			xMinus: this.#pickPoints[1],
			yPlus: this.#pickPoints[2],
			yMinus: this.#pickPoints[3],
			zPlus: this.#pickPoints[4],
			zMinus: this.#pickPoints[5],
		};

		for (const pp of this.#pickPoints) {
			this.#clippingPlanes.push(new Plane().setFromNormalAndCoplanarPoint(pp.axis, pp.position));
		}

		this.#transformControl = new TransformControls(camera, element);

		this.#transformControl.attach(this.#group);
		this.add(this.#transformControl);

		if (position) this.#group.position.copy(position);
		if (rotation) this.#group.setRotationFromQuaternion(rotation);

		this.#group.scale.copy(size);

		this.recomputeBox();

		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (element) {
			this.attachElement(element);
		}

		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseMoved.on(this.onMouseMove);
		this.traverse((o) => (o.renderOrder = 10));
	}

	/** Disabling the default raycasting on this object. */
	override raycast(): void {}

	/**
	 * Dispose all resources and event connections
	 */
	dispose(): void {
		this.#transformControl.detach();
		this.#transformControl.dispose();
		this.detachElement();
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseMoved.off(this.onMouseMove);
	}

	/** Enable/Disable this control class */
	set enabled(e: boolean) {
		this.#touchEvents.enabled = e;
	}

	/** @returns true if the controls are enabled */
	get enabled(): boolean {
		return this.#touchEvents.enabled;
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attachElement(target: HTMLElement): void {
		this.#touchEvents.attach(target);
		document.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
	}

	/**
	 * If attached to an element, detach all event listeners
	 */
	detachElement(): void {
		this.#touchEvents.detach();
		document.removeEventListener("keydown", this.onKeyDown);
		window.removeEventListener("keyup", this.onKeyUp);
	}

	/** @returns the box clipping planes */
	getClippingPlanes(): Plane[] {
		return this.#clippingPlanes;
	}

	/**
	 * Compute the clipping coordinates from the touchEvents mouse coords
	 *
	 * @returns The x/y coordinates in clipping space [-1, 1]
	 */
	mouseToClip(): Vector2 {
		if (!this.#element) throw new Error("Box Controls not attached");
		const mouse = this.#touchEvents.mouseCoords.position;
		this.#mouseClip.x = (mouse.x / this.#element.clientWidth) * 2 - 1;
		this.#mouseClip.y = 1 - (mouse.y / this.#element.clientHeight) * 2;
		return this.#mouseClip;
	}

	/**
	 * Handle mouse down
	 *
	 * @param ev The mouse down event
	 */
	private onMouseDown(ev: MouseEvent): void {
		if (ev.button === 0) {
			this.#mouseDown = true;
			const clip = this.mouseToClip();
			this.#raycaster.setFromCamera(clip, this.#camera);
			const hits = this.#raycaster.intersectObjects<BoxHandle>(this.#pickPoints.filter((pp) => pp.visible));
			if (hits.length > 0) {
				hits.sort((a, b) => a.distance - b.distance);
				this.#currentHandle = hits[0].object;
				this.#currentHandle.state = BoxHandleState.focused;
				this.interactStarted.emit();
			}
		}
	}

	/**
	 * Handle mouse up
	 *
	 * @param ev The mouse up event
	 */
	private onMouseUp(ev: MouseEvent): void {
		if (ev.button === 0) {
			this.#mouseDown = false;
			this.#dragging = false;
			if (this.#currentHandle) {
				this.#currentHandle.state = BoxHandleState.default;
				this.#currentHandle = undefined;
			}
			this.interactionStopped.emit();
		}
	}

	/** Handle mouse drag */
	onMouseDrag = memberWithPrivateData(() => {
		const currentAxis = new Vector3();
		const startPoint = new Vector3();
		const endPoint = new Vector3();
		const newPosition = new Vector3();

		return (): void => {
			if (this.#transformControl.dragging) {
				if (!this.#dragging) {
					this.interactStarted.emit();
					this.#dragging = true;
				}
				this.recomputeBox();
			} else if (this.#currentHandle) {
				this.#dragging = true;
				currentAxis.copy(this.#currentHandle.axis);
				startPoint.addVectors(this.#currentHandle.position, currentAxis.multiplyScalar(1000));
				const start = this.#group.localToWorld(startPoint);

				// The endpoint is set to the point where the box would have its minimum size
				currentAxis.copy(this.#currentHandle.axis);
				endPoint
					.set(0, 0, 0)
					.sub(this.#currentHandle.position)
					.add(currentAxis.divide(this.#group.scale).multiplyScalar(MIN_BOX_SIZE));
				const end = this.#group.localToWorld(endPoint);

				this.#raycaster.setFromCamera(this.mouseToClip(), this.#camera);

				this.#raycaster.ray.distanceSqToSegment(start, end, undefined, newPosition);
				this.#group.worldToLocal(newPosition);
				if (this.#currentHandle.axis.dot(newPosition) <= 0) {
					newPosition.copy(this.#currentHandle.axis);
					newPosition.multiplyScalar(0.1);
				}
				this.#currentHandle.position.copy(newPosition);
				this.recomputeBox();
			}
		};
	});

	/** Handle mouse move */
	onMouseMove(): void {
		if (!this.#mouseDown) {
			const clip = this.mouseToClip();
			this.#raycaster.setFromCamera(clip, this.#camera);
			const hits = this.#raycaster.intersectObjects<BoxHandle>(this.#pickPoints.filter((pp) => pp.visible));
			if (hits.length > 0) {
				hits.sort((a, b) => a.distance - b.distance);
				const h = hits[0].object;

				this.changeHoveredHandle(h);
				this.#transformControl.enabled = false;
			} else {
				this.changeHoveredHandle(undefined);
				this.#transformControl.enabled = this.showTransformControls;
			}

			if (this.#hoveredHandle) {
				const side = this.#handleToSide.get(this.#hoveredHandle);

				this.changeHoveredSide(side);
				this.changeHoveredHandle(side && this.#sideToHandle.get(side));
			} else {
				this.changeHoveredSide(undefined);
			}
		}
	}

	/**
	 * Changes the currently hovered handle and updates the visuals
	 *
	 * @param hovered The BoxHandle to show the hover effect on
	 */
	private changeHoveredHandle(hovered: BoxHandle | undefined): void {
		if (this.#hoveredHandle === hovered) return;
		if (this.#hoveredHandle?.state === BoxHandleState.hovered) {
			this.#hoveredHandle.state = BoxHandleState.default;
		}
		this.#hoveredHandle = hovered;
		if (this.#hoveredHandle?.state === BoxHandleState.default) {
			this.#hoveredHandle.state = BoxHandleState.hovered;
		}
	}

	/**
	 * Changes the currently hovered side and updates the visuals
	 *
	 * @param hovered The BoxSide to show the hover effect on
	 */
	private changeHoveredSide(hovered: BoxSide | undefined): void {
		if (this.#hoveredSide === hovered) return;
		if (this.#hoveredSide?.state === BoxSideState.hovered) {
			this.#hoveredSide.state = BoxSideState.default;
		}
		this.#hoveredSide = hovered;
		if (this.#hoveredSide?.state === BoxSideState.default) {
			this.#hoveredSide.state = BoxSideState.hovered;
		}
	}

	/**
	 * Calculates the scale and position of the box given the positions of all the handles after the handles are manipulated.
	 * Then recomputes the position and size of all the handles to keep them sized and aligned
	 * Finally updates all the clipping planes to align with the box.
	 */
	private recomputeBox = memberWithPrivateData(() => {
		// The world positions of the six handles
		const x1 = new Vector3();
		const x2 = new Vector3();
		const y1 = new Vector3();
		const y2 = new Vector3();
		const z1 = new Vector3();
		const z2 = new Vector3();
		// The bounding box size
		const scale = new Vector3();
		// The bounding box center, in coordinates local to the group
		const center = new Vector3();
		const cx = new Vector3();
		const cy = new Vector3();
		const cz = new Vector3();

		const tmp = new Vector3();
		// The world position of the bounding box center
		const gp = new Vector3();
		// The world position of any of the six box handles
		const ppw = new Vector3();
		// Three temp variables needed so that the box handles can compensate for the
		// global box's scale and rotation, in order to remain uniformly scaled.
		const invRotation = new Quaternion();
		const localScale = new Vector3();
		const sgnVector = new Vector3();

		return (): void => {
			if (!this.#group.parent) return;

			const pp = this.#pickPoints;
			pp[0].getWorldPosition(x1);
			pp[1].getWorldPosition(x2);
			pp[2].getWorldPosition(y1);
			pp[3].getWorldPosition(y2);
			pp[4].getWorldPosition(z1);
			pp[5].getWorldPosition(z2);

			const dx = x1.distanceTo(x2);
			const dy = y1.distanceTo(y2);
			const dz = z1.distanceTo(z2);

			scale.set(dx, dy, dz);

			cx.addVectors(x1, x2).multiplyScalar(0.5);
			cy.addVectors(y1, y2).multiplyScalar(0.5);
			cz.addVectors(z1, z2).multiplyScalar(0.5);

			this.#group.worldToLocal(cx);
			this.#group.worldToLocal(cy);
			this.#group.worldToLocal(cz);
			center.set(cx.x, cy.y, cz.z);
			this.#group.localToWorld(center);

			this.#group.position.copy(center);
			this.#group.scale.copy(scale);

			this.#group.getWorldPosition(tmp);
			gp.copy(tmp);
			for (let index = 0; index < this.#clippingPlanes.length; index++) {
				const plane = this.#clippingPlanes[index];
				const pp = this.#pickPoints[index];

				pp.position.normalize().multiplyScalar(0.5);
				pp.getWorldPosition(ppw);
				const vec = tmp.subVectors(ppw, gp).normalize();
				if (!this.#clipInside) vec.negate();
				plane.setFromNormalAndCoplanarPoint(vec, ppw);

				// Back out the parent scale from the box handles so they remain uniformly scaled.
				invRotation.copy(pp.quaternion);
				invRotation.invert();
				localScale.copy(this.#group.scale);
				localScale.applyQuaternion(invRotation);
				sgnVector.set(Math.sign(localScale.x), Math.sign(localScale.y), Math.sign(localScale.z));
				localScale.multiply(sgnVector);
				pp.baseScale.set(1 / localScale.x, 1 / localScale.y, 1 / localScale.z);
			}
			this.clippingPlanesChanged.emit(this.#clippingPlanes);
			this.update();
		};
	});

	/**
	 * Call once per frame to update the scale of the box handles based on the distance from the camera.
	 */
	update(): void {
		for (const pp of this.#pickPoints) {
			pp.scale.copy(pp.baseScale).multiplyScalar(this.computePixelsToMetersFactor(pp));
		}
	}

	/**
	 * @returns the scale factor of the box handles based on the distance from the camera.
	 * @param handle the handle to check
	 */
	private computePixelsToMetersFactor = memberWithPrivateData(() => {
		const handlePos = new Vector3();

		return (handle: Object3D): number => {
			const vh = this.#element?.clientHeight;
			if (!vh) return this.#handleSize;
			handle.getWorldPosition(handlePos);
			handlePos.applyMatrix4(this.#camera.matrixWorldInverse);
			const dist = Math.abs(handlePos.z);
			return pixels2m(this.#handleSize, this.#camera, vh, dist);
		};
	});

	/** Updates which axes are shown given the visibility settings and current mode */
	private showAxes(): void {
		const tx = this.#transformControl;
		switch (this.mode) {
			case "rotate":
				tx.showX = this.enableRotateX;
				tx.showY = this.enableRotateY;
				tx.showZ = this.enableRotateZ;
				break;
			case "scale":
				tx.showX = this.enableScaleX;
				tx.showY = this.enableScaleY;
				tx.showZ = this.enableScaleZ;
				break;
			case "translate":
				tx.showX = this.enableTranslateX;
				tx.showY = this.enableTranslateY;
				tx.showZ = this.enableTranslateZ;
				break;
		}
		// This is necessary because THREE updates the handlers visibility
		// in the updateMatrixWorld function (╯°□°）╯︵ ┻━┻
		this.#transformControl.updateMatrixWorld(true);
	}

	/** */
	set space(space: TransformSpace) {
		this.#transformControl.setSpace(space);
	}
	/** @returns The transform space, "world" or "local" */
	get space(): TransformSpace {
		return this.#transformControl.space;
	}
	/** */
	set mode(mode: TransformControlsMode) {
		this.#transformControl.setMode(mode);
		this.showAxes();
		this.transformModeChanged.emit(mode);
	}
	/** @returns The transform mode, "translate", "rotate", or "scale" */
	get mode(): TransformControlsMode {
		return this.#transformControl.mode;
	}
	/** Whether the transform controls are shown */
	set showTransformControls(visible: boolean) {
		this.#transformControl.visible = visible;
	}
	/** @returns whether the transform controls are shown */
	get showTransformControls(): boolean {
		return this.#transformControl.visible;
	}
	/** @returns the size of the box in meters */
	get size(): Vector3 {
		return this.#group.scale;
	}
	/** Sets the size of the box in meters */
	set size(size: Vector3) {
		this.#group.scale.copy(size);
		this.recomputeBox();
	}
	/** @returns the position of the center of the box in world space */
	get pos(): Vector3 {
		return this.#group.position;
	}
	/** Sets the position of the center of the box in world space */
	set pos(pos: Vector3) {
		this.#group.position.copy(pos);
		this.recomputeBox();
	}

	/** @returns the rotation of the bounding box */
	get rot(): Quaternion {
		return this.#group.quaternion;
	}

	/** @returns the world matrix of the user defined box */
	get boxMatrixWorld(): Matrix4 {
		return this.#group.matrixWorld;
	}

	#handleSize: number;
	/** @returns The scale of the handles relative to the size of the face they are on */
	get handleSize(): number {
		return this.#handleSize;
	}
	/** Sets the scale of the handles relative to the size of the face they are on */
	set handleSize(scale: number) {
		this.#handleSize = scale;
		this.recomputeBox();
	}

	/** Set the axis control to "translate" mode */
	toTranslateMode(): void {
		this.mode = "translate";
	}
	/** Set the axis control to "rotate" mode */
	toRotateMode(): void {
		this.mode = "rotate";
	}
	/** Set the axis control to "scale" mode */
	toScaleMode(): void {
		this.mode = "scale";
	}
	/** Toggle the axis control between "local" and "world" space */
	toggleLocalWorldSpace(): void {
		this.#transformControl.setSpace(this.#transformControl.space === "local" ? "world" : "local");
	}

	/**
	 *
	 * @param event the event
	 */
	onKeyDown(event: KeyboardEvent): void {
		if (this.#mouseDown && event.key !== this.keys.snapping) return;

		switch (event.key) {
			case this.keys.toggleLocalWorldSpace:
				this.toggleLocalWorldSpace();
				break;

			case this.keys.snapping:
				this.#transformControl.setTranslationSnap(100);
				this.#transformControl.setRotationSnap(MathUtils.degToRad(ROTATION_SNAP_DEG));
				this.#transformControl.setScaleSnap(SCALE_SNAP);
				break;

			case this.keys.toTranslateMode:
				this.toTranslateMode();
				break;

			case this.keys.toRotateMode:
				this.toRotateMode();
				break;

			case this.keys.toScaleMode:
				this.toScaleMode();
				break;

			case this.keys.toggleClipInside:
				this.clipInside = !this.clipInside;
				break;

			case this.keys.increaseGizmoSize:
				this.#transformControl.setSize(this.#transformControl.size + 0.1);
				break;

			case this.keys.decreaseGizmoSize:
				this.#transformControl.setSize(Math.max(this.#transformControl.size - 0.1, 0.1));
				break;
		}
	}
	/**
	 *
	 * @param event the event
	 */
	onKeyUp(event: KeyboardEvent): void {
		switch (event.key) {
			case this.keys.snapping:
				this.#transformControl.setTranslationSnap(null);
				this.#transformControl.setRotationSnap(null);
				this.#transformControl.setScaleSnap(null);
				break;
		}
	}
}
