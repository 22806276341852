import { assert } from "@faro-lotv/foundation";
import {
	InstancedBufferAttribute,
	InstancedMesh,
	Intersection,
	Matrix4,
	Object3D,
	PlaneGeometry,
	Quaternion,
	Raycaster,
	Texture,
	Vector3,
} from "three";
import { OverviewPlaceholdersMaterial } from "../Materials/OverviewPlaceholdersMaterial";

/** Single shared geometry for all the placeholders */
const PLACEHOLDER_GEOMETRY = new PlaneGeometry(0.5, 0.5);

/** A fixed 90 degrees rotation around the X axis */
const ROTATION_FIX_MATRIX = new Matrix4().makeRotationX(-Math.PI / 2);

// Temporary objects to avoid memory allocation
const ALLOCATED_VECTOR1 = new Vector3();
const ALLOCATED_VECTOR2 = new Vector3();
const ALLOCATED_QUATERNION = new Quaternion();
const ALLOCATED_MATRIX = new Matrix4();

/**
 * Object optimized to render a high number of placeholders as textured quads.
 * Each placeholder can be in one of three state: [normal, hovered, selected]
 * Each state have a separate size factor (normal defaults to 1) and texture used to be rendered
 */
export class OverviewPlaceholders extends InstancedMesh<PlaneGeometry, OverviewPlaceholdersMaterial> {
	/** The list of indices of the hidden placeholders */
	#hidden: number[] = [];
	/** The attribute which provides the hidden information for each placeholder to the GPU*/
	#hiddenAttribute: InstancedBufferAttribute;
	/** A temporary array allocated once to avoid memory re-allocation. Used during raycasting */
	#localIntersects: Array<Intersection<Object3D>> = [];

	/**
	 * Construct a MapPlaceholders object on a set of points
	 *
	 * @param positions position of all the placeholders
	 */
	constructor(positions: Vector3[]) {
		super(PLACEHOLDER_GEOMETRY, new OverviewPlaceholdersMaterial(), positions.length);
		const hiddenState = new Uint8Array(positions.length);
		for (const [index, pos] of positions.entries()) {
			this.#updatePosition(index, pos);
			hiddenState[index] = 0;
		}
		this.#hiddenAttribute = new InstancedBufferAttribute(hiddenState, 1);
		this.geometry.setAttribute("hidden", this.#hiddenAttribute);
	}

	/** @returns the current base size used to render the placeholders */
	get size(): number {
		return this.geometry.parameters.width;
	}

	/** Change the base size used to render the placeholders */
	set size(value: number) {
		assert(value > 0, "size should be a positive integer");
		this.geometry.dispose();
		this.geometry = new PlaneGeometry(value, value);
		this.geometry.setAttribute("hidden", this.#hiddenAttribute);
	}

	/** @returns the min alpha value rendered, fragments with an alpha below alphaTest will be discarded */
	get alphaTest(): number {
		return this.material.uniforms.alphaTest.value;
	}

	/** Change the alphaTest threshold */
	set alphaTest(value: number) {
		this.material.uniforms.alphaTest.value = value;
	}

	/** @returns The distance at which the placeholders start fading*/
	get fadeOffDistance(): number {
		return this.material.uniforms.fadeOffDistance.value;
	}

	/** Set the distance at which the placeholders start fading. Use undefined to disable fading */
	set fadeOffDistance(distance: number | undefined) {
		this.material.uniforms.fadeOffDistance.value = distance ?? Number.MAX_VALUE;
		this.material.uniformsNeedUpdate = true;
	}

	/** @returns the factor used to multiply the size when the placeholder is hovered */
	get hoveredSizeFactor(): number {
		return this.material.uniforms.hoveredSizeFactor.value;
	}

	/** Change the factor used to multiply the size when the placeholder is hovered */
	set hoveredSizeFactor(value: number) {
		assert(value > 0, "hoveredSizeFactor should be a positive integer");
		this.material.uniforms.hoveredSizeFactor.value = value;
	}

	/** @returns the factor used to multiply the size when the placeholder is selected */
	get selectedSizeFactor(): number {
		return this.material.uniforms.selectedSizeFactor.value;
	}

	/** Change the factor used to multiply the size when the placeholder is selected */
	set selectedSizeFactor(value: number) {
		assert(value > 0, "selectedSizeFactor should be a positive integer");
		this.material.uniforms.selectedSizeFactor.value = value;
	}

	/** @returns the texture used to render the placeholder default state */
	get defaultMap(): Texture | undefined {
		return this.material.uniforms.map.value ?? undefined;
	}

	/** Change the texture used to render the placeholder default state */
	set defaultMap(s: Texture | undefined) {
		this.material.uniforms.map.value = s ?? null;
		this.material.uniformsNeedUpdate = true;
	}

	/** @returns the texture used to render the placeholder hovered state */
	get hoveredMap(): Texture | undefined {
		return this.material.uniforms.hoveredMap.value ?? undefined;
	}

	/** Change the texture used to render the placeholder hovered state */
	set hoveredMap(s: Texture | undefined) {
		this.material.uniforms.hoveredMap.value = s ?? null;
		this.material.uniformsNeedUpdate = true;
	}

	/** @returns the texture used to render the placeholder selected state */
	get selectedMap(): Texture | undefined {
		return this.material.uniforms.selectedMap.value ?? undefined;
	}

	/** Change the texture used to render the placeholder selected state */
	set selectedMap(s: Texture | undefined) {
		this.material.uniforms.selectedMap.value = s ?? null;
		this.material.uniformsNeedUpdate = true;
	}

	/** @returns the index of the hovered placeholder */
	get hovered(): number | undefined {
		const val = this.material.uniforms.hovered.value;
		if (val < 0) return;
		return val;
	}

	/** Set the index of the hovered placeholder */
	set hovered(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.material.uniforms.hovered.value = index ?? -1;
	}

	/** @returns the index of the selected placeholder */
	get selected(): number | undefined {
		const val = this.material.uniforms.selected.value;
		if (val < 0) return;
		return val;
	}

	/** Set the index of the selected placeholder */
	set selected(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.material.uniforms.selected.value = index ?? -1;
	}

	/** @returns the list of hidden placeholders */
	get hidden(): number[] {
		return this.#hidden;
	}

	/** Set the indices of the hidden placeholders */
	set hidden(list: number[]) {
		// Shallow comparison to not update the attribute array
		// if the hidden array is the same as before
		if (this.#hidden === list) return;
		for (let i = 0; i < this.geometry.attributes.hidden.count; ++i) {
			let state = this.geometry.attributes.hidden.array[i];
			if (list.includes(i)) {
				state = 1;
			} else {
				state = 0;
			}
			this.geometry.attributes.hidden.setX(i, state);
		}
		this.#hidden = list;
		this.geometry.attributes.hidden.needsUpdate = true;
	}

	/**
	 * Update the position of a placeholder
	 *
	 * @param index The index of the placeholder
	 * @param position The new position of the placeholder
	 */
	#updatePosition(index: number, position: Vector3): void {
		const m = new Matrix4().makeTranslation(position.x, position.y, position.z);
		m.multiply(ROTATION_FIX_MATRIX);
		this.setMatrixAt(index, m);
	}

	/**
	 * Update the position of the placeholders, assuming the input array has
	 * the same number of elements as before
	 *
	 * @param positions The new positions
	 */
	updatePositions(positions: Vector3[]): void {
		// Update the instance matrices
		for (const [index, pos] of positions.entries()) {
			this.#updatePosition(index, pos);
		}
		this.instanceMatrix.needsUpdate = true;
	}

	/**
	 * Set the rotation of all the placeholders to the given quaternion.
	 *
	 * @param quaternion The quaternion representing the rotation
	 */
	copyRotation(quaternion: Quaternion): void {
		for (let i = 0; i < this.count; i++) {
			this.getMatrixAt(i, ALLOCATED_MATRIX);

			// Preserve position and scale, while updating the rotation
			ALLOCATED_MATRIX.decompose(ALLOCATED_VECTOR1, ALLOCATED_QUATERNION, ALLOCATED_VECTOR2);
			ALLOCATED_MATRIX.compose(ALLOCATED_VECTOR1, quaternion, ALLOCATED_VECTOR2);

			this.setMatrixAt(i, ALLOCATED_MATRIX);
		}
		this.instanceMatrix.needsUpdate = true;
	}

	/**
	 * Raycast on the placeholders, ignoring the hidden ones
	 *
	 * @param raycaster The raycaster used to compute the intersection
	 * @param intersects The list of elements intersected
	 */
	override raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		super.raycast(raycaster, this.#localIntersects);

		// Filter out the hidden placeholders
		for (const intersect of this.#localIntersects) {
			if (intersect.instanceId !== undefined && this.geometry.attributes.hidden.array[intersect.instanceId] > 0) {
				continue;
			}
			intersects.push(intersect);
		}

		this.#localIntersects.length = 0;
	}
}
