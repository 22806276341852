import { DropdownDark } from "./dropdown-dark";
import { DropdownLight } from "./dropdown-light";
import { DropdownProps } from "./dropdown-types";

/**
 * @returns The reusable dropdown component styled according to the design guidelines
 */
export function Dropdown({ dark, ...rest }: DropdownProps): JSX.Element {
  return dark ? <DropdownDark {...rest} /> : <DropdownLight {...rest} />;
}
