import { useMemo } from "react";
import { FeedBackForm } from "./feedback-menu";

/**
 * @param feedbackFormLink (main) app feed back form link
 * @param additionalFeedbackForms additional labeled feedback form links
 * @returns Combined memoized array of FeedbackForms which concatenates additionalFeedbackForms and feedbackFormLink into a single array.
 * The Array might be empty because both parts can be optional.
 */
export function useFeedbackForms(
  feedbackFormLink?: string,
  additionalFeedbackForms?: FeedBackForm[],
): FeedBackForm[] {
  return useMemo(
    () => [
      ...(additionalFeedbackForms ?? []),
      ...(feedbackFormLink
        ? [{ label: "Sphere Viewer", link: feedbackFormLink }]
        : new Array<FeedBackForm>()),
    ],
    [additionalFeedbackForms, feedbackFormLink],
  );
}
