import {
  ExitFullScreenIcon,
  FullScreenIcon,
  IconButton,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/ielement-types";
import { AnimationManager } from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import { OrthographicCamera } from "@react-three/drei";
import { Vector2 as Vector2Prop, useFrame, useThree } from "@react-three/fiber";
import {
  CSSProperties,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Color,
  ColorRepresentation,
  Texture,
  OrthographicCamera as ThreeOrthoCamera,
  Vector2,
} from "three";
import { Canvas } from "../../canvas";
import { usePropSettableState } from "../../hooks/use-prop-settable-state";
import { parseVector2 } from "../../utils/props";
import { StateAnimation } from "../../utils/state-animation";
import { Map2dControls } from "../controls/map2d-controls";

/** Default camera position if not specified */
const DEF_CAMERA_POSITION = new Vector2(0, 0);

// Height to place the camera at to make ray-casts work correctly if objects are layered on top of the map.
const MAP_CAMERA_HEIGHT = 10;

/**
 * Anything that can be used as a background
 */
type BackgroundProp = ColorRepresentation | Texture | undefined;

/**
 * Property for a simple utility component used to change the background of the minimap
 */
type Map2dBackgroundProps = {
  /** The background to use, transparent if undefined */
  background?: BackgroundProp;
};

/**
 * @returns A small utility component to update the background of the MiniMap canvas
 */
function Map2dBackground({ background }: Map2dBackgroundProps): null {
  const scene = useThree((s) => s.scene);
  // Apply the background, restore old background when unmounted
  useEffect(() => {
    if (background === undefined) {
      return;
    }
    const old = scene.background;
    if (background instanceof Texture) {
      scene.background = background;
    } else {
      scene.background = new Color(background);
    }
    return () => {
      scene.background = old;
    };
  }, [scene, background]);

  return null;
}

/**
 * Property for MiniMapCamera
 */
type Map2dCameraProps = {
  /** The width of the entire map */
  mapWidth?: number;

  /** The height of the entire map */
  mapHeight?: number;

  /** The position of the camera */
  position?: Vector2;

  /** The rotation of the camera */
  rotation?: number;

  /** The zoom level */
  zoom?: number;
};

/** @returns A wrapper on top of OrthographicCamera to use in a minimap */
function Map2dCamera({
  mapWidth = 2,
  mapHeight = 2,
  position = DEF_CAMERA_POSITION,
  rotation = 0,
  zoom = 0,
}: Map2dCameraProps): JSX.Element {
  // Let's compute the camera width/height so it matches our map respecting the canvas aspect ratio
  const { width: vpWidth, height: vpHeight } = useThree((s) => s.size);
  const [width, setWidth] = useState(2);
  const [height, setHeight] = useState(2);
  const set = useThree((s) => s.set);

  const ref = useRef<ThreeOrthoCamera>(null);

  useEffect(() => {
    if (mapWidth / mapHeight < vpWidth / vpHeight) {
      setHeight(mapHeight);
      setWidth((mapHeight * vpWidth) / vpHeight);
    } else {
      setWidth(mapWidth);
      setHeight((mapWidth * vpHeight) / vpWidth);
    }
  }, [mapHeight, mapWidth, vpHeight, vpWidth]);

  useEffect(() => {
    ref.current?.updateProjectionMatrix();
  }, [zoom, width, height]);

  useEffect(() => {
    if (ref.current) {
      set({ camera: ref.current });
    }
  }, [set]);

  return (
    <OrthographicCamera
      ref={ref}
      position={[position.x, position.y, MAP_CAMERA_HEIGHT]}
      near={-1000}
      far={1000}
      left={-width / 2}
      bottom={-height / 2}
      right={width / 2}
      top={height / 2}
      makeDefault={true}
      rotation-z={rotation}
      zoom={zoom}
      manual={true}
    />
  );
}

/** Properties of a MiniMap Canvas */
export type IMap2dInternalCanvasProps = PropsWithChildren<{
  /** Includes CSS style definition of the map containing canvas to control transition behavior on resizing */
  style?: CSSProperties;

  /** Background of the map */
  background?: BackgroundProp;

  /** Zoom level */
  zoom?: number;

  /** Map rotation in radians */
  rotation?: number;

  /** Position of the camera on the map */
  cameraPosition?: Vector2Prop;

  /** Width of the entire map */
  mapWidth?: number;

  /** Height of the entire map */
  mapHeight?: number;

  /** Callback called any time the map rotation changes due to user interaction */
  onRotationChanged?(newRot: number): void | undefined;

  /** Callback called any time the zoom changes due to user interaction */
  onZoomChanged?(zoom: number): void | undefined;

  /** Callback called any time the camera position changes due to user interaction */
  onCameraPositionChanged?(position: Vector2): void | undefined;
}>;

/**
 * Specific MiniMap context exposed to all components inside the mini map canvas
 */
type Map2dContext = {
  /** GUID of the currently hovered placeholder in the minimap */
  hovered: GUID | undefined;

  /** Change the current hovered placeholder in the minimap */
  startHover(x: GUID): void;
  endHover(x: GUID): void;

  /** True if we're dragging the map */
  isDragging: boolean;

  /** Update the dragging flag */
  setDragging(d: boolean): void;

  /** Mini map rotation in radians */
  rotation: number;

  /** Change the map rotation */
  setRotation(rot: number): void;

  /** Mini map zoom */
  zoom: number;

  /** Change mini map zoom */
  animateZoom(zoom: number): void;
  setZoom(zoom: number): void;

  /** Camera Position */
  cameraPosition: Vector2;

  /** Move camera */
  animateCameraPosition(newPos: Vector2): void;
  setCameraPosition(newPos: Vector2): void;
};

/** The mini map context instance */
const Map2dContext = createContext<Map2dContext | undefined>(undefined);

/**
 * Custom hook to access MiniMap context
 *
 * @returns The current bound minimap context
 */
export function useMap2d(): Map2dContext {
  const context = useContext(Map2dContext);
  if (!context) {
    throw new Error("useMap2d can be used only inside a MiniMap");
  }
  return context;
}

function identity<T>(x: T): T {
  return x;
}

/** Properties for the internal state of the map2d canvas */
type InternalProps = IMap2dInternalCanvasProps & {
  setCursor(cursor: string): void;
};

/**
 * A component with all the internal logic of the map2d canvas
 * we need this because we can access 3rf state only inside the canvas
 *
 * @returns All internal state logic for the map2d canvas
 */
function Map2dInternal({
  rotation: origRotation,
  onRotationChanged,
  zoom: origZoom,
  onZoomChanged,
  cameraPosition: origCameraPosition,
  onCameraPositionChanged,
  setCursor,
  mapWidth,
  mapHeight,
  background,
  children,
}: PropsWithChildren<InternalProps>): JSX.Element {
  const [hovered, setHovered] = useState<GUID>();
  const [isDragging, setDragging] = useState(false);
  const am = useMemo(() => new AnimationManager(), []);
  const [zoomAnimation, setZoomAnimation] = useState<StateAnimation<number>>();
  const [poseAnimation, setPoseAnimation] = useState<StateAnimation<Vector2>>();

  // Update the animation manager for the camera movements at every frame
  useFrame((_, delta) => {
    am.update(delta);
    // The animation just moved the camera, executing this with UPDATE_CONTROLS_PRIORITY
    // so that it is guaranteed that camera monitoring and lod visibility tasks come after this.
  }, UPDATE_CONTROLS_PRIORITY);

  // Create a callback that can be used by child components to notify hover start on placeholders
  const startHover = useCallback((target: GUID) => {
    setHovered(target);
  }, []);

  // Create a callback that can be used by child components to notify hover ends on placeholders
  const endHover = useCallback(
    (target: GUID) => {
      if (hovered === target) {
        setHovered(undefined);
      }
    },
    [hovered],
  );

  // Create a props synchronized with callback rotation state
  const [rotation, setRotation] = usePropSettableState(
    origRotation,
    0,
    onRotationChanged,
    identity,
  );

  // Create a props synchronized with callback zoom state
  const [zoom, setZoom] = usePropSettableState(
    origZoom,
    1,
    onZoomChanged,
    identity,
  );

  // Create a props synchronized with callback cameraPosition state
  const [cameraPosition, setCameraPosition] = usePropSettableState(
    origCameraPosition,
    DEF_CAMERA_POSITION,
    onCameraPositionChanged,
    parseVector2,
  );

  // Create a callback that can be used to animate the zoom
  const animateZoom = useCallback(
    (target: number) => {
      if (zoomAnimation) {
        zoomAnimation.cancel();
      }
      const anim = new StateAnimation<number>(zoom, target, setZoom);
      am.run(anim);
      setZoomAnimation(anim);
    },
    [am, setZoom, zoom, zoomAnimation],
  );

  // Create a callback that can be used to animate the camera position
  const animateCameraPosition = useCallback(
    (target: Vector2) => {
      if (poseAnimation) {
        poseAnimation.cancel();
      }
      const anim = new StateAnimation<Vector2>(
        cameraPosition,
        target,
        setCameraPosition,
      );
      am.run(anim);
      setPoseAnimation(anim);
    },
    [am, cameraPosition, poseAnimation, setCameraPosition],
  );

  // Update map2d canvas cursor depending on the hovered state
  useEffect(() => {
    if (hovered) {
      setCursor("pointer");
      return;
    }
    if (isDragging) {
      setCursor("grabbing");
      return;
    }
    setCursor("grab");
  }, [hovered, isDragging, setCursor]);

  return (
    <Map2dContext.Provider
      value={{
        hovered,
        startHover,
        endHover,
        isDragging,
        setDragging,
        rotation,
        setRotation,
        zoom,
        animateZoom,
        setZoom,
        cameraPosition,
        animateCameraPosition,
        setCameraPosition,
      }}
    >
      <Map2dCamera
        position={cameraPosition}
        mapWidth={mapWidth}
        mapHeight={mapHeight}
        rotation={rotation}
        zoom={zoom}
      />
      <Map2dControls
        setDragging={setDragging}
        setRotation={setRotation}
        setZoom={setZoom}
        setCameraPosition={setCameraPosition}
      />
      <Map2dBackground background={background} />

      {children}
    </Map2dContext.Provider>
  );
}

interface IMap2dCanvasProps extends IMap2dInternalCanvasProps {
  /** Control the available of fullscreen toggling icon button */
  shouldShowFullscreenToggle?: boolean;

  /** Initial state of the fullscreen button */
  isFullScreen?: boolean;

  /** Callback called any time fullscreen toggling button was pushed by user */
  onFullScreenToggled?(fullScreen: boolean): void | undefined;
}

/**
 * @returns A canvas to render a 2d mini map
 */
export function Map2dCanvas({
  shouldShowFullscreenToggle,
  isFullScreen = true,
  onFullScreenToggled,
  ...map2dInternalProps
}: IMap2dCanvasProps): JSX.Element {
  const [cursor, setCursor] = useState("grab");

  const toggleFullScreen = useCallback(() => {
    if (onFullScreenToggled) {
      onFullScreenToggled(!isFullScreen);
    }
  }, [isFullScreen, onFullScreenToggled]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Canvas style={{ cursor }} resize={{ debounce: 0 }}>
        <Map2dInternal {...map2dInternalProps} setCursor={setCursor} />
      </Canvas>

      {shouldShowFullscreenToggle && (
        <IconButton
          title={isFullScreen ? "Normal Size" : "FullScreen"}
          onClick={toggleFullScreen}
          variant="MenuBarIcon"
          sx={{ position: "absolute", top: "0", left: "0" }}
        >
          {isFullScreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
        </IconButton>
      )}
    </div>
  );
}
