import { Box3, BufferGeometry, Float32BufferAttribute, Matrix, Matrix4, Vector3 } from "three";

/**
 * Given a point P and an AA box B, returns the point on B that is closest to P,
 * plus a boolean that is true if P is inside B, or lying on B, false if P is outside.
 *
 * @param point The argument point
 * @param box The argument aa box
 * @param closestPoint The resulting closest point, passed in as argument to avoid allocations.
 * @returns The point on the box surface that is closest to 'point', and a boolean that is true iff 'point' was inside 'box'
 */
export function closestPointOnBox(
	point: Vector3,
	box: Box3,
	closestPoint: Vector3,
): { closestPoint: Vector3; pointInside: boolean } {
	closestPoint.copy(point);
	closestPoint.clamp(box.min, box.max);
	const pointInside = closestPoint.equals(point);
	return { closestPoint, pointInside };
}

/**
 * Returns the eight corners of the given AA 3D box.
 *
 * @param b The argument aa box
 * @returns The eight corners of the argument box.
 */
export function getBoxCorners(b: Box3): Vector3[] {
	return [
		new Vector3(b.min.x, b.min.y, b.min.z),
		new Vector3(b.max.x, b.min.y, b.min.z),
		new Vector3(b.max.x, b.max.y, b.min.z),
		new Vector3(b.min.x, b.max.y, b.min.z),
		new Vector3(b.min.x, b.min.y, b.max.z),
		new Vector3(b.max.x, b.min.y, b.max.z),
		new Vector3(b.max.x, b.max.y, b.max.z),
		new Vector3(b.min.x, b.max.y, b.max.z),
	];
}

/**
 * Given an AA box 'b', this function computes and returns the eight boxes that are
 * obtained cutting 'b' in its center three times, once for each axis. Useful for octree generation:
 * given a parent node, this function returns the eight octants.
 *
 * @param b The parent box
 * @returns The eight children boxes.
 */
export function getChildrenBoxes(b: Box3): Box3[] {
	const ret = new Array<Box3>();
	const c = b.getCenter(new Vector3());
	const corners = getBoxCorners(b);
	for (const corner of corners) {
		const box = new Box3();
		box.setFromPoints([c, corner]);
		ret.push(box);
	}
	return ret;
}

const EPSILON_SQ = 1e-6;

/**
 * This function quickly checks whether the input matrix m is a scale matrix or not.
 * No divisions or square roots performed.
 *
 * @param m The input matrix
 * @returns Whether m is a scale matrix.
 */
export function isScaleMatrix(m: Matrix4): boolean {
	const te = m.elements;

	const scaleXSq = te[0] * te[0] + te[1] * te[1] + te[2] * te[2];
	const scaleYSq = te[4] * te[4] + te[5] * te[5] + te[6] * te[6];
	const scaleZSq = te[8] * te[8] + te[9] * te[9] + te[10] * te[10];

	return (
		Math.abs(scaleXSq - 1.0) > EPSILON_SQ ||
		Math.abs(scaleYSq - 1.0) > EPSILON_SQ ||
		Math.abs(scaleZSq - 1.0) > EPSILON_SQ
	);
}

/**
 * Returns the inverse of the argument matrix. It is assumed that the
 * argument matrix represents a rigid transformation, therefore a fast algorithm
 * can be used.
 * Precondition: matrix 'm' is a rigid transform.
 * The fast algorithm does not work if the argument matrix m implements scaling
 * != 1 in any dimension. If m is a scaling matrix, a slow inversion algo is used instead.
 * Performance: no divisions or square roots or allocations used to perform this algorithm.
 *
 * @param m The argument matrix. m it is not modified within the function.
 * @param outMatrix The output matrix, provided here to avoid allocations.
 * @returns The inverse matrix, inverted with a fast algorithm for rigid transforms.
 */
export function invertedRigid(m: Matrix4, outMatrix: Matrix4): Matrix4 {
	if (isScaleMatrix(m)) {
		outMatrix.copy(m).invert();
		return outMatrix;
	}
	const e = m.elements;
	const oe = outMatrix.elements;
	// for the rotation matrix, the inverse is just the transpose
	oe[0] = e[0];
	oe[1] = e[4];
	oe[2] = e[8];
	oe[4] = e[1];
	oe[5] = e[5];
	oe[6] = e[9];
	oe[8] = e[2];
	oe[9] = e[6];
	oe[10] = e[10];
	// computing translation component of inverse matrix
	const tx = -e[12];
	const ty = -e[13];
	const tz = -e[14];
	oe[12] = tx * oe[0] + ty * oe[4] + tz * oe[8];
	oe[13] = tx * oe[1] + ty * oe[5] + tz * oe[9];
	oe[14] = tx * oe[2] + ty * oe[6] + tz * oe[10];
	// last row of output matrix should be 0, 0, 0, 1
	oe[3] = oe[7] = oe[11] = 0;
	oe[15] = 1;
	return outMatrix;
}

/**
 * Returns whether all coefficients of matrices m and n are equal within threshold t.
 * Works for both Matrix3 and Matrix4 types.
 *
 * @param m The first equality term
 * @param n The second equality term
 * @param t The equality threshold
 * @returns True iff m is equal to n with approximation t.
 */
export function areMatricesEqual(m: Matrix, n: Matrix, t: number): boolean {
	if (m.elements.length !== n.elements.length) {
		return false;
	}
	for (let i = 0; i < m.elements.length; ++i) {
		if (Math.abs(m.elements[i] - n.elements[i]) > t) {
			return false;
		}
	}
	return true;
}

/**
 * Extract the indices of the triangles of the sphere geometry with the same number
 * of sectors for the polar and the azimuth angle.
 *
 * @param heightSegments The number of steps in which the polar angle is subdivided
 * @param widthSegments The number of steps in which the azimuth angle is subdivided
 * @param grid The grid containing the list of indices for each row of the sphere
 * @returns The linearized indices that describe the final mesh
 */
function sphereIndices(heightSegments: number, widthSegments: number, grid: number[][]): number[] {
	const indices = [];
	for (let iy = 0; iy < heightSegments; iy++) {
		if (grid[iy].length === 1) {
			// Here the polar angle is -Math.PI * 0.5
			const a = grid[iy][0];
			for (let ix = 0; ix < widthSegments; ix++) {
				const b = grid[iy + 1][ix];
				const c = grid[iy + 1][ix + 1];
				indices.push(a, b, c);
			}
		} else if (grid[iy + 1].length === 1) {
			// Here the polar angle is Math.PI * 0.5
			const a = grid[iy + 1][0];
			for (let ix = 0; ix < widthSegments; ix++) {
				const b = grid[iy][ix];
				const c = grid[iy][ix + 1];
				indices.push(a, b, c);
			}
		} else {
			for (let ix = 0; ix < widthSegments; ix++) {
				const a = grid[iy][ix + 1];
				const b = grid[iy][ix];
				const c = grid[iy + 1][ix];
				const d = grid[iy + 1][ix + 1];
				indices.push(a, b, d, b, c, d);
			}
		}
	}
	return indices;
}

/**
 * Extract a geometry buffer representing a sphere, given the azimuth angle range (i.e. left/right)
 * and the polar angle range (i.e up/down).
 * This function is very similar to the one provided by THREE, but adapted to our reference system (right handed - z up).
 *
 * @param radius The radius of the sphere
 * @param widthSegments The number of sectors in which the azimuth angle is subdivided
 * @param heightSegments The number of sectors in which the polar angle is subdivided
 * @param phiStart The starting value for the azimuth angle
 * @param phiRange The variation range of the azimuth angle, such that the final angle will be phi + phiRange
 * @param thetaStart The starting value for the polar angle
 * @param thetaRange The variation range of the polar angle, such that the final angle will be theta + thetaRange
 * @returns A THREE buffer geometry containing the vertices positions and the triangles indices of the mesh.
 */
export function sphereGeometry(
	radius: number,
	widthSegments: number,
	heightSegments: number,
	phiStart: number,
	phiRange: number,
	thetaStart: number,
	thetaRange: number,
): BufferGeometry {
	const buffer = new BufferGeometry();
	buffer.morphAttributes = {};
	let index = 0;
	const grid = [];
	const vertex = new Vector3();

	const vertices = [];
	for (let iy = 0; iy <= heightSegments; iy++) {
		const verticesRow = [];
		const v = iy / heightSegments;
		const polar = thetaStart + v * thetaRange;

		if (polar === -Math.PI * 0.5 || polar === Math.PI * 0.5) {
			vertices.push(0, 0, (polar / Math.PI) * 2);
			verticesRow.push(index++);
			grid.push(verticesRow);
			continue;
		}

		for (let ix = 0; ix <= widthSegments; ix++) {
			const u = ix / widthSegments;
			const azimuth = phiStart + u * phiRange;

			vertex.x = radius * Math.cos(azimuth) * Math.cos(polar);
			vertex.y = radius * Math.sin(azimuth) * Math.cos(polar);
			vertex.z = radius * Math.sin(polar);
			vertices.push(vertex.x, vertex.y, vertex.z);
			verticesRow.push(index++);

			// Texture coordinates (if needed) = (1-u, v)
		}

		grid.push(verticesRow);
	}

	const indices = sphereIndices(heightSegments, widthSegments, grid);
	buffer.setIndex(indices);
	buffer.setAttribute("position", new Float32BufferAttribute(vertices, 3));
	return buffer;
}
