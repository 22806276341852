import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { Stack } from "@mui/material";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaroButton } from "../../button/faro-button";
import { neutral } from "../../colors";
import { TextField } from "../../input/text-field";
import { FaroRichTextEditor } from "../../rich-text";
import {
  AnnotationEditorMetaFields,
  AnnotationEditorMetaFieldsProps,
} from "./annotation-editor-meta-fields";

/** The maximum allowed number of characters in ProjectAPI for description of the annotation */
const MAX_DESCRIPTION_LENGTH = 10000;

export type AnnotationEditorProps = AnnotationEditorHeaderProps &
  AnnotationEditorMetaFieldsProps &
  AnnotationEditorButtonsProps & {
    /**
     * Initial value of the description text field.
     * Updating this prop from parent will have no effect!
     */
    initialDescription?: string;

    /** Function called when the content of the description text field changes */
    onDescriptionChange?(description?: string): void;

    /**
     * Method to call if there's an error with the description
     */
    onDescriptionError(error: Error): void;
  };

/**
 * @returns a dialog used to create and edit annotations and their fields value
 */
export function AnnotationEditor({
  children,
  title,
  initialDescription = "",
  assignee = "",
  initialDate,
  status = "",
  syncedWith,
  assigneeOptions,
  statusOptions,
  syncedWithOptions,
  tagsOptions,
  confirmButtonText,
  attachments,
  tags,
  addNewAttachment,
  onTitleChange,
  onDescriptionChange,
  onAssigneeChange,
  onDueDateChange,
  onStatusChange,
  onSyncedWithChange,
  onTagsChange,
  onAddNewTagClick,
  onCancelButtonClick,
  onConfirmButtonClick,
  onAttachmentOpened,
  enableSync = false,
  autoFocusTitle = false,
  shouldShowAttachments,
  isSaving,
  progress,
  onDescriptionError,
}: PropsWithChildren<AnnotationEditorProps>): JSX.Element {
  const [descriptionLength, setDescriptionLength] = useState(
    initialDescription.length,
  );

  const handleDescriptionChange = useCallback(
    (newDescription: string) => {
      setDescriptionLength(newDescription.length);
      onDescriptionChange?.(newDescription);
    },
    [onDescriptionChange],
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: isSaving ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={autoFocusTitle}
        onTitleChange={onTitleChange}
        isSaving={isSaving}
      />
      <Stack
        sx={{
          // Add a scrollbar only to the main content if needed
          overflow: "auto",
          flexGrow: 1,
          gap: 3,
          mx: -0.875,
          px: 0.875,
        }}
      >
        <FaroRichTextEditor
          initialText={initialDescription}
          dark
          fullWidth
          readOnly={isSaving}
          label="Description"
          placeholder="Insert a description"
          onError={onDescriptionError}
          onChange={handleDescriptionChange}
          sx={{
            height: "120px",
          }}
        />

        {children}
        <AnnotationEditorMetaFields
          isSaving={isSaving}
          assignee={assignee}
          initialDate={initialDate}
          status={status}
          syncedWith={syncedWith}
          assigneeOptions={assigneeOptions}
          statusOptions={statusOptions}
          syncedWithOptions={syncedWithOptions}
          tagsOptions={tagsOptions}
          attachments={attachments}
          tags={tags}
          addNewAttachment={addNewAttachment}
          onAddNewTagClick={onAddNewTagClick}
          onAssigneeChange={onAssigneeChange}
          onDueDateChange={onDueDateChange}
          onStatusChange={onStatusChange}
          onSyncedWithChange={onSyncedWithChange}
          onTagsChange={onTagsChange}
          enableSync={enableSync}
          shouldShowAttachments={shouldShowAttachments}
          progress={progress}
          onAttachmentOpened={onAttachmentOpened}
        />
      </Stack>
      <AnnotationEditorButtons
        isConfirmButtonDisabled={
          !title ||
          title.length === 0 ||
          title.length > MAX_NAME_LENGTH ||
          descriptionLength > MAX_DESCRIPTION_LENGTH
        }
        isSaving={isSaving}
        confirmButtonText={confirmButtonText}
        onCancelButtonClick={onCancelButtonClick}
        onConfirmButtonClick={onConfirmButtonClick}
      />
    </Stack>
  );
}

type AnnotationEditorHeaderProps = {
  /** Current value of the title text field */
  title?: string;

  /**
   * If true the title input field will be focused and its text will be selected, when the form is first shown.
   *
   * @default false
   */
  autoFocusTitle?: boolean;

  /** Function called when the content of the title text field changes */
  onTitleChange?(title: string): void;

  /** True if this control should be enabled */
  isSaving: boolean;
};

function AnnotationEditorHeader({
  // The default value is set to avoid warning logs that appear when going from undefined to a string
  // The assigned value it's a specific value used by the Dropdown Api
  title = "",
  autoFocusTitle,
  onTitleChange,
  isSaving,
}: AnnotationEditorHeaderProps): JSX.Element {
  const [isFirstFocus, setIsFirstFocus] = useState(true);

  const clampText = useCallback(
    (text: string) => onTitleChange?.(text.slice(0, MAX_NAME_LENGTH)),
    [onTitleChange],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the title input field when the form is first shown
  useEffect(() => {
    if (!inputRef.current || !autoFocusTitle) return;

    inputRef.current.focus();
  }, [autoFocusTitle]);

  return (
    <TextField
      inputRef={inputRef}
      disabled={isSaving}
      text={title}
      fullWidth={true}
      variant="underlined"
      dark={true}
      placeholder="Insert a title"
      sx={{
        height: "fit-content",
        minHeight: "32px",
        input: { fontSize: "1rem" },
      }}
      onTextChanged={clampText}
      maxCharacterCount={MAX_NAME_LENGTH}
      shouldShowCounter
      multiline={true}
      inputProps={{ style: { fontSize: "1rem" } }}
      helpText={
        title.length === MAX_NAME_LENGTH ? "Maximum limit reached" : undefined
      }
      onFocus={(e) => {
        // Select the text on the first focus
        if (isFirstFocus && autoFocusTitle) {
          e.target.select();
          setIsFirstFocus(false);
        }
      }}
    />
  );
}

type AnnotationEditorButtonsProps = {
  /**
   * Text to show in the confirm button
   *
   * @default Edit
   */
  confirmButtonText?: string;

  /** Function called when the cancel button is clicked */
  onCancelButtonClick?(): void;

  /** Function called when the confirm button is clicked */
  onConfirmButtonClick?(): void;

  /** Set to true to show a spinner and disable the buttons */
  isSaving: boolean;

  /** True, if the confirm button should be disabled */
  isConfirmButtonDisabled?: boolean;
};

function AnnotationEditorButtons({
  confirmButtonText = "Save",
  onCancelButtonClick,
  onConfirmButtonClick,
  isSaving,
  isConfirmButtonDisabled,
}: AnnotationEditorButtonsProps): JSX.Element {
  return (
    <Stack direction="row" justifyContent="end">
      <FaroButton
        variant="ghost"
        dark
        onClick={onCancelButtonClick}
        disabled={isSaving}
      >
        Cancel
      </FaroButton>
      <FaroButton
        dark
        onClick={onConfirmButtonClick}
        isLoading={isSaving}
        disabled={isConfirmButtonDisabled}
      >
        {confirmButtonText}
      </FaroButton>
    </Stack>
  );
}
