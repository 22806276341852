import { useCallback } from "react";
import { FaroToast } from "./faro-toast";
import { SnackbarKey, useToastContext } from "./toast-context";
import { OpenToastOptions, ToastReturn } from "./toast-types";

/**
 * Hook providing the means to open and close toast notifications, opening in centered at the top of the screen
 *
 * Important: can only be used within a React tree that is wrapped inside the ToastProvider component (at some point above)
 *
 * @returns Two functions two handle toasts
 */
export function useToast(): ToastReturn {
  const { enqueueSnackbar, closeSnackbar } = useToastContext();

  const openToast = useCallback(
    ({
      message,
      key,
      variant = "success",
      title,
      persist = variant === "error",
      image,
      action,
      preventDuplicate = true,
    }: OpenToastOptions): SnackbarKey => {
      return enqueueSnackbar(message ?? "", {
        preventDuplicate,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        key,
        // TODO: Remove use of the deprecated content property (https://faro01.atlassian.net/browse/SWEB-4818)
        content: (key, message) => (
          <FaroToast
            key={key}
            title={title}
            variant={variant}
            message={message}
            image={image}
            action={action}
            onClose={() => closeSnackbar(key)}
          />
        ),
        persist,
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const closeToast = useCallback(
    (toastKey?: SnackbarKey): void => {
      closeSnackbar(toastKey);
    },
    [closeSnackbar],
  );

  return {
    openToast,
    closeToast,
  };
}
