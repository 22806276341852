import { RadioGroup, RadioGroupProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { FormHelperTextDark, FormHelperTextLight } from "../input/base-inputs";
import { FaroInputLabel, TagTypes } from "../input/input-label";

export type FaroRadioGroupProps = Pick<
  RadioGroupProps,
  "value" | "onChange"
> & {
  /** Optional label on top of the radio group */
  label?: string;

  /** Optional tag to show on the label*/
  tag?: TagTypes;

  /** Optional help text below the radio group (empty strings are considered same as undefined) */
  helpText?: string;

  /** The error message to show (empty strings are considered same as undefined) */
  error?: string;

  /** Whether to enable dark styles */
  dark?: boolean;

  /** Whether to disable the component */
  disabled?: boolean;

  /** Additional styles to apply to the radio group. */
  radioGroupSx?: RadioGroupProps["sx"];
};

/**
 * @returns a radio group that can coordinates FaroRadio components in its children
 */
export function FaroRadioGroup({
  dark,
  label,
  tag,
  helpText,
  error,
  disabled,
  children,
  radioGroupSx,
  ...radioGroupProps
}: PropsWithChildren<FaroRadioGroupProps>): JSX.Element {
  const FormHelperTextVariant = dark ? FormHelperTextDark : FormHelperTextLight;

  const hasError = !!error;
  const errorOrHelpText = hasError ? error : helpText;

  return (
    <>
      {label && (
        <FaroInputLabel
          tag={tag}
          dark={dark}
          disabled={disabled}
          error={hasError}
        >
          {label}
        </FaroInputLabel>
      )}
      <RadioGroup sx={radioGroupSx} {...radioGroupProps}>
        {children}
      </RadioGroup>
      {errorOrHelpText && (
        <FormHelperTextVariant
          sx={{ m: 0 }}
          error={!!error}
          disabled={disabled}
        >
          {errorOrHelpText}
        </FormHelperTextVariant>
      )}
    </>
  );
}
