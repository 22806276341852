import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useExternalScript } from "../hooks/use-external-script";

/** CSS class to be used in order to disable translations for parts of the UI */
export const NO_TRANSLATE_CLASS = "notranslate";

type TranslateFunction = (
  /** Text or element to translate */
  input: JSX.Element | string,

  /** An object with key:value pairs of variables that will be replaced in the input parameter */
  variables?: Record<string, string | number>,
) => JSX.Element | string;

type LocalizeInitOptions = {
  /**
   * The Project Key
   * See https://developers.localizejs.com/docs/library-api#key
   */
  key: string;

  /**
   * If true, Localize will remember the user’s previous language choice and will translate your website to that language.
   * See https://developers.localizejs.com/docs/library-api#rememberlanguage
   */
  rememberLanguage?: boolean;

  /**
   * If set to true, Localize will automatically translate content that is added dynamically to your webpage.
   * See https://developers.localizejs.com/docs/library-api#retranslateonnewphrases
   */
  retranslateOnNewPhrases?: boolean;

  /**
   * If true, the <title> tag of the page in the <head> section of the web page will be translatable.
   * See https://developers.localizejs.com/docs/library-api#translatetitle
   */
  translateTitle?: boolean;

  /**
   * When true, the default Localize language-switching widget is hidden in your web pages (via CSS).
   * See https://developers.localizejs.com/docs/library-api#disablewidget
   */
  disableWidget?: boolean;

  /**
   * List of CSS classes for which the translation will be disabled
   * See https://developers.localizejs.com/docs/library-api#blockedclasses
   */
  blockedClasses?: string[];

  // Support all other options we have not yet documented here
  [key: string]: unknown;
};

type LocalizeType = {
  /** Localize option API https://developers.localizejs.com/docs/library-api */
  initialize(options: LocalizeInitOptions): void;

  /** Function that returns all the available languages */
  getAvailableLanguages(
    callback: (
      err: unknown,
      languages: Array<{ code: string; name: string }>,
    ) => void,
  ): void;

  /** Function to change the language the app is translated in */
  setLanguage(language: string): void;

  /** Function to translate a text or element to the current language */
  translate: TranslateFunction;
};

type LocalizeContextData = Pick<LocalizeType, "setLanguage" | "translate"> & {
  /** True if the script is finished loading */
  isScriptLoaded: boolean;

  /** True if localize has been initialized (i.e., all functionality is available) */
  isInitialized: boolean;

  /** List of available languages */
  availableLanguages: Array<{ code: string; name: string }>;
};

// Extending the window global to contain the Localize object
declare global {
  interface Window {
    Localize?: LocalizeType;
  }
}

export const LocalizeContext = createContext<LocalizeContextData | undefined>(
  undefined,
);

export type LocalizeProviderProps = {
  /** Key for the project to initialize */
  projectKey: string;

  /** Localize option API https://developers.localizejs.com/docs/library-api */
  initOptions?: Partial<LocalizeInitOptions>;
};

/**
 * @returns The context that provides access to localize object.
 *  Will load the provided script URL and then call the init function of localize.
 */
export function LocalizeProvider({
  children,
  projectKey,
  initOptions,
}: PropsWithChildren<LocalizeProviderProps>): JSX.Element | null {
  /** List of available languages */
  const [availableLanguages, setAvailableLanguages] = useState<
    Array<{ code: string; name: string }>
  >([]);

  // Load the main Localize script to a specific version
  const { isDoneLoading } = useExternalScript(
    "https://global.localizecdn.com/localize.js",
  );
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize the localize object once the script has been loaded
  useEffect(() => {
    if (isDoneLoading && window.Localize) {
      window.Localize.initialize({
        key: projectKey,
        rememberLanguage: true,
        retranslateOnNewPhrases: true,
        translateTitle: false,
        disableWidget: true,
        blockedClasses: [NO_TRANSLATE_CLASS],
        ...initOptions,
      });

      // Get the available languages
      window.Localize.getAvailableLanguages((err, languages) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.warn("Could not get available languages");
          return;
        }

        setAvailableLanguages(languages);
      });

      setIsInitialized(true);
    }
  }, [isDoneLoading, projectKey, initOptions]);

  const setLanguage = useCallback(
    (language: string) => {
      if (isInitialized && window.Localize) {
        window.Localize.setLanguage(language);
      }
    },
    [isInitialized],
  );

  const translate = useCallback<TranslateFunction>(
    (input, variables) => {
      if (isInitialized && window.Localize) {
        return window.Localize.translate(input, variables);
      }

      return input;
    },
    [isInitialized],
  );

  return (
    <LocalizeContext.Provider
      value={{
        isScriptLoaded: isDoneLoading,
        isInitialized,
        availableLanguages,
        setLanguage,
        translate,
      }}
    >
      {children}
    </LocalizeContext.Provider>
  );
}

/**
 * @returns Hook that returns the utility function to create a dialog
 */
export function useLocalize(): LocalizeContextData {
  const ctx = useContext(LocalizeContext);
  if (!ctx) {
    throw Error("useLocalize called outside the LocalizeContext");
  }
  return ctx;
}
