import {
  PropOptional,
  validateArrayOf,
  validatePrimitive,
} from "@faro-lotv/foundation";

/** Format sent by the backend to describe a generic model3d stream */
export type Model3dStreamDescription = {
  /** Optional URL to the glb file of the model. Was generated only until 2024-08-12. */
  meshGlbUrl?: string | null;

  /** URL that contains the optional metadata of the model */
  metadataUrl: string | null;

  /** URL that contains the optional model tree (this tag was introduced on October 5th = it might be missing in some projects) */
  modelTreeUrl: string | null;

  /** URL that contains the metadata of list sub meshes. Was not generated prior 2024-06-14. */
  subMeshesUrl?: string | null;
};

/**
 * @returns True if the generic record describes the urls of a model3d stream
 * @param data The generic input record
 */
export function isModel3dStreamURLs(
  data: Record<string, unknown>,
): data is Model3dStreamDescription {
  return (
    validatePrimitive(data, "meshGlbUrl", "string", PropOptional) &&
    validatePrimitive(data, "subMeshesUrl", "string", PropOptional) &&
    (typeof data.metadataUrl === "string" || data.metadataUrl === null) &&
    (typeof data.modelTreeUrl === "string" || data.modelTreeUrl === null) &&
    (!!data.metadataUrl || !!data.meshGlbUrl || !!data.subMeshesUrl)
  );
}

/**
 * ISO 8601 date (midnight UTC) when Model3dStreamDescription.subMeshesUrl started to be valid on PROD.
 * The value of subMeshesUrl for an Model3dStreamDescription generated before this date should be ignored.
 */
export const firstVersionSubMeshesInProd = "2024-06-14";

/**
 * Format sent by the backend to describe an object in the model tree.
 * An object is the instance of a part or of a solid in the assembly model.
 * Note: the name and case of the fields is defined form the json contents.
 */
export type CadModelTreeObjectDescription = {
  /**
   * Autodesk/APS object id.
   * Is unique in the model tree = can be used as a key.
   * This id is NOT persistent and may change for the same model on each conversion.
   */
  ObjectId: number;

  /**
   * Optional object name.
   * Can be empty.
   * Is not unique in the model tree = do not use as a key.
   */
  Name: string | null;

  /**
   * Optional persistent id.
   * This id is only available for some formats (Revit and NavisWorks).
   * When available, this is is persistent and constant even when importing a edited version of the CAD model.
   */
  PersistentId: number | null;

  /**
   * Optional list of children objects.
   * Can be empty.
   */
  Children: CadModelTreeObjectDescription[] | null;
};

/**
 * Format sent by the backend to describe cad model message.
 *
 * Note: the name and case of the fields is defined from the json contents.
 */
export type CadMessageObjectDescription = {
  /**
   * message type.
   * See https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3639967903/Format+of+json+data+stored+in+the+Model3DStream+node
   * for UI message type description
   */
  Type: string;

  /**
   * message text.
   */
  Text: string;
};

/** Type used for message array. */
export type CadMessagesDescription = CadMessageObjectDescription[];

/**
 * Cad model Levels as it presented in metadata (sent by backend)
 * See https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3639967903/Format+of+json+data+stored+in+the+Model3DStream+node
 * Note: the name and case of the fields is defined from the json contents.
 */
export type CadLevelObjectDescription = {
  /**
   * name of level in CAD (ex “P1 Level”)
   */
  Name: string;

  /**
   * elevation of the level in meter
   */
  Elevation: number;

  /**
   * height of the level in meter
   */
  Height: number;
};

/** Type used for Levels info array. */
export type CadLevelsDescription = CadLevelObjectDescription[];

/**
 * Format sent by the backend to describe metadata for the cad model.
 *
 * Note: the name and case of the fields is defined from the json contents.
 * Only Messages and Levels are defined here for now. Other objects in the json file (Views/Thumbnails)
 * can be added here when there is need.
 */
export type CadMetadataDescription = {
  /**
   * array of model conversion message.
   */
  Messages: CadMessagesDescription;

  /**
   * array of Levels in data.
   */
  Levels: CadLevelsDescription;
};

/**
 * @returns True if the generic record describes an message object
 * @param data The generic input record
 */
function validateCadMessageObjectDescription(
  data: Record<string, unknown>,
): data is CadMessageObjectDescription {
  return typeof data.Type === "string" && typeof data.Text === "string";
}

/**
 * @returns True if the generic record describes an Level object
 * @param data The generic input record
 */
function validateCadLevelsObjectDescription(
  data: Record<string, unknown>,
): data is CadLevelObjectDescription {
  return (
    typeof data.Name === "string" &&
    typeof data.Elevation === "number" &&
    typeof data.Height === "number"
  );
}

/**
 * @returns True if the generic record describes metadata object
 * @param data The generic input record
 */
export function isCadMetadataDescription(
  data: Record<string, unknown>,
): data is CadMetadataDescription {
  return (
    validateArrayOf({
      object: data,
      prop: "Messages",
      elementGuard: validateCadMessageObjectDescription,
    }) &&
    validateArrayOf({
      object: data,
      prop: "Levels",
      elementGuard: validateCadLevelsObjectDescription,
    })
  );
}

/**
 * @returns True if the generic record describes an object in model tree (CadModelTreeObjectDescription)
 * @param data The generic input record
 */
export function isCadModelTreeObjectDescription(
  data: Record<string, unknown>,
): data is CadModelTreeObjectDescription {
  // test the root properties
  if (
    !(
      typeof data.ObjectId === "number" &&
      (typeof data.Name === "string" || data.Name === null) &&
      (typeof data.PersistentId === "number" || data.PersistentId === null) &&
      (Array.isArray(data.Children) || data.Children === null)
    )
  ) {
    return false;
  }
  // recursively test the items in the children array
  if (data.Children !== null) {
    for (const child of data.Children) {
      if (!isCadModelTreeObjectDescription(child)) {
        return false;
      }
    }
  }
  return true;
}

/** Type used for a complete model tree = an array of CadModelTreeObjectDescription for the root of the model. */
export type CadModelTreeDescription = CadModelTreeObjectDescription[];

/**
 * @returns True if the generic record describes a CAD model tree (CadModelTreeObjectDescription[])
 * @param data The generic input record
 */
export function isCadModelTreeDescription(
  data: Array<Record<string, unknown>>,
): data is CadModelTreeDescription {
  // test the root properties
  if (!Array.isArray(data)) return false;
  // recursively test the items in the children array
  for (const child of data) {
    if (!isCadModelTreeObjectDescription(child)) {
      return false;
    }
  }

  return true;
}
