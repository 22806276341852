import { IElementImg2d } from "@faro-lotv/ielement-types";
import { Billboard, Plane, useTexture } from "@react-three/drei";
import { PropsWithChildren, forwardRef } from "react";
import { Mesh } from "three";
import { IElementRendererProps } from "../../common/i-element-renderer-types";
import { RotationReset } from "../transform-reset/transform-reset";
import { isMarkupModelMetaData } from "../utils/markups-utils";

/**
 * @returns Renderer for the Img2D iElements.
 * Shows them as Billboards always facing the camera.
 */
export const Img2dRenderer = forwardRef<
  Mesh,
  PropsWithChildren<IElementRendererProps<IElementImg2d>>
>(function Img2dRenderer({ children, iElement }, ref): JSX.Element | null {
  const texture = useTexture(iElement.uri);
  const metaData =
    iElement.metaDataMap && isMarkupModelMetaData(iElement.metaDataMap)
      ? iElement.metaDataMap
      : undefined;

  if (!iElement.uri) {
    return null;
  }

  return (
    <RotationReset>
      <Billboard>
        <Plane ref={ref}>
          <meshBasicMaterial
            attach="material"
            transparent={true}
            map={texture}
            color={metaData?.color}
          />
        </Plane>

        {children}
      </Billboard>
    </RotationReset>
  );
});
