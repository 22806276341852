import { Box, InputLabel, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { blue, cyan } from "../colors";
import { FontWeights } from "../faro-theme";
import { InputLabelDark } from "./base-inputs";

/**
 * Tags to show next to the label. Should be used to mark the minority of fields.
 *  - "star" marks a field as required, but must have an accompanying legend visible in the form.
 *  - "required" marks a field as required
 *  - "optional" marks a field as optional
 */
export type TagTypes = "star" | "required" | "optional";

export type InputLabelProps = {
  /** Whether the label should indicate a disabled state */
  disabled?: boolean;

  /** Additional tag to show next to the label, e.g. to mark a field as required */
  tag?: TagTypes;

  /** Enables error styles */
  error: boolean;

  /** Enables dark mode styles */
  dark?: boolean;
};

/**
 * @returns A label to place above input components.
 */
export function FaroInputLabel({
  children,
  disabled,
  tag,
  error,
  dark,
}: PropsWithChildren<InputLabelProps>): JSX.Element | null {
  if (!children) {
    return null;
  }

  const DISABLED_OPACITY = 0.6;

  const InputLabelVariant = dark ? InputLabelDark : InputLabel;

  return (
    <InputLabelVariant
      error={error}
      sx={{
        position: "unset",
        opacity: disabled ? DISABLED_OPACITY : 1,
      }}
      // prevents MUI from hiding the placeholder, when there is a label above the input
      shrink
    >
      {children}
      <TextFieldTag tag={tag} dark={dark} />
    </InputLabelVariant>
  );
}

type TextFieldTagProps = {
  /** The tag type to show */
  tag?: TagTypes;

  /** Enables dark mode styles */
  dark?: boolean;
};

/** @returns a tag for a specific tag type to show next to the label */
function TextFieldTag({ tag, dark }: TextFieldTagProps): JSX.Element | null {
  if (!tag) return null;

  if (tag === "star") {
    const color = dark ? cyan[500] : blue[500];
    return (
      <Box component="span" sx={{ color, pl: 0.5 }}>
        *
      </Box>
    );
  }

  return (
    <Typography
      sx={{ pl: 0.5 }}
      component="span"
      fontSize="0.75rem"
      fontWeight={FontWeights.Regular}
    >
      ({tag})
    </Typography>
  );
}
