import {
  MenuItem,
  MenuItemProps,
  MenuList,
  Stack,
  SvgIconProps,
} from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import { blue, cyan, neutral } from "../colors";
import { ArrowDownIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";
import { FaroMenu } from "./faro-menu";

export type FaroMenuItemProps = Omit<MenuItemProps, "children"> & {
  /** Main text label for this item */
  label: string;

  /** The items in a sub menu for this item */
  children?: ReactNode;

  /** SvgIcon component to use as an icon for the MenuItem, needs to be passed as a component to be styled internally */
  Icon?(props: SvgIconProps): JSX.Element;

  /** An optional secondary text to appear below the main text */
  secondaryText?: string;

  // TODO: Implement shortcut logic https://faro01.atlassian.net/browse/SWEB-3583
  /** @beta An optional shortcut string (No event logic is implemented to trigger the action when the user press the shortcut) */
  shortcut?: string;

  /** optional menu element located at the bottom of menu item. It can be passive or active (link to action) */
  footnoteText?: string;

  /** action on click footnote element of menu. If not provided footnote is passive (read-only) */
  onFootnoteClick?(): void;

  /** Color to use for the default state of the item, default to the Faro theme color */
  color?: string;

  /** Color to use for the secondary text, default to the Faro theme color */
  secondaryColor?: string;

  /** Color to use to highlight the text and icon on hover, default to the Faro theme color */
  highlightColor?: string;

  /** true to use the dark variant of the component @default false */
  dark?: boolean;
};

/** @returns a MenuItem for a FaroMenu that conforms to the FARO Design System guidelines */
export function FaroMenuItem({
  label,
  children,
  secondaryText,
  shortcut,
  footnoteText,
  onFootnoteClick,
  Icon,
  onClick,
  color,
  secondaryColor,
  highlightColor,
  dark = false,
  sx,
  ...rest
}: FaroMenuItemProps): JSX.Element {
  color ??= dark ? neutral[100] : neutral[800];
  secondaryColor ??= dark ? neutral[300] : neutral[600];
  highlightColor ??= dark ? cyan[400] : blue[500];

  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFootnoteHovered, setFootnoteIsHovered] = useState(false);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);

  const isSelected =
    (isActive || isHovered || isSubMenuHovered) && !isFootnoteHovered;

  const menuItem = useRef<HTMLLIElement>(null);

  return (
    <>
      <MenuItem
        {...rest}
        onClick={(ev) => {
          if (!isFootnoteHovered) {
            if (children) setIsActive(!isActive);
            onClick?.(ev);
          }
        }}
        ref={menuItem}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        selected={isSelected}
        sx={{
          px: 1.5,
          py: 1,

          // Hover background
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: `${neutral[500]}1A`,
          },
          ":hover": {
            backgroundColor: `${neutral[500]}1A`,
          },
          // Pressed background
          "&& .MuiTouchRipple-child": {
            backgroundColor: `${neutral[500]}33`,
          },
          ...sx,
        }}
      >
        <Stack direction="column" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            sx={{ width: "100%" }}
          >
            {Icon && (
              <Icon
                sx={{
                  fontSize: "1rem",
                  color: isSelected ? highlightColor : color,
                  alignSelf: "center",
                }}
              />
            )}

            <FaroText
              variant="bodyM"
              sx={{ color: isSelected ? highlightColor : color }}
              flexGrow={1}
            >
              {label}
            </FaroText>

            {shortcut && (
              <FaroText variant="bodyS" color={secondaryColor}>
                {shortcut}
              </FaroText>
            )}
            {children && (
              <ArrowDownIcon
                sx={{
                  color,
                  fontSize: "0.875rem",
                  transform: "rotate(-90deg)",
                  ml: "auto",
                }}
              />
            )}
          </Stack>
          {secondaryText && (
            <FaroText
              variant="bodyS"
              color={secondaryColor}
              // Align secondary text with main text
              // They can't be aligned in the same container as that will make it hard
              // to align the main text with the icon instead
              ml={Icon ? "1rem" : 0}
              pl={Icon ? 1 : 0}
            >
              {secondaryText}
            </FaroText>
          )}

          {footnoteText && (
            <FaroText
              variant="bodyS"
              onClick={() => onFootnoteClick?.()}
              color={onFootnoteClick ? highlightColor : secondaryColor}
              sx={{
                mt: "1.5em",
                ":hover": {
                  textDecoration: "underline",
                },
              }}
              onMouseEnter={() => setFootnoteIsHovered(!!onFootnoteClick)}
              onMouseLeave={() => setFootnoteIsHovered(false)}
            >
              {footnoteText}
            </FaroText>
          )}
        </Stack>
      </MenuItem>
      {children && (
        <FaroMenu
          dark={dark}
          open={isSelected}
          anchorEl={menuItem.current}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          onClose={() => setIsActive(false)}
          onPointerEnter={() => setIsSubMenuHovered(true)}
          onPointerLeave={() => setIsSubMenuHovered(false)}
          autoFocus={false}
          sx={{
            ml: 1,
            pointerEvents: isActive ? "auto" : "none",
            "& .MuiMenu-paper": {
              pointerEvents: isActive ? "auto" : "none",
            },
            "& .MuiMenu-list": {
              pointerEvents: "auto",
            },
          }}
        >
          <MenuList>{children}</MenuList>
        </FaroMenu>
      )}
    </>
  );
}
