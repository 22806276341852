import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { neutral } from "../colors";
import { FontWeights } from "../faro-theme";
import { ArrowDown2Icon } from "../icons/icons";
import { DropdownProps } from "./dropdown-types";
import { SingleSelectItem } from "./single-select-item";

function Placeholder({ placeholder }: { placeholder?: string }): JSX.Element {
  return (
    <Typography
      sx={{
        fontStyle: "italic",
        mr: 1,
      }}
    >
      {placeholder ?? "Select option"}
    </Typography>
  );
}

/**
 * @returns The reusable dropdown component styled according to the design guidelines
 */
export function DropdownLight({
  options,
  placeholder,
  value: selectedValue,
  label,
  disabled: isDisabled,
  error: isError,
  sx,
  fullWidth = true,
  shouldCapitalize = true,
  ...props
}: Omit<DropdownProps, "dark">): JSX.Element {
  const { palette, spacing } = useTheme();

  const CustomIconComponent = useCallback((props: object) => {
    return <ArrowDown2Icon sx={{ marginRight: 1 }} {...props} />;
  }, []);

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel
          shrink
          sx={{
            ...(isDisabled ? { color: palette.gray500 } : {}),
          }}
        >
          {label}
        </InputLabel>
      )}

      <Select
        {...props}
        displayEmpty
        value={selectedValue}
        disabled={isDisabled}
        error={isError}
        IconComponent={CustomIconComponent}
        sx={{
          ...sx,
          color: palette.gray500,
          ["&:hover, &.Mui-focused"]: {
            color: palette.gray850,
          },
          "label + &": {
            marginTop: spacing(3),
          },
          // Disabled style
          ...(isDisabled ? { color: palette.gray600 } : {}),
        }}
        renderValue={(selected) => {
          if (!selected) {
            return <Placeholder placeholder={placeholder} />;
          }

          const option = options.find((o) => o.value === selected);
          return (
            <Typography
              sx={{
                color: palette.gray850,
                textOverflow: "ellipsis",
                overflow: "hidden",
                textTransform: shouldCapitalize ? "capitalize" : "none",
                mr: 1.5,
              }}
            >
              {option?.label ?? selected}
            </Typography>
          );
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: { marginTop: 0.5 },
          PaperProps: {
            sx: {
              boxShadow: `0 3px 6px ${neutral[500]}33`,
              border: `1px solid ${neutral[200]}`,

              "& .MuiList-root": {
                display: "flex",
                flexFlow: "column",
                p: 0.5,
                gap: 0.5,
              },
            },
          },
        }}
      >
        {options.map(
          (
            { key, value, label, secondaryText, isDisabled, isHeader },
            index,
          ) =>
            isHeader ? (
              <ListSubheader key={key}>
                {index !== 0 && <Divider />}

                <Typography
                  sx={{
                    color: palette.gray850,
                    fontSize: "0.75rem",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    mt: 1,
                  }}
                >
                  {label ?? value}
                </Typography>
              </ListSubheader>
            ) : (
              <SingleSelectItem
                key={key}
                value={value}
                label={label}
                secondaryText={secondaryText}
                disabled={isDisabled}
                shouldCapitalize={shouldCapitalize}
                selectedValue={selectedValue}
                dark={false}
              />
            ),
        )}
      </Select>
      {isError && (
        <FormHelperText
          sx={{
            marginY: 0.5,
            marginX: "1px",
            color: palette.red600,
            fontStyle: "italic",
            fontWeight: FontWeights.SemiBold,
          }}
        >
          Enter a valid selection
        </FormHelperText>
      )}
    </FormControl>
  );
}
