import {
  ToggleButton as MUIToggleButton,
  ToggleButtonProps,
} from "@mui/material";
import { forwardRef } from "react";

/** @returns A custom ToggleButton that can be disabled while preserving the ToolTip */
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    { children, value, disabled, onClick, sx, ...props }: ToggleButtonProps,
    ref,
  ) {
    const extraSx: ToggleButtonProps["sx"] = disabled
      ? {
          ":hover": {
            backgroundColor: "transparent",
            cursor: "default",
          },
          opacity: 0.5,
          backgroundColor: "transparent",
        }
      : {};
    return (
      <MUIToggleButton
        ref={ref}
        value={value}
        sx={[extraSx, ...(Array.isArray(sx) ? sx : [sx])]}
        disableRipple={disabled}
        onClick={disabled ? undefined : onClick}
        {...props}
      >
        {children}
      </MUIToggleButton>
    );
  },
);
