// Base size
uniform float size;

// Size factor to apply to hovered placeholders
uniform float hoveredSizeFactor;

// Size factor to apply to selected placeholders
uniform float selectedSizeFactor;

// Flag with the placeholder state
attribute int state;
flat out int vState;
#ifdef USE_COLORS
attribute vec3 color;
out vec3 vColor;
#endif

#define NORMAL_STATE 0
#define HOVER_STATE 1
#define SELECTED_STATE 2

void main() {
	// Project position
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);

    // Adjust point size to the placeholder state
    if ((state & SELECTED_STATE) == SELECTED_STATE) {
        gl_PointSize = size * selectedSizeFactor;
    } else if ((state & HOVER_STATE) == HOVER_STATE) {
        gl_PointSize = size * hoveredSizeFactor;
    } else {
	    gl_PointSize = size;
    }

    // Forward state to the fragment shader
    vState = state;
#ifdef USE_COLORS
    vColor = color;
#endif
}
