import { pixels2m } from "@faro-lotv/lotv";
import { Color as ColorProp, MeshProps, useFrame } from "@react-three/fiber";
import { useRef } from "react";
import {
  Camera,
  DoubleSide,
  Mesh,
  Texture,
  Vector3,
  Vector3Tuple,
} from "three";
import { useViewportRef } from "../../hooks/use-viewport-ref";

/** Default background for a user marker */
const DEF_BACKGROUND = 0xffffff;

/** Resolution of the circle mesh used for the user marker */
const CIRCLE_SEGMENTS = 32;

/**
 * This height is used to display the user marker just above the sheet so that
 * the sheet and user marker do not fight for the same plain resulting in user marker flickering effect
 */
const HEIGHT_OFFSET = 1;

/** The default pixel size of the marker */
const MARKER_DEFAULT_PIXEL_SIZE = 40;

/**
 * Create variables here to avoid creating them every frame
 */
const globalPos = new Vector3();
const globalDir = new Vector3();

export interface IMapUserMarkerVariants {
  /** Texture to use when the user marker  */
  defaultTexture: Texture;
}

type MapUserMarkerProps = MeshProps & {
  /** the camera that represents the user */
  userCamera: Camera;
  /** The global position of the sheet that this marker is on */
  sheetPosition: Vector3Tuple;
  /** Texture to use when the user marker  */
  texture: Texture;
  /** The size (in meters) of this user marker */
  size?: number;
  /** Thea background color for this user marker */
  background?: ColorProp;
};

/** Type of a ref to a user marker */
export type MapUserMarkerRef = Mesh | null;

/**
 * @returns A component to render a user marker on a 2d map
 */
export function MapUserMarker({
  userCamera,
  sheetPosition,
  texture,
  size = 1,
  background = DEF_BACKGROUND,

  // All event handlers are part of rest so we can forward all of them to the mesh
  ...rest
}: MapUserMarkerProps): JSX.Element {
  // Handle on the mesh
  const ref = useRef<Mesh>(null);

  const viewport = useViewportRef();

  // At every frame compute the mesh scale so the size is in pixel and not meters
  useFrame(({ camera, size }) => {
    if (!ref.current) return;

    // Set the marker just above the sheet at the position of the camera
    userCamera.getWorldPosition(globalPos);
    globalPos.y = sheetPosition[1] + HEIGHT_OFFSET;
    ref.current.position.copy(globalPos);

    // Compute the rotation around the up direction of the 3d camera
    userCamera.getWorldDirection(globalDir);
    // The angle computation make sense only if we're not looking directly in the up direction
    if (globalDir.x !== 0 || globalDir.z !== 0) {
      const cosTheta = Math.sqrt(
        globalDir.x * globalDir.x + globalDir.z * globalDir.z,
      );
      const invCosTheta = 1 / cosTheta;
      const phi = Math.atan2(
        -globalDir.z * invCosTheta,
        globalDir.x * invCosTheta,
      );
      ref.current.rotation.set(-Math.PI / 2, 0, phi);
    }
    // Compute the distance between the object and the maps camera along the up axis
    const distance = globalPos.sub(camera.position).dot(camera.up);

    // Compute the factor to convert pixels to meters at this distance
    const p2m = pixels2m(
      MARKER_DEFAULT_PIXEL_SIZE,
      camera,
      viewport.current?.height ?? size.height,
      distance,
    );
    ref.current.scale.setScalar(p2m);
  }, 0);
  // Render the sprite
  return (
    <mesh ref={ref} {...rest}>
      <circleGeometry attach="geometry" args={[size, CIRCLE_SEGMENTS]} />
      <meshBasicMaterial
        attach="material"
        side={DoubleSide}
        map={texture}
        transparent={true}
        color={background}
        alphaTest={0.1}
        depthTest={false}
        depthWrite={false}
      />
    </mesh>
  );
}
