// Import adapter so typescript knows what date time library is in use
// without this line applications using flat-ui without an adapter will
// fail to type-check this file
import type {} from "@mui/x-date-pickers/AdapterLuxon";

import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  DateView,
  DatePicker as MUIDatePicker,
  PickersDay,
  PickersDayProps,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import {
  CSSProperties,
  RefAttributes,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FontWeights } from "../faro-theme";
import { CalendarIcon } from "../icons/icons";
import {
  DatePickerVariant,
  VariantStyle,
  variantMap,
} from "./date-picker-variants";

export type DatePickerProps = {
  /** Label for this date picker */
  label: string;

  /**
   * Initial selected date.
   * If it is not defined the footer will not be shown
   */
  initialDate?: Date;

  /** Current selected date */
  date?: Date;

  /**
   * Color variant of the DatePicker
   *
   * @default Light
   */
  variant?: `${DatePickerVariant}`;

  /**
   * If true a small dot will be rendered under the "today" day
   *
   * @default false
   */
  enableDot?: boolean;

  /**
   * If true the DatePicker is shown in read-only mode and it will not be possible to select a date
   *
   * @default false
   */
  readOnly?: boolean;

  /**
   * If true the DatePicker is shown as disabled and it will not be possible to select or copy a date
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * If true the DatePicker will be shown with the error style
   *
   * @default false
   */
  error?: boolean;

  /**
   * True, if the date picker is clearable
   *
   * @default false
   */
  isClearable?: boolean;

  /** Callback to notify the user selected a new date*/
  onChange?(date?: Date): void;

  /** Optional style to apply to the FormControl component that contains the DatePicker */
  formSx?: SxProps<Theme>;
};

/**
 * @returns a customized MUI DatePicker that follow the FARO design guidelines
 */
export function DatePicker({
  label,
  initialDate: sourceInitialDate,
  date: sourceDate,
  variant = DatePickerVariant.Light,
  enableDot = false,
  readOnly = false,
  disabled = false,
  error = false,
  isClearable = false,
  onChange,
  formSx,
}: DatePickerProps): JSX.Element {
  // isOpen is a reflection of the internal state of the MuiDatePicker. Its value is used to style the components.
  // updateIsOpen is only used in callbacks of the Mui component. It cannot be used to forcefully open the DatePicker.
  const [isOpen, updateIsOpen] = useState(false);

  // Convert the date objects to the luxon object MUI expects, if the date is available
  const initialDate = useMemo(
    () =>
      sourceInitialDate ? DateTime.fromJSDate(sourceInitialDate) : undefined,
    [sourceInitialDate],
  );
  const date = useMemo(
    () => (sourceDate ? DateTime.fromJSDate(sourceDate) : undefined),
    [sourceDate],
  );

  /** Object containing all the colors to use for the current variant */
  const variantStyle = variantMap[variant];

  /** Color to apply to the input field text label */
  const labelColor = useMemo(() => {
    if (error) {
      return variantStyle.errorColor;
    }
    if (disabled) {
      return variantStyle.disabledPrimaryColor;
    }
    return variantStyle.textColorInputFieldLabel;
  }, [
    disabled,
    error,
    variantStyle.disabledPrimaryColor,
    variantStyle.errorColor,
    variantStyle.textColorInputFieldLabel,
  ]);

  /** Wrapper of the CustomPickersDay component, used to forward some custom props */
  const CustomPickersDayWrapper = useCallback(
    (props: PickersDayProps<DateTime>) => (
      <CustomPickersDay
        variantStyle={variantStyle}
        enableDot={enableDot}
        {...props}
      />
    ),
    [enableDot, variantStyle],
  );

  /** Wrapper of the CustomCalendarLayout component, used to forward some custom props */
  const CustomCalendarLayoutWrapper = useCallback(
    (props: PickersLayoutPropsWithRef) => (
      <CustomCalendarLayout
        variantStyle={variantStyle}
        initialDate={initialDate}
        {...props}
      />
    ),
    [initialDate, variantStyle],
  );

  return (
    <FormControl variant="outlined" sx={formSx}>
      <InputLabel
        sx={{
          color: labelColor,
        }}
      >
        {label}
      </InputLabel>
      <MUIDatePicker<DateTime>
        disabled={disabled}
        readOnly={readOnly}
        onOpen={() => updateIsOpen(true)}
        onClose={() => updateIsOpen(false)}
        sx={{
          height: "56px",
          // Pushing the label above the input
          "label + &": {
            marginTop: 3,
          },
          ".MuiInputBase": {
            "&-root": {
              // Set the height of the input field
              height: "38px",
              // Update color of text and background of the input field
              backgroundColor: variantStyle.backgroundColor,
              // Update the color of the input field's text value
              color: isOpen
                ? variantStyle.inputFieldHoverColor
                : variantStyle.inputFieldDefaultColor,
              // Change the style on hover of the entire input field, so that all its components are updated at the same time
              ":hover": {
                // Update the color of the input field's text value on hover
                color: variantStyle.inputFieldHoverColor,
                ">fieldset": {
                  // Update border color of input field on hover
                  borderColor: variantStyle.inputFieldHoverColor,
                },
                ">input::placeholder": {
                  // Update the color of the input field's placeholder text on hover (if available)
                  color: variantStyle.inputFieldHoverColor,
                },
                ">.MuiInputAdornment-root>button": {
                  // Change the color of the calendar icon on hover
                  color: variantStyle.inputFieldHoverColor,
                },
              },
              // Update style of all components when the date picker is disabled
              "&.Mui-disabled": {
                ">input": {
                  // Color the input field's text value when it's disabled
                  color: variantStyle.disabledSecondaryColor,
                  // This property is set to override an mui color that has priority over the "color" property
                  WebkitTextFillColor: variantStyle.disabledSecondaryColor,
                },
                ">.MuiInputAdornment-root>button": {
                  // Color the calendar icon when it's disabled
                  color: variantStyle.disabledPrimaryColor,
                },
                ">fieldset": {
                  // Color the input field's border when it's disabled
                  border: `1px solid ${variantStyle.disabledSecondaryColor}`,
                },
              },
              // Update style of all components when the date picker is in read only mode
              "&.Mui-readOnly": {
                // Change the color of the input field background when it's read only
                backgroundColor: variantStyle.readOnlyBackgroundColor,
                ">input": {
                  // Color the input field's text value when it's read only
                  color: variantStyle.inputFieldHoverColor,
                  // This property is set to override an mui color that has priority over the "color" property
                  WebkitTextFillColor: variantStyle.inputFieldHoverColor,
                },
                ">.MuiInputAdornment-root>button": {
                  // Color the calendar icon when it's read only
                  color: variantStyle.inputFieldHoverColor,
                },
                ">fieldset": {
                  // Remove border in read only mode
                  border: "none",
                },
              },
              // Update style of all components when the date picker is in error state
              "&.Mui-error": {
                ">fieldset": {
                  // Apply border to the input field when the date picker is in error state
                  border: `2px solid ${variantStyle.errorColor}`,
                },
              },
            },
            "&-input": {
              "::placeholder": {
                // Update the color of the input field's placeholder
                // It will have a different color if the DatePicker is opened
                color: isOpen
                  ? variantStyle.inputFieldHoverColor
                  : variantStyle.inputFieldDefaultColor,
              },
            },
          },
          ".MuiIconButton-root": {
            // Update the color of the calendar icon
            color: isOpen
              ? variantStyle.inputFieldHoverColor
              : variantStyle.inputFieldDefaultColor,
            ".MuiSvgIcon-root": {
              // Resize the calendar icon
              fontSize: "18px",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            // Update border color of the input field (use important to override the mui class that is created)
            // Since there is no CSS class to know if this element is "active" (it's active when the DatePicker is opened),
            // we directly check the open property of the DatePicker
            border: isOpen
              ? `2px solid ${variantStyle.selectedColor} !important`
              : `1px solid ${variantStyle.inputFieldDefaultColor}`,
          },
        }}
        views={["year", "month", "day"]}
        slots={{
          // Use correct icon for the calendar button
          openPickerIcon: CalendarIcon,
          // Hide the arrow to open the month selector
          switchViewIcon: () => null,
          // Inject our custom day picker renderer
          day: CustomPickersDayWrapper,
          // Inject our custom calendar layout to add the footer
          layout: CustomCalendarLayoutWrapper,
        }}
        showDaysOutsideCurrentMonth={true}
        disableHighlightToday={true}
        slotProps={{
          textField: {
            error,
          },
          field: { clearable: isClearable },
        }}
        value={date}
        defaultValue={initialDate}
        onChange={(date) =>
          onChange?.(date instanceof DateTime ? date.toJSDate() : undefined)
        }
      />
    </FormControl>
  );
}

/**
 * @returns the color to use for the PickersDay
 * @param today true if this PickersDay is for today
 * @param outsideCurrentMonth true if this PickersDay is outside of the current month
 * @param variantStyle object with colors to use for the current variant of the DatePicker
 */
function computeDayLabelColor(
  today: boolean | undefined,
  outsideCurrentMonth: boolean,
  variantStyle: VariantStyle,
): string | undefined {
  if (today) {
    // Color of the current day
    return variantStyle.selectedColor;
  }
  if (outsideCurrentMonth) {
    // Color of the visible days that are outside the current month
    return variantStyle.textColorDayOutsideMonth;
  }
  // Color of the non selected days of the current month
  return variantStyle.secondaryColor;
}

type PickersDayTodayDotProps = {
  /** Color to use for the dot */
  color: CSSProperties["backgroundColor"];
};

/**
 * @returns a dot placed just below a PickersDay to identify the "today" picker
 */
function PickersDayTodayDot({ color }: PickersDayTodayDotProps): JSX.Element {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "relative",
        height: "10px",
        width: "auto",
        bottom: "10px",
        m: 0,
        p: 0,
      }}
    >
      <Box
        component="span"
        sx={{
          height: "5px",
          width: "5px",
          backgroundColor: color,
          borderRadius: "50%",
          display: "inline-block",
        }}
      />
    </Stack>
  );
}

type CustomPickersDayProps = PickersDayProps<DateTime> & {
  /** colors to apply to the pickers day. They depend on the current variant of the DatePicker */
  variantStyle: VariantStyle;

  /** if true a dot will be rendered under the "today" day */
  enableDot: boolean;
};

/**
 * @returns a custom PickersDay to match the FARO Design
 */
function CustomPickersDay({
  outsideCurrentMonth,
  selected,
  today,
  variantStyle,
  enableDot,
  ...rest
}: CustomPickersDayProps): JSX.Element {
  const styles: SxProps<Theme> = {
    // Update colors of the focused day (can focus with tab)
    "&:focus-visible": {
      backgroundColor: `${variantStyle.hoverColor}1E`,
      color: variantStyle.selectedColor,
      border: `1px solid ${variantStyle.selectedColor}`,
    },
    // Update style on pressed state (this style is applied after long press and release, or after right click)
    "&:focus": {
      backgroundColor: `${variantStyle.hoverColor}1E`,
      color: variantStyle.selectedColor,
    },
    "&.Mui-selected": {
      // Update background color of the current selected day, when pressing another day (long press on new date)
      backgroundColor: variantStyle.selectedColor,
      // Update color of the text for the selected day
      color: variantStyle.textColorSelectedDay,
      // Add border to selected day
      border: `1px solid ${variantStyle.selectedColor}`,
      "&:focus": {
        // Update colors of the selected day when it's focused
        backgroundColor: variantStyle.selectedColor,
        border: variantStyle.selectedColor,
      },
    },
    ".MuiTouchRipple-child": {
      // Update color of ripple and add 40% opacity (between mouse press and mouse release)
      backgroundColor: `${variantStyle.hoverColor}66`,
    },
    "&:hover": {
      // Update day background color when hovered
      backgroundColor: `${variantStyle.hoverColor}26`,
    },
    // Update days text color if the selected date is outside the date range (before 1900 or after 2099)
    "&.Mui-disabled": {
      // Use color for the non selected and disabled days text of the current month
      color: `${variantStyle.disabledPrimaryColor} !important`,
      "&.Mui-selected": {
        // Use specific color for the selected and disabled day text
        color: `${variantStyle.textColorSelectedDay} !important`,
      },
      "&.MuiPickersDay-dayOutsideMonth": {
        // Use specific color for the disabled days text outside the current month
        color: `${variantStyle.disabledSecondaryColor} !important`,
      },
    },
    color: computeDayLabelColor(today, outsideCurrentMonth, variantStyle),
    fontSize: "0.875rem",
    fontWeight: outsideCurrentMonth
      ? FontWeights.Regular
      : FontWeights.SemiBold,
  };

  return (
    // Wrap PickersDay in a Box to overlay a little blue dot for the "today" picker
    <Box component="div" sx={{ height: "36px" }}>
      <PickersDay
        sx={styles}
        tabIndex={0}
        outsideCurrentMonth={outsideCurrentMonth}
        {...rest}
      />
      {enableDot && today && !selected && (
        <PickersDayTodayDot color={variantStyle.selectedColor} />
      )}
    </Box>
  );
}

type PickersLayoutPropsWithRef = PickersLayoutProps<
  DateTime | null,
  DateTime,
  DateView
> &
  RefAttributes<HTMLDivElement>;

type CustomCalendarLayoutProps = PickersLayoutPropsWithRef & {
  /** Colors to apply to the layout. They depend on the current variant of the DatePi cker */
  variantStyle: VariantStyle;

  /** The initialDate that was defined when the DatePicker was created to use in the footer */
  initialDate?: DateTime;
};

/**
 * @returns a custom calendar layout with a Footer showing the initial date
 */
function CustomCalendarLayout({
  variantStyle,
  initialDate,
  isLandscape,
  ...rest
}: CustomCalendarLayoutProps): JSX.Element {
  const { content, actionBar } = usePickerLayout({ isLandscape, ...rest });
  return (
    <Stack
      direction="column"
      // Update background color of the date picker
      sx={{ backgroundColor: variantStyle.backgroundColor }}
    >
      <PickersLayoutRoot ownerState={{ isLandscape, ...rest }}>
        <PickersLayoutContentWrapper
          className={pickersLayoutClasses.contentWrapper}
          sx={{
            // Change color of days labels
            ".MuiDayCalendar-weekDayLabel": {
              // Update text color of initials of week days
              color: variantStyle.textColorWeekDay,
              fontWeight: FontWeights.SemiBold,
              fontSize: "0.875rem",
            },
            ".MuiPickersMonth-monthButton": {
              // Update text color of months in "months" view
              color: variantStyle.secondaryColor,
            },
            ".MuiPickersYear-yearButton": {
              // Update text color of years in "years" view
              color: variantStyle.secondaryColor,
            },
            // Adjust the position of the month change arrow to be
            // at the side of the month-year label and not on the top right
            // of the calendar
            ".MuiPickersCalendarHeader-root": {
              padding: 0,
            },
            ".MuiPickersArrowSwitcher": {
              "&-spacer": {
                display: "flex",
                flex: 1,
              },
              "&-root": {
                width: "100%",
                // Leave some space to the left and right so that the arrow buttons are not on the edge
                mx: "25px",
              },
              "&-button": {
                // Resize the icons to change month
                fontSize: "16px",
                // Update color of arrow icons used to change current month
                color: variantStyle.iconColor,
              },
            },
            ".MuiPickersCalendarHeader": {
              "&-labelContainer": {
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
              },
              "&-label": {
                // Resize the current month text
                fontSize: "0.875rem",
                margin: 0,
                fontWeight: FontWeights.Bold,
                // Update text color of current month in the header
                color: variantStyle.secondaryColor,
              },
              "&-switchViewButton": {
                display: "none",
              },
            },
          }}
        >
          {content}
        </PickersLayoutContentWrapper>
        {actionBar}
      </PickersLayoutRoot>
      {initialDate && (
        <>
          <Divider sx={{ mx: 1 }} />

          <Typography
            fontSize="12px"
            // Update text color of the footer
            sx={{ m: 1, px: 1, color: variantStyle.textColorFooter }}
          >
            Original Date:{" "}
            {initialDate.toLocaleString(DateTime.DATE_FULL, {
              locale: "en-US",
            })}
          </Typography>
        </>
      )}
    </Stack>
  );
}
