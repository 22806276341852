import { Link, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useCheckForOverflow } from "../../hooks";
import { neutral } from "../colors";

/** Style used to shorten the breadcrumb if it's too long */
const SHORT_BREADCRUMB_ITEM_STYLE: SxProps = {
  // Make ellipse work for long labels. The parent component needs to specify a maxWidth
  display: "inline-block",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  justifyContent: "center",
};

/** Common style to both the breadcrumb link and text */
const BREADCRUMB_ITEM_STYLE: SxProps = {
  ...SHORT_BREADCRUMB_ITEM_STYLE,

  // Properly style the label text
  letterSpacing: "0.5px",
  textTransform: "uppercase",
  fontSize: "0.625rem",
  fontWeight: 800,
  color: neutral[600],
};

type BreadcrumbItemProps = {
  /** Text to display for the breadcrumb */
  label: JSX.Element | string;

  /** Optional URL that will be opened when the breadcrumb is clicked */
  link?: string;

  /**
   * Flag to disable the breadcrumb interaction
   *
   * @default false
   */
  isDisabled?: boolean;

  /**
   * Flag to open the link in a new tab
   *
   * @default false
   */
  openInNewTab?: boolean;

  /** Optional style to apply to the Breadcrumb */
  sx?: SxProps<Theme>;

  /** Optional function called when a link is clicked. This is not called on a text label */
  onClickLink?(): void;
};

/**
 * @returns a styled link or text, that can be used in the FaroBreadcrumbs component
 */
export function BreadcrumbItem({
  label,
  link,
  isDisabled = false,
  openInNewTab = false,
  sx,
  onClickLink,
}: BreadcrumbItemProps): JSX.Element {
  const isTextLabel = typeof label === "string";

  // Apply the custom style for the text, only if the label is a string.
  // Otherwise, use the component's style and only add the shortening logic.
  const commonStyle = useMemo(
    () =>
      isTextLabel
        ? [BREADCRUMB_ITEM_STYLE, ...(Array.isArray(sx) ? sx : [sx])]
        : [SHORT_BREADCRUMB_ITEM_STYLE, ...(Array.isArray(sx) ? sx : [sx])],
    [isTextLabel, sx],
  );

  // The styling props for the link element in the breadcrumbs item.
  const linkSx = useMemo<SxProps<Theme>>(
    () => [
      {
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? "0.5" : "1",
        "&:hover": {
          color: neutral[800],
        },
      },
      ...commonStyle,
    ],
    [isDisabled, commonStyle],
  );

  const { hasOverflown, checkForOverflow } = useCheckForOverflow();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  return (
    <Tooltip
      title={label}
      open={isTooltipOpen && hasOverflown}
      disableHoverListener={!hasOverflown}
      onClose={() => setIsTooltipOpen(false)}
    >
      {link ? (
        <Link
          aria-label="breadcrumb-link"
          href={link}
          target={openInNewTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
          underline="none"
          onClick={onClickLink}
          onMouseEnter={(ev) => {
            checkForOverflow(ev.currentTarget);
            setIsTooltipOpen(true);
          }}
          sx={linkSx}
        >
          {label}
        </Link>
      ) : (
        <Typography
          aria-label="breadcrumb-text"
          component={isTextLabel ? "p" : "div"}
          onMouseEnter={(ev) => {
            checkForOverflow(ev.currentTarget);
            setIsTooltipOpen(true);
          }}
          sx={commonStyle}
        >
          {label}
        </Typography>
      )}
    </Tooltip>
  );
}
