import { IPose, IQuat, IVec3 } from "@faro-lotv/ielement-types";
import {
  Matrix4,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4Tuple,
} from "./math";

export const Z_TO_Y_UP_QUAT = Object.freeze(
  new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2),
);

export const Z_TO_Y_UP_MATRIX = Object.freeze(
  new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), -Math.PI / 2),
);

/**
 * Holds a ThreeJS transform in a right-handed coordinate system.
 */
interface ThreeTransform {
  position?: Vector3;
  quaternion?: Quaternion;
  scale?: Vector3;
}

/**
 * Holds a ThreeJS transform in a right-handed coordinate system.
 */
interface ThreeTransformTuple {
  position?: Vector3Tuple;
  quaternion?: Vector4Tuple;
  scale?: Vector3Tuple;
}

/**
 * Converts from right-handed ThreeJS coordinates into left-handed IElement coordinates.
 * Use when creating new IElements or mutations from ThreeJS based data.
 *
 * @param transform A transform in a ThreeJs coordinate system
 * @param isRightHanded whether this transform is right-handed and should therefore be converted to a left-handed system (Default: true)
 * @returns An IElement compatible pose
 */
export function convertThreeToIElementTransform(
  transform: ThreeTransform,
  isRightHanded = true,
): IPose {
  const flip = isRightHanded ? -1 : 1;
  return {
    pos: transform.position
      ? {
          x: transform.position.x,
          y: transform.position.y,
          z: flip * transform.position.z,
        }
      : null,
    rot: transform.quaternion
      ? {
          x: transform.quaternion.x,
          y: transform.quaternion.y,
          z: flip * transform.quaternion.z,
          w: flip * transform.quaternion.w,
        }
      : null,
    scale: transform.scale
      ? {
          x: transform.scale.x,
          y: transform.scale.y,
          z: transform.scale.z,
        }
      : null,
    gps: null,
    isWorldRot: false,
  };
}

/**
 * Convert from left-handed quaternion to right-handed quaternion
 *
 * @param rot The quaternion referred to a left-handed coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns The input quaternion referred to a right-handed coordinate system
 */
export function convertIElementToThreeRotation(
  rot: IQuat | null | undefined,
  leftHanded: boolean = true,
): Vector4Tuple {
  if (!rot) return [0, 0, 0, 1];
  const flip = leftHanded ? -1 : 1;
  return [rot.x, rot.y, flip * rot.z, flip * rot.w];
}

/**
 * Convert from left-handed position to right-handed position
 *
 * @param pos The position referred to a left-handed coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns The input position referred to a right-handed coordinate system
 */
export function convertIElementToThreePosition(
  pos: IVec3 | null | undefined,
  leftHanded: boolean = true,
): Vector3Tuple {
  if (!pos) return [0, 0, 0];
  const flip = leftHanded ? -1 : 1;
  return [pos.x, pos.y, flip * pos.z];
}

/**
 * Converts from left-handed IElement into right-handed ThreeJS coordinates coordinates.
 *
 * @param pose The pose to convert into the three js coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns A ThreeJs compatible transform
 */
export function convertIElementToThreeTransform(
  pose: IPose | null | undefined,
  leftHanded: boolean = true,
): Required<ThreeTransformTuple> {
  return {
    position: convertIElementToThreePosition(pose?.pos, leftHanded),
    quaternion: convertIElementToThreeRotation(pose?.rot, leftHanded),
    scale: [pose?.scale?.x ?? 1, pose?.scale?.y ?? 1, pose?.scale?.z ?? 1],
  };
}

const X_AXIS = new Vector3();
const Y_AXIS = new Vector3();
const Z_AXIS = new Vector3();
const CROSS_PROD = new Vector3();

/**
 * Returns whether the given pose is left-handed or right-handed.
 * This is determined by checking whether the cross product of the X and Y axes
 * is co-directional with the Z axis (right handed) or not (left handed). This
 * computation is valid because we assume that all scale values are always positive.
 * This constraint will be also documented in a confluence page.
 *
 * @param pose The pose to check
 * @returns true if the pose is left-handed, false if it is right-handed
 */
export function isPoseLeftHanded(pose: Matrix4): boolean {
  const e = pose.elements;
  X_AXIS.set(e[0], e[1], e[2]);
  Y_AXIS.set(e[4], e[5], e[6]);
  Z_AXIS.set(e[8], e[9], e[10]);
  CROSS_PROD.crossVectors(X_AXIS, Y_AXIS);
  return CROSS_PROD.dot(Z_AXIS) < 0;
}
