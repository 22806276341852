import { Pivot } from "@faro-lotv/flat-ui";
import {
  LodPointCloud,
  PointerRotates,
  SupportedCamera,
  WalkOrbitControls as WalkOrbitControlsLotv,
  clamp,
} from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import { useForkRef } from "@mui/material";
import { useCursor } from "@react-three/drei";
import {
  Object3DNode,
  Overwrite,
  Vector3 as Vector3Prop,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import {
  BufferGeometry,
  MOUSE,
  Matrix4,
  Object3D,
  Points,
  PointsMaterial,
  Quaternion,
  Raycaster,
  Vector3,
} from "three";
import { useNonExhaustiveEffect } from "../../hooks";
import {
  CustomClickHandler,
  TypedEventCallback,
  useCustomClickEvent,
  useKeyPressedCallback,
  useKeyboardEvents,
  useSvg,
  useThreeEventTarget,
  useTypedEvent,
} from "../hooks";
import { CameraTargetState, CameraTransition } from "../transitions";
import { PrecisePoints, SinglePointGeometry } from "../utils/points";
import { parseVector3 } from "../utils/props";

/**
 * Disable the realtime raycating for all the pointclouds in the multiple scenes
 * that we want to raycast on
 *
 * @param targets all the scenes we want to check
 * @returns the list of pointclouds that were changed and need to be restored
 */
function disableRealtimeRaycasting(targets: Object3D[]): LodPointCloud[] {
  const toRestore: LodPointCloud[] = [];
  targets.map((t) =>
    t.traverse((o) => {
      if (o instanceof LodPointCloud && o.realTimeRaycasting) {
        o.realTimeRaycasting = false;
        toRestore.push(o);
      }
    }),
  );

  return toRestore;
}

/**
 * Restore the realTimeRaycasting flag on a list of LodPointCloud
 *
 * @param pcs the pointclouds to restore
 */
function restoreRealtimeRaycasting(pcs: LodPointCloud[]): void {
  for (const pc of pcs) {
    pc.realTimeRaycasting = true;
  }
}

type WalkOrbitControlsProps = Overwrite<
  Object3DNode<WalkOrbitControlsLotv, typeof WalkOrbitControlsLotv>,
  {
    targetDismissed?: TypedEventCallback<
      WalkOrbitControlsLotv["targetDismissed"]
    >;

    /** The camera managed by the controls. @default the main r3f scene camera */
    camera?: SupportedCamera;

    /**
     * The update priority for the control's update in r3f's `.useFrame()`
     *
     * @default -10
     */
    updatePriority?: number;

    /** if defined and is true disable inertia in camera movements */
    disableInertia?: boolean;

    /** Callback executed when the user has interacted with the controls */
    onUserInteracted?: TypedEventCallback<
      WalkOrbitControlsLotv["userInteracted"]
    >;

    onCameraStartedTranslating?: TypedEventCallback<
      WalkOrbitControlsLotv["cameraStartedTranslating"]
    >;

    onCameraStoppedTranslating?: TypedEventCallback<
      WalkOrbitControlsLotv["cameraStoppedTranslating"]
    >;
  }
>;

/**
 * Wrapper for the Lotv WalkOrbitControls to be used in the ExplorationControls component
 */
const WalkOrbitControls = forwardRef<
  WalkOrbitControlsLotv,
  WalkOrbitControlsProps
>(function WalkOrbitControls(
  {
    target,
    targetDismissed,
    camera: cameraProp,
    updatePriority = UPDATE_CONTROLS_PRIORITY,
    onUserInteracted,
    onCameraStartedTranslating,
    onCameraStoppedTranslating,
    disableInertia = false,
    obstacles,
    ...rest
  }: WalkOrbitControlsProps,
  ref,
): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const set = useThree((s) => s.set);
  const get = useThree((s) => s.get);
  const eventTarget = useThreeEventTarget();

  const camera = cameraProp ?? defaultCamera;

  const [controls] = useState(new WalkOrbitControlsLotv(camera));

  useTypedEvent(controls.targetDismissed, targetDismissed);

  useTypedEvent(controls.userInteracted, onUserInteracted);

  useTypedEvent(controls.cameraStartedTranslating, onCameraStartedTranslating);

  useTypedEvent(controls.cameraStoppedTranslating, onCameraStoppedTranslating);

  // Attach to the correct eventTarget for HTML events
  useEffect(() => {
    controls.attach(eventTarget);
    return () => controls.detach();
  }, [controls, eventTarget]);

  // Manage the inertia parameters
  useEffect(() => {
    if (!disableInertia) return;
    // Store previous values
    const { movementInertia, rotationInertia } = controls.mouseSettings;
    // Disable inertia
    controls.mouseSettings.movementInertia = 0;
    controls.mouseSettings.rotationInertia = 0;

    return () => {
      // Restore previous values
      controls.mouseSettings.movementInertia = movementInertia;
      controls.mouseSettings.rotationInertia = rotationInertia;
    };
  }, [controls, disableInertia]);

  // Register this control as the default and restore the old default when unmounted
  useEffect(() => {
    const oldControls = get().controls;
    set({ controls });

    return () => {
      set({ controls: oldControls });
    };
  }, [controls, get, set]);

  // Update the controls at every frame
  useFrame((_, delta) => {
    controls.update(delta);
  }, updatePriority);

  useEffect(() => eventTarget.focus(), [eventTarget, obstacles]);

  return (
    <primitive
      object={controls}
      ref={ref}
      {...rest}
      /* eslint-disable react/no-unknown-property */
      camera={camera}
      obstacles={obstacles}
      target={target}
      /* eslint-enable react/no-unknown-property */
    />
  );
});

type ExplorationPivotRef = Points<BufferGeometry, PointsMaterial>;

const EXPLORATION_PIVOT_NAME = "ExplorationPivot";

/** Duration of the zoom-to-pivot animation, in seconds */
const ZOOM_ANIMATION_TIME = 1;

/** Duration of the pivot change animation, in seconds */
const CHANGE_PIVOT_ANIMATION_TIME = 0.35;

/**
 * Render and interact with the Pivot for ExplorationControls
 */
const ExplorationPivot = forwardRef<ExplorationPivotRef>(
  function ExplorationPivot(_, ref): JSX.Element {
    // Show hand cursor when hovered
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);

    const defaultTexture = useSvg(Pivot, 256, 256);

    return (
      <PrecisePoints
        ref={ref}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
        name={EXPLORATION_PIVOT_NAME}
      >
        <SinglePointGeometry />
        <pointsMaterial
          size={hovered ? 16 : 10}
          map={defaultTexture}
          transparent={true}
          depthTest={false}
          /* eslint-disable react/no-unknown-property */
          sizeAttenuation={false}
          alphaTest={0.1}
          /* eslint-enable react/no-unknown-property */
        />
      </PrecisePoints>
    );
  },
);

/**
 * @param obj The 3D object to check for being a measurement line.
 * @returns true, if the object is the ExplorationPivot
 */
export function isExplorationPivot(obj: Object3D): boolean {
  return obj.name === EXPLORATION_PIVOT_NAME;
}

export type ExplorationControlsProps = {
  /** The camera managed by the controls. @default the main r3f scene camera */
  camera?: SupportedCamera;

  /** Objects to check for zoom speed and pivot positioning */
  obstacles?: Object3D[];

  /** Current position of the pivot, undefined to remove the pivot */
  target?: Vector3Prop | undefined;

  /** Max distance where to place the camera when the pivot changes */
  focusDistance?: number;

  /** Notify to reset the Pivot to the initial value */
  onResetPivot?(): void;

  /** Enable click focus, double click zoom will also be disabled if click focus is disabled */
  enableClickToFocus?: boolean;

  /** Enable click zoom, double click zoom will be disabled*/
  enableDoubleClickToZoom?: boolean;

  /**
   * Set to false to disable the controls from moving the camera
   *
   * @default true
   */
  enabled?: boolean;

  /** if defined and is true disable inertia in camera movements */
  disableInertia?: boolean;

  /** if defined and is true hide Pivot */
  hidePivot?: boolean;

  /** Whether the pointer rotates the view direction (like a first person shooter) or the model (like a pano images viewer) */
  pointerRotates?: PointerRotates;

  /** Callback executed when the user has interacted with the controls */
  onUserInteracted?: TypedEventCallback<
    WalkOrbitControlsLotv["userInteracted"]
  >;

  /** Callback issued when the user has started moving the camera, either via the pointer or via keys. */
  onCameraStartedTranslating?: TypedEventCallback<
    WalkOrbitControlsLotv["cameraStartedTranslating"]
  >;

  /** Callback issued when the user has stopped moving the camera, either via the pointer or via keys. */
  onCameraStoppedTranslating?: TypedEventCallback<
    WalkOrbitControlsLotv["cameraStoppedTranslating"]
  >;
};

/**
 * ExplorationControls are a control scheme for 3d scene focused on exploring large areas
 * and at the same time analyzing small details.
 * They mix the interaction of an FlyControls by default, with the possibility to focus on a pivot point
 * and move to an OrbitControls experience around that pivot point
 *
 * @returns A component to manage a set of exploration controls and their pivot target
 */
export const ExplorationControls = forwardRef<
  WalkOrbitControlsLotv,
  ExplorationControlsProps
>(function ExplorationControls(
  {
    camera: cameraProp,
    obstacles,
    target: targetProp,
    focusDistance = 2.5,
    onResetPivot,
    enableClickToFocus = true,
    enableDoubleClickToZoom = true,
    enabled = true,
    disableInertia = false,
    hidePivot = false,
    pointerRotates = PointerRotates.ViewDirection,
    onUserInteracted,
    onCameraStartedTranslating,
    onCameraStoppedTranslating,
  },
  refProp,
): JSX.Element {
  const scene = useThree((s) => s.scene);
  const defaultCamera = useThree((s) => s.camera);
  const raycaster = useThree((s) => s.raycaster);
  const pointer = useThree((s) => s.pointer);
  const controls = useRef<WalkOrbitControlsLotv>(null);
  const pivot = useRef<ExplorationPivotRef>(null);

  const mergedRef = useForkRef(refProp, controls);

  const camera = cameraProp ?? defaultCamera;

  // Store the current target so we can change it after an animation when the user click
  const [target, setTarget] = useState<Vector3 | undefined>(undefined);

  // Animation data after an user click
  const [cameraAnimation, setCameraAnimation] = useState<
    CameraTargetState & { target: Vector3; duration: number }
  >();

  /**
   * Function to change the pivot with the proper animation
   */
  const changePivot = useCallback(
    (newTarget: Vector3, zoom: boolean) => {
      if (!enabled) return;
      if (!controls.current) return;
      if (zoom) {
        const { position, quaternion } =
          controls.current.computeBestCameraPoseForTarget(newTarget);
        setCameraAnimation({
          position,
          quaternion,
          target: newTarget,
          duration: ZOOM_ANIMATION_TIME,
        });
      } else {
        const mat = new Matrix4().lookAt(camera.position, newTarget, camera.up);
        const quaternion = new Quaternion();
        mat.decompose(new Vector3(), quaternion, new Vector3());
        setCameraAnimation({
          quaternion,
          target: newTarget,
          duration: CHANGE_PIVOT_ANIMATION_TIME,
        });
      }
    },
    [camera.position, camera.up, enabled],
  );

  // Trigger a camera animation when the target changes
  useNonExhaustiveEffect(() => {
    if (!enabled) return;
    if (!controls.current) return;
    const newTarget = targetProp ? parseVector3(targetProp) : undefined;
    if (!newTarget) {
      setTarget(undefined);
      return;
    }
    if (target && newTarget.equals(target)) return;
    changePivot(newTarget, false);
  }, [targetProp]);

  const togglePivotWithRaycaster = useCallback(
    (raycaster: Raycaster, zoom: boolean): void => {
      if (!enabled) return;
      if (!controls.current) return;

      const targets =
        obstacles && pivot.current ? [...obstacles, pivot.current] : [scene];
      const toRestore = disableRealtimeRaycasting(targets);

      // Cast ray on obstacles + pivot
      const hits = raycaster.intersectObjects(targets);

      restoreRealtimeRaycasting(toRestore);

      // If the user clicked outside the scene reset pivot to the initial position
      if (hits.length === 0) {
        onResetPivot?.();
        return;
      }

      // If we hit the pivot remove it
      const hitPivot = hits.some((hit) => hit.object === pivot.current);
      if (hitPivot) {
        controls.current.removeTarget();
        setTarget(undefined);
        return;
      }

      for (const hit of hits) {
        // Discard hits too close to the camera
        if (hit.distance < 0.1) continue;

        // Compute clicked point, need to clone here to not modify raycaster ray in-place
        const newTarget = raycaster.ray.origin
          .clone()
          .add(raycaster.ray.direction.multiplyScalar(hit.distance));

        changePivot(newTarget, zoom);
        break;
      }
    },
    [changePivot, enabled, obstacles, onResetPivot, scene],
  );

  // Handle user clicks, we need to do a custom click event because we want
  // to raycast explicitly on the obstacles or the scene so we can't use
  // threejs events
  useCustomClickEvent(
    useCallback<CustomClickHandler>(
      ({ event, raycaster, isDoubleClick }) => {
        if (!enabled) return;
        if (event.button !== MOUSE.LEFT) return;
        if (!enableClickToFocus) return;

        togglePivotWithRaycaster(
          raycaster,
          isDoubleClick && enableDoubleClickToZoom,
        );
      },
      [
        enabled,
        enableClickToFocus,
        togglePivotWithRaycaster,
        enableDoubleClickToZoom,
      ],
    ),
  );

  function onKeyPressed(ev: KeyboardEvent): void {
    if (!enabled || !enableClickToFocus) return;
    if (ev.code === "KeyF") {
      // Focus the camera on the point under the mouse
      raycaster.setFromCamera(pointer, camera);
      togglePivotWithRaycaster(raycaster, true);
    }
  }

  const keyboardEvents = useKeyboardEvents();
  useKeyPressedCallback(keyboardEvents, onKeyPressed);

  // When an animation finish remove it and change the target
  const onTransitionFinished = useCallback(() => {
    if (!cameraAnimation) return;
    setTarget(cameraAnimation.target);
    setCameraAnimation(undefined);
  }, [cameraAnimation]);

  // Update the enabled state of the underlying controls
  // Cancel the camera animation when the controls are disabled
  useEffect(() => {
    if (!enabled) {
      setCameraAnimation(undefined);
    }
    if (controls.current) {
      controls.current.enabled = enabled;
    }
  }, [enabled, controls]);

  // At each frame update the pivot to the last control state as they may change in real-time
  // and react state updates are too slow to keep up with the controls updates
  useFrame(() => {
    if (!enabled) return;
    if (!controls.current) return;
    if (!pivot.current) return;
    if (controls.current.target) {
      pivot.current.position.copy(controls.current.target);
      pivot.current.updateMatrixWorld();
      pivot.current.visible = !hidePivot;
      const MIN_OPACITY = 0.2;
      const MAX_OPCITY = 0.8;
      pivot.current.material.opacity = clamp(
        camera.position.distanceTo(pivot.current.position) / focusDistance,
        MIN_OPACITY,
        MAX_OPCITY,
      );
    } else {
      pivot.current.visible = false;
    }
  });

  return (
    <>
      {!cameraAnimation && (
        <WalkOrbitControls
          ref={mergedRef}
          camera={camera}
          focusDistance={focusDistance}
          obstacles={obstacles}
          target={target}
          disableInertia={disableInertia}
          targetDismissed={() => setTarget(undefined)}
          pointerRotates={pointerRotates}
          onUserInteracted={onUserInteracted}
          onCameraStartedTranslating={onCameraStartedTranslating}
          onCameraStoppedTranslating={onCameraStoppedTranslating}
        />
      )}
      {cameraAnimation && (
        <CameraTransition
          target={cameraAnimation}
          onTransitionFinished={onTransitionFinished}
          duration={cameraAnimation.duration}
        />
      )}
      {!cameraAnimation && target && <ExplorationPivot ref={pivot} />}
    </>
  );
});
