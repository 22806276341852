/**
 * An algorithm to check if an array includes an element of a "related" type, and if it does narrow the
 * searched element to the array type
 *
 * Useful for example to check if a generic IElement is in an array of IElementSections.
 * If the functions returns true the input IElement will be narrowed down to IElementSection after the call
 *
 * @param array of element to search into
 * @param toFind element to find inside the array
 * @param comparator function used to decide if two objects are the same
 * @returns true if toFind is part of array
 */
export function includes<ToFind, Item extends ToFind>(
  array: readonly Item[],
  toFind: ToFind,
  comparator: CmpFunction<ToFind> = defaultComparator,
): toFind is Item {
  return array.find((value) => comparator(value, toFind)) !== undefined;
}

/** Function used to compare two elements */
export type CmpFunction<Item> = (a: Item, b: Item) => boolean;

/**
 * Compare two items
 *
 * @param a first item
 * @param b second item
 * @returns true if the two items are the same object
 */
function defaultComparator<Item>(a: Item, b: Item): boolean {
  return a === b;
}
