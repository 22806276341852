import { GUID } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Change the thumbnail of an Element
 */
export interface MutationSetElementThumbnailUri extends BaseMutation {
  type: MutationTypes.MutationSetElementThumbnailUri;

  /** Thew new value of the uri to set */
  value: string;
}

/**
 * @returns a mutation to change an IElements thumbnail
 * @param elementId id of the element to change the thumbnail of
 * @param uri the thumbnailUri to set
 */
export function createMutationSetElementThumbnailUri(
  elementId: GUID,
  uri: string,
): MutationSetElementThumbnailUri {
  return {
    ...createBaseMutation(
      MutationTypes.MutationSetElementThumbnailUri,
      elementId,
    ),
    value: uri,
  };
}
