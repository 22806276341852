import {
  IElementGenericPointCloud,
  IElementGenericPointCloudStream,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "../i-elements-selectors";
import { State } from "../i-elements-slice";

/**
 * List of known Point Cloud types.
 */
export enum PointCloudType {
  /** A higher quality, stationary scan of a mobile device. */
  flash = "flash",
  /** A non-stationary scan, generally with lower quality. */
  mobile = "mobile",
  /** A merged and post-processed point cloud generated from individual scans */
  project = "project",
  /** A point cloud for an individual scan */
  singleScan = "singleScan",
  /** General point cloud scan. */
  other = "other",
}

/**
 * @param pointCloud The point cloud to determine the type of.
 * @returns The type of scan technique used to create the point cloud.
 */
export function selectPointCloudType(
  pointCloud: IElementGenericPointCloudStream | IElementGenericPointCloud,
) {
  return (state: State) => {
    const isFlash = !!selectAncestor(pointCloud, (el) =>
      isIElementWithTypeAndHint(
        el,
        IElementType.section,
        IElementTypeHint.flash,
      ),
    )(state);

    // ASSUMPTION: GeoSlam datasets contain either flash scans or the mobile scans
    const isMobile =
      !isFlash &&
      !!selectAncestor(pointCloud, (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetGeoSlam,
        ),
      )(state);

    const isSingleScan = !!selectAncestor(pointCloud, (el) =>
      isIElementWithTypeAndHint(
        el,
        IElementType.section,
        IElementTypeHint.focusScan,
      ),
    )(state);

    const isProjectPointCloud = !!selectAncestor(
      pointCloud,
      (el) =>
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetFocus,
        ) ||
        isIElementWithTypeAndHint(
          el,
          IElementType.section,
          IElementTypeHint.dataSetEls,
        ),
    )(state);

    if (isFlash) {
      return PointCloudType.flash;
    }

    if (isMobile) {
      return PointCloudType.mobile;
    }

    if (isSingleScan) {
      return PointCloudType.singleScan;
    }

    if (isProjectPointCloud) {
      return PointCloudType.project;
    }

    return PointCloudType.other;
  };
}
