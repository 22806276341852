import { validatePrimitive } from "@faro-lotv/foundation";
import {
  CadModel,
  CadPartIDsRenderer,
  CameraMonitor,
  ICadModel,
  StreamCadModel,
  createCadModel,
} from "@faro-lotv/lotv";
import { UPDATE_CAMERA_MONITOR_PRIORITY } from "@faro-lotv/spatial-ui";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Intersection, Object3D, Raycaster, Vector3 } from "three";
import { useOnResize, useTypedEvent } from "../hooks";

export type CadModelRef = ICadModel | undefined;

export type CadModelRendererBaseProps = EventHandlers & {
  /** The Cad model to be rendered */
  cadModel: ICadModel;

  /**
   * Whether the cad model should respond to pointer events via raycasting
   *
   * @default true By default the Cad model handles pointer events returing the ID of the interested CAD part
   */
  raycastEnabled?: boolean;
};

/** The type of intersection with the CAD model */
export type CadModelIntersection = Intersection & {
  /** The ID of the CAD object that was intersected */
  cadObjectId: number;
};

/**
 * @param intersection The Intersection object to check the type of
 * @returns whether the intersection is a Cad model intersection
 */
export function isCadModelIntersection(
  intersection: Intersection,
): intersection is CadModelIntersection {
  return validatePrimitive(intersection, "cadObjectId", "number");
}

/** @returns a component that renders a Cad model */
export const CadModelRendererBase = forwardRef<
  CadModelRef,
  CadModelRendererBaseProps
>(function CADModelRendererBase(
  { cadModel, raycastEnabled = true, ...rest }: CadModelRendererBaseProps,
  ref,
): JSX.Element {
  const gl = useThree((s) => s.gl);
  const camera = useThree((s) => s.camera);

  const intersectionRef = useRef<Array<Intersection<Object3D>>>([]);

  // Create a camera monitor to know when the camera is still
  const cameraMonitor = useMemo(() => new CameraMonitor(), []);
  useFrame((_, delta) => {
    cameraMonitor.checkCameraMovement(camera, delta);
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  const idRenderer = useMemo(
    () => new CadPartIDsRenderer(cadModel, gl),
    [cadModel, gl],
  );

  // Recompute the ID framebuffer when the CAD model visibility changes
  useTypedEvent<void>(cadModel.visibleObjectsChanged, () => {
    idRenderer.invalidate();
  });

  // Recompute the ID framebuffer when the canvas is resized
  useOnResize((size) => {
    idRenderer.resize(Math.floor(size.width), Math.floor(size.height));
  });

  // Recompute the ID framebuffer when the camera is still
  useEffect(() => {
    if (cadModel instanceof StreamCadModel) {
      cadModel.startStream();
    }
    const cameraStartedMoving = cameraMonitor.cameraStartedMoving.on(() => {
      if (cadModel instanceof StreamCadModel) {
        cadModel.stopStream();
      }
    });
    const cameraStoppedConnection = cameraMonitor.cameraStoppedMoving.on(() => {
      if (cadModel instanceof StreamCadModel) {
        cadModel.startStream();
      }
      idRenderer.invalidate();
    });
    return () => {
      cameraStartedMoving.dispose();
      cameraStoppedConnection.dispose();
      if (cadModel instanceof StreamCadModel) {
        cadModel.stopStream();
      }
    };
  }, [idRenderer, cameraMonitor, cadModel]);

  // Override raycasting function, by picking using the DrawID buffer and
  // then raycasting on the selected mesh
  useEffect(() => {
    const { raycast } = cadModel;
    cadModel.raycast = (
      raycaster: Raycaster,
      intersections: Array<Intersection<Object3D>>,
    ) => {
      if (!raycastEnabled || cameraMonitor.cameraMoving) {
        return;
      }
      // On some events (wheel rotation when a button is focused instead of the canvas)
      // the raycaster will not have a camera attached, default to the scene camera
      const rayCamera = raycaster.camera ?? camera;
      idRenderer.updateIfNeeded(rayCamera);
      // compute the mouse position from the raycaster
      const mouse = new Vector3();
      mouse.addVectors(raycaster.ray.origin, raycaster.ray.direction);
      mouse.project(rayCamera);
      const x = ((mouse.x + 1) / 2) * idRenderer.size.x;
      const y = ((1 - mouse.y) / 2) * idRenderer.size.y;
      const id = idRenderer.drawIdAtPixel(Math.floor(x), Math.floor(y));
      if (id === undefined) {
        return;
      }
      const cadObjectId = cadModel.drawIdToObjectId(id);
      if (cadObjectId !== undefined) {
        intersectionRef.current.length = 0;
        cadModel.raycastNode(id, raycaster, intersectionRef.current);

        intersectionRef.current.map((intersect) => {
          intersect.object = cadModel;
          // attach the CAD object id to the output intersection
          Object.assign(intersect, { cadObjectId });
        });
        intersections.push(...intersectionRef.current);
      }
    };
    return () => {
      cadModel.raycast = raycast.bind(cadModel);
    };
  }, [
    cadModel,
    camera,
    cameraMonitor.cameraMoving,
    idRenderer,
    raycastEnabled,
  ]);

  return <primitive ref={ref} object={cadModel} dispose={null} {...rest} />;
});

export type CadModelRendererProps = EventHandlers & {
  /** The URL at which to load the Cad model */
  url: string;
  /** Optional callback to be informed when the Cad model is loaded. */
  onCadLoaded?(cad: CadModel): void;
};

/** @returns a component to load a CAD model from an URL and render it */
export const CadModelRenderer = forwardRef<CadModelRef, CadModelRendererProps>(
  function CadModelRenderer(
    { url, onCadLoaded = undefined, ...rest }: CadModelRendererProps,
    ref,
  ): JSX.Element | null {
    const [cadModel, setCadModel] = useState<CadModel>();

    const result = useGLTF(url);

    useEffect(() => {
      const cadModel = createCadModel(result.scene);
      setCadModel(cadModel);
      onCadLoaded?.(cadModel);
    }, [result, onCadLoaded]);

    if (!cadModel) return null;

    return <CadModelRendererBase ref={ref} cadModel={cadModel} {...rest} />;
  },
);
