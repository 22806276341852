import {
	Color,
	ColorRepresentation,
	CylinderGeometry,
	Euler,
	Mesh,
	MeshBasicMaterial,
	SphereGeometry,
	Vector3,
} from "three";

// Sizes for the handles
const HANDLE_RADIUS = 1;
const HANDLE_BORDER_SIZE = 0.1;

/** Interaction states for the BoxHandle */
export enum BoxHandleState {
	default = "default",
	hovered = "hovered",
	focused = "focused",
}

/** The base color and overrides for the different states of the box handle */
export interface BoxHandleColors {
	main: ColorRepresentation;
	hovered: ColorRepresentation;
	focused: ColorRepresentation;
}

/**
 * A small mesh handle to edit a Box
 */
export class BoxHandle extends Mesh {
	/** Name to recognize the Object in the scene graph */
	name = "BoxHandle";

	/** Mesh to render a border around the handle in focused state */
	#border = new BoxHandleBorder();

	/** The currently rendered interaction state of the box handle */
	#state = BoxHandleState.default;

	/** Material used for rendering the object. It's color will be dynamically changed per object. */
	material = new MeshBasicMaterial();

	/** Material used for rendering the xray mesh. It's color will be dynamically changed per object. */
	xrayMeshMat: MeshBasicMaterial;

	/** The colors used for the BoxHandles different states */
	colors: BoxHandleColors;

	/** Shared Geometry resource to use for all handles */
	static geometry = new SphereGeometry(HANDLE_RADIUS);

	/** Axis direction that the handle should manipulate. Cached for numerical stability. */
	#axis: Vector3;

	/** As the actual scale value is updated at runtime set this to modify the size of the handles. */
	baseScale: Vector3 = new Vector3(1, 1, 1);

	/** @returns the axis direction that the handle should manipulate. */
	get axis(): Vector3 {
		return this.#axis.clone();
	}
	/**
	 *
	 * @param position The position of the box relative to the parent
	 * @param rotation The rotation of the box relative to the parent
	 * @param colors The colors to use with the BoxHandle
	 */
	constructor(position: Vector3, rotation: Euler, colors: BoxHandleColors) {
		super();

		this.geometry = BoxHandle.geometry;
		this.material = new MeshBasicMaterial({ color: colors.main });
		this.colors = colors;

		const xrayMeshScale = 0.99;
		this.xrayMeshMat = this.material.clone();
		this.xrayMeshMat.transparent = true;
		this.xrayMeshMat.opacity = 0.5;
		this.xrayMeshMat.depthTest = false;
		const xrayMesh = new Mesh(BoxHandle.geometry, this.xrayMeshMat);
		// Disable raycast on the xray mesh
		xrayMesh.raycast = () => {};
		this.add(xrayMesh);
		xrayMesh.scale.setScalar(xrayMeshScale);

		this.position.copy(position);
		this.rotation.copy(rotation);
		this.#axis = position.clone().normalize();

		this.add(this.#border);

		this.state = BoxHandleState.default;
	}

	/** changes the current interaction state of the BoxHandle */
	set state(state: BoxHandleState) {
		this.#state = state;

		switch (this.#state) {
			case BoxHandleState.default:
				this.material.color = new Color(this.colors.main);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.5;
				this.#border.visible = false;
				break;
			case BoxHandleState.hovered:
				this.material.color = new Color(this.colors.hovered);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.8;
				this.#border.visible = true;
				break;
			case BoxHandleState.focused:
				this.material.color = new Color(this.colors.focused);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.8;
				this.#border.visible = true;
				break;
		}
	}

	/** @returns the current interaction state of the BoxHandle */
	get state(): BoxHandleState {
		return this.#state;
	}
}

/**
 * A small sub-mesh of the BoxHandle to render a border
 */
class BoxHandleBorder extends Mesh {
	/** Shared Material resource for all BoxHandleBorders */
	static material = new MeshBasicMaterial({ color: 0xffffff });

	/** Shared Geometry resource for all BoxHandleBorders */
	static geometry = new CylinderGeometry(
		HANDLE_RADIUS + HANDLE_BORDER_SIZE,
		HANDLE_RADIUS + HANDLE_BORDER_SIZE,
		0.1,
		32,
	);

	constructor() {
		super();

		this.geometry = BoxHandleBorder.geometry;
		this.material = BoxHandleBorder.material;
	}

	/** Disable raycasts on the border object so only the actual parent handle is hit */
	raycast(): void {}
}
