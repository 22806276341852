import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { UserDisplayInfo } from "../../types/user-display-info";
import { ExportIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";
import { UserAvatar } from "../user-avatar/user-avatar";
import { UserMenuBody, UserMenuBodyProps } from "./user-menu-body";

export type UserMenuProps = UserMenuBodyProps & {
  /** Info about the user */
  userDisplayInfo: UserDisplayInfo;

  /** If true the menu will be displayed */
  open: boolean;

  /** Element to anchor the UserMenu to */
  anchorElement?: Element;

  /** Callback executed when the user closes the menu */
  closeMenu?(): void;

  /** Optional style to apply to the menu component */
  sx?: SxProps<Theme>;
};

/**
 * @returns the user menu without its options inside. Pass the options/items as children of the component.
 */
export function UserMenu({
  userDisplayInfo,
  anchorElement,
  open,
  links,
  appVersion,
  lastPageUpdate,
  linkStyle,
  closeMenu,
  sx,
  children,
}: PropsWithChildren<UserMenuProps>): JSX.Element {
  const { email, firstName, lastName } = userDisplayInfo;

  const fullName = useMemo(
    () => `${firstName} ${lastName}`,
    [firstName, lastName],
  );

  return (
    <Menu
      anchorEl={anchorElement}
      id="account-menu"
      open={open}
      onClose={closeMenu}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "none",
          border: ({ palette }) => `1px solid ${palette.gray200}`,
          borderRadius: 0,
          elevation: 0,
          overflow: "visible",
          minWidth: "275px",
          maxWidth: "600px",
          "& .MuiList-root": {
            padding: 0,
          },
        },
        ...sx,
      }}
    >
      {/* Top section of the card for user avatar and user details */}
      <Box
        component="div"
        sx={{ display: "flex", alignItems: "center", padding: "16px" }}
      >
        {/* Section for user's avatar */}
        <Box component="div">
          <UserAvatar
            userDisplayInfo={userDisplayInfo}
            size="l"
            shouldShowGradientBorder
          />
        </Box>

        {/* Section for user details like name and email */}
        <Box
          component="div"
          sx={{
            paddingLeft: "16px",
            overflow: "hidden",
          }}
        >
          {/* Row for the user full name */}
          <Box
            component="div"
            sx={{
              color: "gray850",
              fontSize: "14px",
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={fullName}>
              <Box component="div">
                <FaroText variant="bodyM">{fullName}</FaroText>
              </Box>
            </Tooltip>
          </Box>

          {/* Row for the user email */}
          <Box
            component="div"
            sx={{
              color: "gray850",
              fontSize: "12px",
              fontWeight: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={email}>
              <Box component="div">
                <FaroText variant="bodyM">{email}</FaroText>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <UserMenuBody
        links={links}
        linkStyle={linkStyle}
        appVersion={appVersion}
        lastPageUpdate={lastPageUpdate}
      >
        {children}
      </UserMenuBody>
    </Menu>
  );
}

type MenuOption = {
  /** Function called when the menu option is clicked */
  onClick?(): void;
};

/**
 * @returns the Account & Security menu item
 */
export function MenuAccountSecurityOption({
  onClick,
}: MenuOption): JSX.Element {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText>
        <FaroText variant="bodyL">Account & Security</FaroText>
      </ListItemText>
    </MenuItem>
  );
}

/**
 * @returns the Sign Out menu item with the icon
 */
export function MenuSignOutOption({ onClick }: MenuOption): JSX.Element {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText>
        <FaroText variant="bodyL">Sign Out</FaroText>
      </ListItemText>
      <ListItemIcon sx={{ justifyContent: "end" }}>
        <ExportIcon sx={{ rotate: "-90deg", color: "gray850" }} />
      </ListItemIcon>
    </MenuItem>
  );
}
