import { TypedEvent } from "@faro-lotv/foundation";
import { useEffect } from "react";

/**
 * A type to compute a callback that wil receive T as argument
 */
export type TypedCallback<T> = (value: T) => void;

/**
 * A type to compute the callback required by a TypedEvent
 */
export type TypedEventCallback<Event> =
  Event extends TypedEvent<infer T> ? TypedCallback<T> : never;

/**
 * An hook to forward a react prop callback to a Lotv TypedEvent
 *
 * @param event The event we want to listen to
 * @param callback The callback to forward the event to
 */
export function useTypedEvent<
  EvType,
  Event extends TypedEvent<EvType> = TypedEvent<EvType>,
>(event?: Event, callback?: TypedCallback<EvType>): void {
  useEffect(() => {
    if (event && callback) {
      event.on(callback);
      return () => event.off(callback);
    }
  }, [event, callback]);
}
