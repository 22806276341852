import { RefObject, useCallback, useEffect, useRef, useState } from "react";

type UseResizeParams = {
  /** Initial width */
  initialWidth: number;

  /** Minimum width to maintain */
  minWidth: number;

  /** Maximum width to maintain (not limited by default) */
  maxWidth?: number;

  /**
   * Offset to add to the mouse coordinate, that is used for resizing
   *
   * This is necessary when the element to resize is not the left most on the page
   */
  leftOffset?: number;
};

type UseResizeReturn = {
  /** Width to use for the element to resize */
  width: number;

  /** Indicator if resizing mode is active */
  isResizing: boolean;

  /** Ref to the handler */
  handleRef: RefObject<HTMLElement>;
};

/**
 * Hook that allows to adjust the width of an element via dragging of the mouse
 *
 * @returns {UseResizeReturn} the provided enableResize method should be attached to the onMouseDown handler of the element
 * that can be dragged by the user
 */
export function useHorizontalResize({
  initialWidth,
  minWidth,
  maxWidth = Number.MAX_SAFE_INTEGER,
  leftOffset = 0,
}: UseResizeParams): UseResizeReturn {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(initialWidth);

  const handleRef = useRef<HTMLElement>(null);

  const startResizing = useCallback((e: PointerEvent) => {
    e.stopPropagation();

    if (!handleRef.current) return;
    handleRef.current.setPointerCapture(e.pointerId);
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback((e: PointerEvent) => {
    e.stopPropagation();

    if (!handleRef.current) return;
    handleRef.current.releasePointerCapture(e.pointerId);
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (e: PointerEvent) => {
      if (handleRef.current && isResizing) {
        e.stopPropagation();
        const newWidth = e.clientX - leftOffset;
        if (newWidth >= minWidth && newWidth <= maxWidth) {
          setWidth(newWidth);
        }
      }
    },
    [minWidth, maxWidth, isResizing, leftOffset],
  );

  // Setup necessary event listeners for mouse actions, and remove them on unmount
  useEffect(() => {
    if (!handleRef.current) return;
    const handle = handleRef.current;
    handle.addEventListener("pointerdown", startResizing);
    handle.addEventListener("pointermove", resize);
    handle.addEventListener("pointerup", stopResizing);

    return () => {
      handle.removeEventListener("pointerdown", startResizing);
      handle.removeEventListener("pointermove", resize);
      handle.removeEventListener("pointerup", stopResizing);
    };
  }, [stopResizing, resize, startResizing]);

  return { width, isResizing, handleRef };
}
