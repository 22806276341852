/**
 * The supported task types we want to track from the ProgressApi.
 */
export enum ProgressApiSupportedTaskTypes {
  pointCloudExport = "PointCloudExport",
  pointCloudToPotree = "PointCloudToPotree",
  // TODO: Remove after backend update: https://faro01.atlassian.net/browse/SWEB-1980
  pointCloudLazToPotree = "PointCloudLazToPotree",
  pointCloudE57ToLaz = "PointCloudE57ToLaz",
  pointCloudGSToLaz = "PointCloudGSToLaz",
  /** Automatic registration (i.e., alignment) of a point cloud pair. */
  c2cRegistration = "C2CRegistration",
  /** Automatic registration (i.e., alignment) of a whole data set. */
  registerMultiCloudDataSet = "RegisterMultiCloudDataSet",
  /** Merging multiple point clouds into a single dataset. */
  mergePointClouds = "MergePointClouds",
  /** BIM/CAD Processing */
  bimModelImport = "BimModelImport",
  /** Video Mode */
  videoMode = "VideoMode",
  /** Scene conversion */
  sceneConversion = "SceneConversion",
}

/**
 * @returns true if the passed string is one of the supported task types
 * @param taskType type of the task from the backend
 */
export function isProgressApiSupportedTaskType(
  taskType: string,
): taskType is ProgressApiSupportedTaskTypes {
  return Object.values<string>(ProgressApiSupportedTaskTypes).includes(
    taskType,
  );
}
