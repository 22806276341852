import { GUID } from "@faro-lotv/ielement-types";
import {
  CoreApiTokenManager,
  TokenProvider,
  sendAuthenticatedJsonRequest,
} from "../authentication";
import {
  BackgroundTaskDetails,
  ProgressApiLatestStatusResponse,
  isProgressApiLatestStatusResponse,
} from "./progress-api-responses";

/**
 * A class to handle the queries regarding long running background tasks
 */
export class ProgressApiClient {
  /** Function to get a new authentication token. */
  private tokenProvider: TokenProvider;

  /**
   * @param projectId Id of the current project
   * @param tokenProvider A function to get a new authentication token.
   * @param endpoint The base url of the progress api
   * @param clientId A string representation of the client in the format client/version
   */
  constructor(
    projectId: GUID,
    tokenProvider: TokenProvider,
    endpoint: string,
    clientId?: string,
  );
  /**
   * @param projectId Id of the current project
   * @param tokenManager To get tokens for this project
   * @param endpoint The base url of the progress api
   * @deprecated Pass a `TokenProvider` instead of a `CoreApiTokenManager`.
   */
  constructor(
    projectId: GUID,
    tokenManager: CoreApiTokenManager,
    endpoint: string,
  );
  /**
   * @param projectId Id of the current project
   * @param tokenProvider A function to get a new authentication token.
   * @param endpoint The base url of the progress api
   * @param clientId A string representation of the client in the format client/version
   */
  constructor(
    private projectId: GUID,
    tokenProvider: TokenProvider | CoreApiTokenManager,
    private endpoint: string,
    private clientId?: string,
  ) {
    // Legacy support for passing a token manager directly
    this.tokenProvider =
      tokenProvider instanceof CoreApiTokenManager
        ? () => tokenProvider.getToken()
        : tokenProvider;
  }

  /**
   * @returns the state of the last 30 backend tasks
   */
  requestProgress({
    signal,
    pageSize,
    after,
    before,
    last = true,
  }: RequestProgressParams = {}): Promise<ProgressApiLatestStatusResponse> {
    return sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: `/v1/status/latest?ProjectId=${this.projectId}`,
      typeGuard: isProgressApiLatestStatusResponse,
      tokenProvider: this.tokenProvider,
      clientId: this.clientId,
      signal,
      queryParams: {
        pageSize: pageSize?.toString(),
        after,
        before,
        last: last ? "true" : "false",
      },
    });
  }

  /**
   * @returns All tasks for the current project, managing the pagination
   */
  async requestAllProgress({
    signal,
    pageSize,
    direction,
  }: RequestAllProgressParams): Promise<BackgroundTaskDetails[]> {
    const tasks: BackgroundTaskDetails[] = [];
    let pageToken = undefined;
    while (pageToken !== null) {
      const last = !pageToken && direction === "lastToFirst";
      const after = direction === "firstToLast" ? pageToken : undefined;
      const before = direction === "lastToFirst" ? pageToken : undefined;
      const response = await this.requestProgress({
        signal,
        pageSize,
        last,
        after,
        before,
      });
      tasks.push(...response.data);
      pageToken =
        direction === "firstToLast" ? response.after : response.before;
    }
    return tasks;
  }
}

type RequestProgressParams = {
  /** The signal to stop the query */
  signal?: AbortSignal;
  /** The number of tasks per page. @default 30 */
  pageSize?: number;
  /** The id of the next page, if it exists */
  after?: string;
  /** The id of the previous page, if it exists */
  before?: string;
  /** Get the latest task page. @default true */
  last?: boolean;
};

type RequestAllProgressParams = Omit<
  RequestProgressParams,
  "after" | "before" | "last"
> & {
  /** Order to gather the pages for the progress */
  direction: "firstToLast" | "lastToFirst";
};
