import { ShaderMaterial, Texture } from "three";
import { makeOptionalUniform, makeUniform } from "../Materials/Uniforms";
import frag from "../Shaders/MapPlaceholders.frag";
import vert from "../Shaders/MapPlaceholders.vert";

const DEFAULT_HOVER_SIZE = 1.5;

/**
 * Specialized material to render placeholders with a fixed pixel size using a PointCloud
 *
 * @see MapPlaceholders
 */
export class MapPlaceholdersMaterial extends ShaderMaterial {
	/** Custom fragment shader source code */
	fragmentShader = frag;

	/** Custom vertex shader source code */
	vertexShader = vert;

	/** Default to transparent to get proper rendering with non quad textures */
	transparent = true;

	uniforms = {
		/** Base size used to render the placeholders */
		size: makeUniform(1),

		/** Factor applied to the size for hovered placeholders */
		hoveredSizeFactor: makeUniform(DEFAULT_HOVER_SIZE),

		/** Factor applied to the size for selected placeholders */
		selectedSizeFactor: makeUniform(DEFAULT_HOVER_SIZE),

		/** Default texture used to render the placeholders */
		map: makeOptionalUniform<Texture>(),

		/** Texture used for hovered placeholders */
		hoveredMap: makeOptionalUniform<Texture>(),

		/** Texture used for the selected placeholders */
		selectedMap: makeOptionalUniform<Texture>(),

		/** Texture used for the placeholders in a custom state */
		customMap: makeOptionalUniform<Texture>(),

		/** Min alpha threshold to render a fragment */
		alphaTest: makeUniform(0.1),
	};
}
