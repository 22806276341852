import { LotvRenderer } from "@faro-lotv/lotv";
import {
  CanvasProps as R3FCanvasProps,
  Canvas as ThreeCanvas,
} from "@react-three/fiber";
import { forwardRef } from "react";
import { Color, Raycaster } from "three";
import { ControlsLockProvider } from "./controls";

export const DEFAULT_BACKGROUND = new Color("white");

const DEFAULT_RAYCAST_PARAMS = new Raycaster().params;

type CanvasProps = R3FCanvasProps & {
  /** Callback exectured everytime a new renderer is created for this canvas */
  onRendererChanged?(gl: LotvRenderer): void;
};

/** @returns a custom Lotv Canvas with the proper default props and a custom ThreeJS renderer */
export const Canvas = forwardRef<HTMLCanvasElement, CanvasProps>(
  function Canvas({ children, onRendererChanged, ...rest }, ref): JSX.Element {
    return (
      <ThreeCanvas
        ref={ref}
        // flat and linear help having the expected color output from the rendering
        // without additional R3F color processing.
        // legacy prevents the ColoManagement component from changing colors of Color objects
        flat
        linear
        legacy
        // TODO: Restore adaptive dpr rendering (https://faro01.atlassian.net/browse/SWEB-4855)
        dpr={1}
        // stricter raycast threshold for points compared from R3F defaults
        raycaster={{
          params: {
            ...DEFAULT_RAYCAST_PARAMS,
            Points: {
              threshold: 0.05,
            },
          },
        }}
        // inject custom Lotv renderer
        gl={(canvas) => {
          const renderer = new LotvRenderer({
            canvas,
            premultipliedAlpha: false,
          });
          // enabling the 'localClippingEnabled' property
          // since the app is going to use a global bounding box
          // in most of its scenes.
          renderer.localClippingEnabled = true;
          if (onRendererChanged) onRendererChanged(renderer);
          return renderer;
        }}
        // replace default gray background with a white background
        onCreated={(state) => (state.scene.background = DEFAULT_BACKGROUND)}
        // Allow external props to override any of the defaults
        {...rest}
      >
        <ControlsLockProvider>{children}</ControlsLockProvider>
      </ThreeCanvas>
    );
  },
);
