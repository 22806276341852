import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementGroup,
  IElementImgSheet,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddImgSheet extends BaseMutation {
  /**
   * A new Group for the new ImgSheet
   * Provide only if no group for ImgSheet exists yet.
   */
  group?: CreateIElement<IElementGroup>;

  /** New imgSheet element */
  newElement: CreateIElement<IElementImgSheet>;
}

interface CreateMutationAddImgSheet {
  /** ID of the root element */
  rootId: GUID;

  /** ID of the section (Area) which will contain the new image sheet */
  sectionId: GUID;

  /** ID of the parent group (Area) if exists, otherwise a new group will be created */
  groupId?: GUID;

  /** Name of the image sheet being created */
  name?: string;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;

  /**
   * The width in pixels of the original image
   */
  pixelWidth: number;

  /**
   * The height in pixels of the original image
   */
  pixelHeight: number;

  /** Name of the file */
  fileName: string;

  /** File md5Hash encoded in base 16 (hexadecimal). */
  md5Hash: string;

  /** Size of the file, in bytes. */
  fileSize: number;

  /** Url to the new ImgSheet file */
  uri: string;

  /** Thumbnail for the ImgSheet */
  thumbnailUri?: string;
}

/**
 * The mutation creates a structure like this:
 *
 * ```
 * Group (SlideContainer)
 * └ Section (Area)
 *   └ Group (Area)
 *     └ ImgSheet (Area)
 * ```
 *
 * @returns a mutation to add a new ImgSheet to a project
 */
export function mutationAddImgSheet({
  rootId,
  sectionId,
  groupId,
  name,
  createdAt = new Date().toISOString(),
  pixelWidth,
  pixelHeight,
  fileName,
  fileSize,
  md5Hash,
  uri,
  thumbnailUri,
}: CreateMutationAddImgSheet): MutationAddImgSheet {
  const elementId = groupId ?? sectionId;
  const areaGroupId = groupId ?? generateGUID();
  const imgSheetId = generateGUID();

  // Create a new group if it's missing
  const group: CreateIElement<IElementGroup> | undefined = groupId
    ? undefined
    : {
        type: IElementType.group,
        typeHint: IElementTypeHint.area,
        id: areaGroupId,
        rootId,
        parentId: sectionId,
        childrenIds: [imgSheetId],
        name: "Floorplan PDFs",
        createdAt,
        xOr: false,
      };

  const imgSheet: CreateIElement<IElementImgSheet> = {
    id: imgSheetId,
    name: name ?? "Image Sheet",
    type: IElementType.imgSheet,
    typeHint: IElementTypeHint.area,
    parentId: areaGroupId,
    childrenIds: [],
    createdAt,
    rootId,
    pixelWidth,
    pixelHeight,
    fileName,
    fileSize,
    md5Hash,
    uri,
    thumbnailUri,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddImgSheet, elementId),
    group,
    newElement: imgSheet,
  };
}
