import { GUID } from "@faro-lotv/foundation";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export enum MarkupStatus {
  Open = "open",
  InProgress = "in_progress",
  ToReview = "to_review",
  Resolved = "resolved",
}

/**
 * @param status string to check if it's part of the MarkupStatus options
 * @returns true if the passed string is a MarkupStatus option
 */
export function isMarkupStatus(status: string): status is MarkupStatus {
  return Object.values<string>(MarkupStatus).includes(status);
}

/**
 * Change the status of a Markup Element
 */
export interface MutationDropDownMarkupField extends BaseMutation {
  type: MutationTypes.MutationDropDownMarkupField;

  /** The new status of the markup */
  value?: `${MarkupStatus}`;
}

/**
 * @returns a mutation to change an status of a Markup element
 * @param elementId id of the DropDownMarkupField element
 * @param value new state of the element
 */
export function createMutationDropDownMarkupField(
  elementId: GUID,
  value?: `${MarkupStatus}`,
): MutationDropDownMarkupField {
  return {
    ...createBaseMutation(MutationTypes.MutationDropDownMarkupField, elementId),
    value,
  };
}
