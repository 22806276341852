import { Color, DoubleSide, Texture, Vector2 } from "three";

type PinSpriteProps = {
  /** Pin opacity */
  opacity: number;

  /** Pin texture */
  texture: Texture;

  /** Pin center */
  center: Vector2;

  /** Pin color */
  color?: Color;
};

/** @returns the sprite for a single Pin */
export function PinSprite({
  opacity,
  texture,
  center,
  color,
}: PinSpriteProps): JSX.Element {
  return (
    <sprite
      // eslint-disable-next-line react/no-unknown-property
      center={center}
    >
      <spriteMaterial
        map={texture}
        transparent={true}
        alphaTest={0.1}
        opacity={opacity}
        depthTest={false}
        side={DoubleSide}
        color={color}
      />
    </sprite>
  );
}
