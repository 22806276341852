import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { cyan, neutral, red } from "../colors";
import { FontWeights } from "../faro-theme";
import { ArrowDown2Icon } from "../icons/icons";
import { DropdownProps } from "./dropdown-types";
import { SingleSelectItem } from "./single-select-item";

function Placeholder({ placeholder }: { placeholder?: string }): JSX.Element {
  return (
    <Typography
      sx={{
        fontStyle: "italic",
        mr: 1,
      }}
    >
      {placeholder ?? "Select option"}
    </Typography>
  );
}

/** @returns The select component of the dropdown */
export function DropdownSelectDark({
  value: selectedValue,
  label,
  disabled: isDisabled,
  error: isError,
  sx,
  fullWidth = true,
  children,
  ...props
}: Omit<
  DropdownProps,
  "options" | "shouldCapitalize" | "placeholder"
>): JSX.Element {
  const { spacing } = useTheme();

  const CustomIconComponent = useCallback((props: object) => {
    return <ArrowDown2Icon sx={{ marginRight: 1 }} {...props} />;
  }, []);

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel
          shrink
          sx={{
            color: isDisabled ? neutral[500] : neutral[100],
            "&.Mui-focused": {
              color: neutral[100],
            },
          }}
        >
          {label}
        </InputLabel>
      )}

      <Select
        {...props}
        displayEmpty
        value={selectedValue}
        disabled={isDisabled}
        error={isError}
        IconComponent={CustomIconComponent}
        sx={{
          ...sx,
          color: neutral[500],
          backgroundColor: neutral[999],

          // Update border color based on state
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: neutral[500],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: neutral[100],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: cyan[500],
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: red[300],
          },

          // Disabled style
          "&.Mui-disabled": {
            backgroundColor: neutral[900],
          },
          "& .MuiInputBase-input.Mui-disabled, .MuiInputBase-input.Mui-error": {
            WebkitTextFillColor: neutral[500],
          },

          "label + &": {
            marginTop: spacing(3),
          },
          ".MuiSelect-icon": {
            color: isDisabled ? neutral[500] : neutral[100],
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          sx: { marginTop: 0.5 },
          PaperProps: {
            sx: {
              color: neutral[100],
              backgroundColor: neutral[950],
              border: `1px solid ${neutral[800]}`,

              "& .MuiList-root": {
                display: "flex",
                flexFlow: "column",
                p: 0.5,
                gap: 0.5,
              },
            },
          },
        }}
      >
        {children}
      </Select>
      {isError && (
        <FormHelperText
          sx={{
            marginY: 0.5,
            marginX: "1px",
            color: red[300],
            fontStyle: "italic",
            fontWeight: FontWeights.SemiBold,
          }}
        >
          Enter a valid selection
        </FormHelperText>
      )}
    </FormControl>
  );
}

/**
 * @returns The reusable dropdown component styled according to the design guidelines
 */
export function DropdownDark({
  options,
  placeholder,
  value: selectedValue,
  label,
  disabled,
  error,
  sx,
  fullWidth = true,
  shouldCapitalize = true,
  ...props
}: Omit<DropdownProps, "dark">): JSX.Element {
  return (
    <DropdownSelectDark
      value={selectedValue}
      label={label}
      disabled={disabled}
      error={error}
      sx={sx}
      fullWidth={fullWidth}
      {...props}
      renderValue={(selected) => {
        if (!selected) {
          return <Placeholder placeholder={placeholder} />;
        }

        const option = options.find((o) => o.value === selected);
        return (
          <Typography
            sx={{
              color: neutral[0],
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: shouldCapitalize ? "capitalize" : "none",
              mr: 1.5,
            }}
          >
            {option?.label ?? selected}
          </Typography>
        );
      }}
    >
      {options.map(
        ({ key, value, label, secondaryText, isDisabled, isHeader }, index) =>
          isHeader ? (
            <ListSubheader key={key}>
              {index !== 0 && <Divider />}

              <Typography
                sx={{
                  color: neutral[0],
                  fontSize: "0.75rem",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  mt: 1,
                }}
              >
                {label ?? value}
              </Typography>
            </ListSubheader>
          ) : (
            <SingleSelectItem
              key={key}
              value={value}
              label={label}
              secondaryText={secondaryText}
              disabled={isDisabled}
              shouldCapitalize={shouldCapitalize}
              selectedValue={selectedValue}
              dark={true}
            />
          ),
      )}
    </DropdownSelectDark>
  );
}
