// Textures for the three states
uniform sampler2D map;
uniform sampler2D hoveredMap;
uniform sampler2D selectedMap;
uniform sampler2D customMap;

// Alpha threshold
uniform float alphaTest;

flat in int vState;
#ifdef USE_COLORS
in vec3 vColor;
#endif

#define NORMAL_STATE 0
#define HOVER_STATE 1
#define SELECTED_STATE 2
#define HIDDEN_STATE 4
#define CUSTOM_STATE 8

float eps = 0.0001;

bool isGray(float r, float g, float b) {
   return abs(r - g) < eps && abs(g - b) < eps;
}

void main() {
	if ((vState & HIDDEN_STATE) == HIDDEN_STATE) {
		discard;
		return;
	}

	// Compute uv coordinates for this point
	vec2 uv = vec2( gl_PointCoord.x, 1.0 - gl_PointCoord.y);

	// Texture lookup depending on placeholder state
	vec4 texColor = vec4(1., 1., 1., 1);
    if ((vState & SELECTED_STATE) == SELECTED_STATE) {
	   texColor = texture2D( selectedMap, uv );
    } else if ((vState & CUSTOM_STATE) == CUSTOM_STATE) {
	    texColor = texture2D( customMap, uv );
    } else if ((vState & HOVER_STATE) == HOVER_STATE) {
	    texColor = texture2D( hoveredMap, uv );
    } else {
	    texColor = texture2D( map, uv );
    }

    // Discard fragments below alpha threshold
	if ( texColor.a < alphaTest ) discard;

	vec4 outColor = texColor;
	#ifdef USE_COLORS
		// Don't replace any gray
		if (!isGray(texColor.r, texColor.g, texColor.b)) {
			outColor.xyz = vColor;
		}
	#endif
	gl_FragColor = outColor;
}


