import { Box2, MirroredRepeatWrapping, NearestFilter, Texture, Vector2 } from "three";
import { ImageNodeFetch, ImageTree, ImageTreeNode } from "../Lod/ImageTree";
import { FloorPlanVisibleTilesStrategy } from "../Lod/VisibleTilesStrategy";
import { TextureLoader } from "../Utils/TextureLoader";
import { FloorPlanLevel } from "./FloorPlan";

const loader = new TextureLoader();

/**
 * Node fetch class for retrieving the texture of a tile
 */
export class FloorPlanNodeFetch implements ImageNodeFetch {
	#controller: AbortController | undefined;

	/**
	 * Constructor class that stores the node
	 *
	 * @param node The node itself.
	 */
	constructor(private node: ImageTreeNode<string>) {}

	/**
	 * Return the image belonging to this node.
	 *
	 * @returns An already resolved promise.
	 */
	async image(): Promise<Texture> {
		if (!this.node.image) {
			const { promise, abort } = loader.load(this.node.source);
			this.#controller = abort;
			const texture = await promise;
			texture.wrapS = MirroredRepeatWrapping;
			texture.wrapT = MirroredRepeatWrapping;
			texture.minFilter = NearestFilter;
			texture.magFilter = NearestFilter;
			texture.generateMipmaps = false;
			texture.needsUpdate = true;
			this.node.image = texture;
		}
		return this.node.image;
	}

	/**
	 * Abort the download of the texture for this node
	 */
	abort(): void {
		if (this.#controller) {
			this.#controller.abort();
		}
	}
}

/**
 * Create a key for the map when building the tree structure
 *
 * @param z The z-coordinate of the tile
 * @param x The x coordinate of the tile
 * @param y The y coordinate of the tile
 * @returns The key encoding the tile
 */
function createKey(z: number, x: number, y: number): string {
	return `${z.toString()}-${x.toString()}-${y.toString()}`;
}

/**
 * A subclass of ImageTree developed to parse Tiled floor plans data.
 */
export class FloorPlanTree extends ImageTree<string> {
	width: number;
	height: number;

	/**
	 * Constructs a new FloorPlane tree
	 *
	 * @param lod The data parsed describing the tree structure
	 */
	constructor(lod: FloorPlanLevel[]) {
		super();

		this.visibleTilesStrategy = new FloorPlanVisibleTilesStrategy();

		// A leaflet tree is always a 100x100 square, the real world sizes are defined at the object level
		this.width = 100;
		this.height = 100;

		const map: Record<string, number | undefined> = {};
		for (const { level, dimX, dimY, sources } of lod) {
			for (const s of sources) {
				const { x, y, source } = s;
				const parentKey = createKey(level - 1, Math.floor(x / 2), Math.floor(y / 2));
				const parent = map[parentKey];

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
				if (parent === undefined && level > 0) {
					throw new Error(`Error while parsing tree - ${level} ${JSON.stringify(s)}`);
				}

				this.appendChildren(parent, {
					source,
					rect: new Box2(new Vector2(x / dimX, y / dimY), new Vector2((x + 1) / dimX, (y + 1) / dimY)),
				});

				const nodeIdx = this.nodeCount;
				map[createKey(level, x, y)] = nodeIdx - 1;
			}
		}
	}

	/**
	 * Get the image for a node
	 *
	 * @param nodeOrId The node or the node id to fetch the image
	 * @returns An object to wait for the image or cancel the fetch
	 */
	// eslint-disable-next-line require-await -- FIXME
	override async getNodeImage(nodeOrId: number | ImageTreeNode<string>): Promise<ImageNodeFetch> {
		const node = typeof nodeOrId === "number" ? this.nodesList[nodeOrId] : nodeOrId;
		return new FloorPlanNodeFetch(node);
	}
}
